




































































































































import axios, { AxiosError } from 'axios'

import Heading from '@/components/ui/Heading.vue'
import PositionConfigurator from '@/components/ui/PositionConfigurator.vue'
import TagPersistence from '@/persistence/TagPersistence'
import StorePersistence from '@/persistence/StorePersistence'
import { IStore, IStoreType, ITag } from '@/types/api/Interfaces'
import IResourceItem from '@/types/IResourceItem'
import TypedVue from '@/config/vue'

interface IData {
    selectedTag: string
    mode: string
    newTag: string
}

export default TypedVue.extend({
    name: 'Store',
    components: { PositionConfigurator, Heading },

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },

    data(): IData {
        return {
            selectedTag: '',
            mode: 'keep',
            newTag: '',
        }
    },

    computed: {
        stores(): Array<IStore> {
            return this.$store.state.stores.items
        },

        storeTypes(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },

        tags(): Array<ITag> {
            const tags = this.$store.state.tags.items.filter(
                (tag: ITag): boolean =>
                    this.item?.tags.find(
                        (tagIri): boolean => tagIri === tag['@id']
                    ) !== undefined
            )

            if (tags === undefined) {
                return []
            }

            return tags
        },

        item(): IStore | undefined {
            return this.$store.state.stores.items.find(
                (item: IStore): boolean =>
                    item.id.toString() === this.id.toString()
            )
        },
    },

    async mounted(): Promise<void> {
        await StorePersistence.loadAll()
        await TagPersistence.loadAll()
    },

    methods: {
        // eslint-disable-next-line max-statements -- bla
        async updateSegments(): Promise<void> {
            if (this.item === undefined) {
                return
            }

            let newSegments: Array<string> = []

            const newStoreType = this.item?.storeType
                ? this.$store.state.storeTypes.items.find(
                      (item: IStoreType): boolean =>
                          this.item!.storeType === item['@id']
                  )
                : undefined

            if (newStoreType !== undefined) {
                if (this.mode === 'merge') {
                    newSegments = Array.from(
                        new Set([
                            ...this.item.segments,
                            ...newStoreType.segments,
                        ])
                    )
                } else if (this.mode === 'overwrite') {
                    newSegments = newStoreType.segments
                } else {
                    // Nothing changed
                }
            }

            if (this.mode !== 'keep') {
                await StorePersistence.update(this.item['@id'], {
                    segments: newSegments,
                })
            }

            this.mode = 'keep'
        },

        uri(): string {
            return `/api/stores/${this.id}`
        },

        resourceItem(): IResourceItem {
            return this.$refs.resourceItem as IResourceItem
        },

        showTagModal(): void {
            this.$bvModal.show('addTag')
        },

        async removeTag(tag: ITag): Promise<void> {
            if (this.item === undefined) {
                return
            }

            await StorePersistence.update(this.item['@id'], {
                tags: this.item.tags.filter(
                    (filterTag): boolean => filterTag !== tag['@id']
                ),
            })
        },

        async addTag(): Promise<void> {
            if (this.item === undefined) {
                return
            }

            if (this.selectedTag === '') {
                await this.createTag()
            } else {
                this.item.tags.push(this.selectedTag)
                this.selectedTag = ''
            }

            await StorePersistence.update(this.item['@id'], {
                tags: this.item.tags,
            })
        },

        // eslint-disable-next-line max-statements -- shush
        async createTag(): Promise<void> {
            if (this.item === undefined) {
                return
            }

            if (this.newTag === '') {
                this.$bvToast.toast('Tag naam kan niet leeg zijn', {
                    title: 'Lege Tag',
                    variant: 'danger',
                })

                return
            }

            try {
                const response = await TagPersistence.create({
                    title: this.newTag.trim(),
                })

                this.newTag = ''

                this.item.tags.push(response['@id'])
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error
                }

                const axiosError = error as AxiosError<any>

                if (
                    axiosError.response?.data['hydra:description'].includes(
                        'This value is already used'
                    )
                ) {
                    this.$bvToast.toast('Een Tag met deze naam bestaat al', {
                        title: 'Duplicate tag',
                        variant: 'danger',
                    })
                } else {
                    throw error
                }
            }
        },

        async patch(property: string, value: any): Promise<void> {
            const data: any = {}

            data[property] = value
            await this.$api.authenticated().patch(this.uri(), data)
            this.$bvModal.show('mergeSegments')
        },
    },
})
