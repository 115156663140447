var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-3 py-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1 text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.mediaObject.file.name) + " ")])]), _c('div', {
    staticClass: "d-flex small text-muted"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'spinner-third'],
      "spin": ""
    }
  }), _c('span', [_vm._v("Uploaden...")])], 1), _c('b-link', [_vm._v("Annuleer")])], 1), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "height": "10px"
    }
  }, [_c('b-progress-bar', {
    style: 'width: ' + _vm.mediaObject.progress + '%',
    attrs: {
      "animated": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }