





































































































import { PropType } from 'vue'

import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import {
    ICampaignItem,
    IMediaObject,
    IProductionProduct,
} from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import ProductionProductPersistence from '@/persistence/ProductionProductPersistence'
import CampaignItemPersistence from '@/persistence/CampaignItemPersistence'

export default TypedVue.extend({
    name: 'CampaignItemPackageProduct',
    components: { MaterialBlueprint },

    props: {
        campaignItem: {
            type: Object as PropType<ICampaignItem>,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    async mounted(): Promise<void> {
        await ProductionProductPersistence.getByIri(
            this.campaignItem?.relationProductionProduct
        )
    },

    methods: {
        showRemarksModal(): void {
            this.$bvModal.show(`remarksModal${this.campaignItem?.id}`)
        },

        async patchRemarks(): Promise<void> {
            if (this.campaignItem === undefined) {
                return
            }

            await CampaignItemPersistence.update(this.campaignItem['@id'], {
                remarks: this.campaignItem.remarks,
            })
        },
    },

    watch: {
        campaignItem(newValue: ICampaignItem, oldValue: ICampaignItem): void {
            if (
                oldValue.pendingMediaObject &&
                !newValue.pendingMediaObject &&
                newValue.mediaObject !== oldValue.pendingMediaObject
            ) {
                this.$bvToast.toast('Er is iets misgegaan bij het uploaden', {
                    title: 'Fout',
                    variant: 'danger',
                })
            }
        },
    },

    computed: {
        product(): IProductionProduct | undefined {
            return this.$store.state.productionProducts.items.find(
                (product: IProductionProduct): boolean =>
                    product['@id'] ===
                    this.campaignItem?.relationProductionProduct
            )
        },

        mediaObject(): IMediaObject | undefined {
            return this.$store.state.mediaObjects.items.find(
                (mediaObject: IMediaObject): boolean =>
                    mediaObject['@id'] === this.campaignItem?.mediaObject
            )
        },

        pendingMediaObject(): IMediaObject | undefined {
            return this.$store.state.mediaObjects.items.find(
                (mediaObject: IMediaObject): boolean =>
                    mediaObject['@id'] === this.campaignItem?.pendingMediaObject
            )
        },
    },
})
