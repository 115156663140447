import { MutationTree } from 'vuex'

import IState from '@/store/modules/nav/IState'

const mutations: MutationTree<IState> = {
    setCollapsed: (state: IState, isCollapsed: boolean): void => {
        state.collapsed = isCollapsed
    },

    setClosed: (state: IState, isClosed: boolean): void => {
        state.closed = isClosed
    },
}

export default mutations
