function formatEur(price: number | string, maxDecimals: number = 2): string {
    let value = price

    if (typeof value === 'string') {
        value = Number.parseFloat(value)
    }

    return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: maxDecimals,
    }).format(value)
}

export default formatEur
