var render = function () {
  var _vm$colors;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "material-blueprint",
    class: Object.assign({}, _vm.classList, {
      'material-blueprint-single-sided': !_vm.doubleSided && !_vm.backColors,
      'material-blueprint-double-sided': _vm.doubleSided || _vm.backColors,
      'material-blueprint-wide': _vm.wide,
      clickable: _vm.clickable
    })
  }, [_vm.title ? _c('div', {
    staticClass: "title",
    on: {
      "click": _vm.checkClick
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm.material ? _c('div', {
    staticClass: "material",
    on: {
      "click": _vm.checkClick
    }
  }, [_vm._v(" " + _vm._s(_vm.material) + " ")]) : _vm._e(), _c('div', {
    staticClass: "body"
  }, [_vm.remarks ? _c('font-awesome-icon', {
    staticClass: "remarks",
    attrs: {
      "title": _vm.remarks,
      "icon": ['far', 'comment']
    }
  }) : _vm._e(), _c('div', {
    staticClass: "front",
    style: {
      width: _vm.calculatedWidth + 'px',
      height: _vm.calculatedHeight + 'px'
    },
    on: {
      "click": _vm.checkClick
    }
  }, [_c('div', {
    staticClass: "content",
    style: {
      backgroundImage: _vm.frontImg ? 'url(' + _vm.frontImg + ')' : 'none',
      backgroundSize: 100 * _vm.frontImageScale + '%'
    }
  }, [_vm.width && _vm.showDimensions ? _c('span', {
    staticClass: "width"
  }, [_vm._v(" " + _vm._s(_vm.formatNumber(_vm.width)) + _vm._s(_vm.dimensionUnit) + " ")]) : _vm._e(), _vm.height && _vm.showDimensions ? _c('span', {
    staticClass: "height",
    style: {
      width: _vm.calculatedHeight + 'px'
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.formatNumber(_vm.height)) + _vm._s(_vm.dimensionUnit) + " ")])]) : _vm._e(), _vm.name ? _c('span', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))]) : _vm._e(), _vm.frontColors && _vm.frontColors.length > 0 ? _c('div', {
    staticClass: "color-list"
  }, _vm._l(_vm.frontColors, function (color) {
    return _c('font-awesome-icon', {
      key: color,
      class: 'color-item text-' + _vm.mapColor(color),
      attrs: {
        "icon": "square",
        "title": color
      }
    });
  }), 1) : _vm._e(), (_vm$colors = _vm.colors) !== null && _vm$colors !== void 0 && _vm$colors.front ? _c('div', {
    staticClass: "color-list"
  }, _vm._l(_vm.colors.front, function (color, index) {
    return _c('div', {
      key: index,
      staticClass: "color-item"
    }, [color === 'CMYK' ? [_c('font-awesome-icon', {
      staticClass: "color-item text-cyan",
      attrs: {
        "icon": "square",
        "title": "cyan"
      }
    }), _c('font-awesome-icon', {
      staticClass: "color-item text-magenta",
      attrs: {
        "icon": "square",
        "title": "magenta"
      }
    }), _c('font-awesome-icon', {
      staticClass: "color-item text-yellow",
      attrs: {
        "icon": "square",
        "title": "yellow"
      }
    }), _c('font-awesome-icon', {
      staticClass: "color-item text-black",
      attrs: {
        "icon": "square",
        "title": "black"
      }
    })] : color === 'PMS' ? [_c('font-awesome-icon', {
      staticClass: "color-item text-pms1",
      attrs: {
        "icon": "square",
        "title": "PMS"
      }
    })] : color === 'Black' ? [_c('font-awesome-icon', {
      staticClass: "color-item text-black",
      attrs: {
        "icon": "square",
        "title": "PMS"
      }
    })] : _vm._e()], 2);
  }), 0) : _vm._e()])]), _vm.doubleSided ? _c('div', {
    staticClass: "back",
    style: {
      width: _vm.calculatedWidth + 'px',
      height: _vm.calculatedHeight + 'px'
    },
    on: {
      "click": _vm.checkClick
    }
  }, [_c('div', {
    staticClass: "content",
    style: {
      backgroundImage: _vm.backImg ? 'url(' + _vm.backImg + ')' : 'none'
    }
  }, [_vm.backColors && _vm.backColors.length > 0 ? _c('div', {
    staticClass: "color-list"
  }, _vm._l(_vm.backColors, function (color) {
    return _c('font-awesome-icon', {
      key: color,
      class: 'color-item text-' + _vm.mapColor(color),
      attrs: {
        "icon": "square",
        "title": color
      }
    });
  }), 1) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "actions"
  }, [_vm._t("actions")], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }