































import Vue from 'vue'

import SizeItem from '@/components/Resource/SizeItem.vue'
import Heading from '@/components/ui/Heading.vue'
import CreateSizeModal from '@/components/Resource/Create/CreateSizeModal.vue'
import { ISize } from '@/types/api/Interfaces'
import SizePersistence from '@/persistence/SizePersistence'

export default Vue.extend({
    name: 'Sizes',

    components: {
        CreateSizeModal,
        Heading,
        SizeItem,
    },

    computed: {
        sizes(): Array<ISize> {
            return this.$store.state.positionSizes.items
        },
    },

    async mounted(): Promise<void> {
        await SizePersistence.loadAll()
    },

    methods: {
        async createSize(size: ISize): Promise<void> {
            await SizePersistence.create(size)
        },
    },
})
