import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=246de14f&scoped=true&"
import script from "./Materials.vue?vue&type=script&lang=ts&"
export * from "./Materials.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246de14f",
  null
  
)

export default component.exports