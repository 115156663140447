var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "body-class": "text-center"
    }
  }, [_c('breeding-rhombus-spinner', {
    staticClass: "mx-auto my-5",
    attrs: {
      "animation-duration": 2000,
      "size": 65,
      "color": "#561894"
    }
  }), _vm.title ? _c('h4', [_vm._v(_vm._s(_vm.title))]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }