




































































import Vue, { PropType } from 'vue'
import prettyBytes from 'pretty-bytes'

import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import { IMediaObject } from '@/types/api/Interfaces'

interface IData {
    imageUrl?: string
    name: string
}

interface IDimensions {
    width: number
    height: number
    unit: string
}

export default Vue.extend({
    name: 'FilePreview',
    components: { MaterialBlueprint },

    props: {
        mediaObject: {
            type: Object as PropType<IMediaObject>,
            default: undefined,
        },

        selected: {
            type: Boolean,
            default: false,
        },

        selectable: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: false,
        },
    },

    data(): IData {
        return {
            name: 'Nameless',
            imageUrl: undefined,
        }
    },

    computed: {
        size(): string {
            return prettyBytes(
                Number.parseInt(this.mediaObject.size ?? 0, 10),
                {
                    locale: 'nl-NL',
                }
            )
        },

        dimensions(): IDimensions | undefined {
            if (this.mediaObject?.trimBox) {
                return {
                    width:
                        Number.parseFloat(this.mediaObject?.trimBox?.width) -
                        Number.parseFloat(this.mediaObject?.trimBox.x),

                    height:
                        Number.parseFloat(this.mediaObject?.trimBox?.height) -
                        Number.parseFloat(this.mediaObject?.trimBox.y),

                    unit: 'mm',
                }
            }

            if (this.mediaObject?.heightPx && this.mediaObject?.widthPx) {
                return {
                    width: this.mediaObject.widthPx,
                    height: this.mediaObject.heightPx,
                    unit: 'px',
                }
            }

            return undefined
        },
    },

    mounted(): void {
        if (this.mediaObject?.thumbnailUrl) {
            this.imageUrl = this.mediaObject.thumbnailUrl
        }
    },

    methods: {},
})
