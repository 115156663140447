import { Module } from 'vuex'

import RootState from '@/store/IRootState'
import state from '@/store/modules/cart/state'
import mutations from '@/store/modules/cart/mutations'
import getters from '@/store/modules/cart/getters'
import actions from '@/store/modules/cart/actions'
import IState from '@/store/modules/cart/IState'

const cart: Module<IState, RootState> = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}

export default cart
