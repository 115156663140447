var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "breadcrumbs",
      fn: function fn() {
        return [_c('b-breadcrumb-item', {
          attrs: {
            "to": {
              name: 'Wizards'
            }
          }
        }, [_vm._v(" Producten ")])];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('div', {
    staticClass: "wizards"
  }, [_c('ResourceItem', {
    attrs: {
      "uri": '/api/wizards/' + _vm.id
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.load($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "6"
          }
        }, [_c('b-card', {
          staticClass: "mb-3",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-img', {
          attrs: {
            "top": "",
            "src": "https://www.kpmz.nl/wp-content/uploads/2020/12/wico-1.jpg"
          }
        }), _c('b-card-body', [_c('b-form-group', {
          attrs: {
            "label": "Pagina's",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": "1",
            "min": item.minPages,
            "max": item.maxPages,
            "value": item.minPages
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Type",
            "label-cols-lg": "3"
          }
        }, [_c('b-radio-group', {
          attrs: {
            "stacked": ""
          }
        }, [_c('b-radio', {
          attrs: {
            "value": "singleSided"
          }
        }, [_vm._v(" Enkelzijdig ")]), _c('b-radio', {
          attrs: {
            "value": "doubleSided"
          }
        }, [_vm._v(" Dubbelzijdig ")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "lg": "6"
          }
        }, [_vm._l(item.production, function (production, pId) {
          return _c('ProductionItem', {
            key: pId,
            staticClass: "mb-3",
            attrs: {
              "show-label": item.production.length > 1,
              "production": production
            }
          });
        }), _c('b-card', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('b-btn', {
          attrs: {
            "variant": "light",
            "size": "lg"
          }
        }, [_vm._v(" Bestanden aanleveren ")]), _c('b-btn', {
          attrs: {
            "variant": "success",
            "size": "lg"
          },
          on: {
            "click": _vm.addToCart
          }
        }, [_vm._v(" Bestellen ")])], 1)])], 2)], 1)];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }