var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "campaign mb-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "card-img-overlay"
  }, [_c('div', {
    staticClass: "d-flex h-100 justify-content-end align-items-end"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "price"
  }, [_vm._v(" 3, "), _c('span', [_vm._v("99")])]), _c('div', {
    staticClass: "weight text-muted"
  }, [_vm._v("A4")]), _c('div', {
    staticClass: "weight text-muted"
  }, [_vm._v("Full Color")]), _c('div', {
    staticClass: "weight text-muted"
  }, [_vm._v("Print")])])])]), _c('b-card-img', {
    ref: "image",
    attrs: {
      "src": require("@/assets/product.jpg")
    }
  })], 1), _c('b-card-body', [_c('div', {
    staticClass: "selected-quantity"
  }, [_c('b-card-title', {
    staticClass: "flex-grow-1 item-title mb-0",
    attrs: {
      "title-tag": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")])], 1)]), _c('b-card-footer', [_vm.currentQuantity > 0 ? _c('QuantitySelector', {
    attrs: {
      "id": _vm.id,
      "name": _vm.name
    },
    on: {
      "add": _vm.animateAdd
    }
  }) : _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "ml-auto add"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.add();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }