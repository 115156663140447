/* eslint-disable import/max-dependencies -- Come on, it is a routes file! */
/* eslint-disable max-lines -- It's a config file */
import Campaigns from '@/screens/Relation/Campaigns.vue'
import Cart from '@/screens/Relation/Cart.vue'
import Stores from '@/screens/Relation/Stores.vue'
import Products from '@/screens/Relation/Products.vue'
import Templates from '@/screens/Relation/Templates.vue'
import Assets from '@/screens/Relation/Assets.vue'
import TemplateEdit from '@/components/Resource/TemplateEdit.vue'
import TemplateList from '@/screens/Relation/TemplateList.vue'
import WizardList from '@/screens/Relation/WizardList.vue'
import Wizard from '@/screens/Relation/Wizard.vue'
import ResetPasswordReset from '@/screens/ResetPassword/ResetPasswordReset.vue'
import ResetPasswordRequest from '@/screens/ResetPassword/ResetPasswordRequest.vue'
import PageNotFound from '@/screens/Core/PageNotFound.vue'
import Login from '@/screens/Core/Login.vue'
import Dashboard from '@/screens/Core/Dashboard.vue'
import Departments from '@/screens/Relation/Departments.vue'
import StoreTypes from '@/screens/Relation/StoreTypes.vue'
import StoreTypeItem from '@/screens/Relation/StoreTypeItem.vue'
import GlobalDna from '@/screens/Relation/GlobalDna.vue'
import Store from '@/screens/Relation/Store.vue'
import AccessDenied from '@/screens/Core/AccessDenied.vue'
import CampaignItem from '@/screens/Relation/CampaignItem.vue'
import Sizes from '@/screens/Relation/Sizes.vue'
import Files from '@/screens/Relation/Files.vue'
import Packages from '@/screens/Relation/Packages.vue'
import Relations from '@/screens/Admin/Relations.vue'
import Orders from '@/screens/Admin/Orders.vue'
import Invoices from '@/screens/Admin/Invoices.vue'
import ProductionProducts from '@/screens/Admin/ProductionProducts.vue'
import StockProducts from '@/screens/Admin/StockProducts.vue'
import Users from '@/screens/Admin/Users.vue'
import Materials from '@/screens/Admin/Materials.vue'
import ProductionProductItem from '@/screens/Admin/ProductionProductItem.vue'
import Order from '@/screens/Admin/Order.vue'
import ScreenTags from '@/screens/Relation/ScreenTags.vue'
import IRouteConfigSingleView from '@/types/IRouteConfigSingleView'

const routes: Array<IRouteConfigSingleView> = [
    {
        path: '/',
        component: { template: '<router-view/>' },

        meta: {
            breadcrumb: 'Home',
            requiredRoles: ['ROLE_USER'],

            accessControl: {
                anonymous: false,
                authenticated: true,
            },
        },

        children: [
            {
                path: '',
                redirect: '/dashboard',

                meta: {
                    breadcrumb: 'Root',

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Dashboard',
                path: 'dashboard',
                component: Dashboard,

                meta: {
                    breadcrumb: 'Dashboard',
                    requiredRoles: ['ROLE_USER'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Relations',
                path: 'relations',
                component: Relations,

                meta: {
                    breadcrumb: 'Relations',
                    requiredRoles: ['ROLE_ADMIN'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Orders',
                path: 'orders',
                component: Orders,

                meta: {
                    breadcrumb: 'Orders',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'Order',
                path: 'orders/:id',
                component: Order,
                props: true,

                meta: {
                    breadcrumb: 'Order',
                    requiredRoles: ['ROLE_ADMIN'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Invoices',
                path: 'invoices',
                component: Invoices,

                meta: {
                    breadcrumb: 'Invoices',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'ProductionProducts',
                path: 'production_products',
                component: ProductionProducts,

                meta: {
                    breadcrumb: 'Productieproducten',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'ProductionProductItem',
                path: 'production_products/edit/:id',

                props: true,

                component: ProductionProductItem,

                meta: {
                    breadcrumb: 'Productieproduct',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'StockProducts',
                path: 'stock_products',
                component: StockProducts,

                meta: {
                    breadcrumb: 'Voorraadproducten',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'Users',
                path: 'users',
                component: Users,

                meta: {
                    breadcrumb: 'Gebruikers',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'Materials',
                path: 'materials',
                component: Materials,

                meta: {
                    breadcrumb: 'Materialen',
                    requiredRoles: ['ROLE_ADMIN'],
                },
            },
            {
                name: 'GlobalDna',
                path: 'globaldna',
                component: GlobalDna,

                meta: {
                    breadcrumb: 'GlobalDna',
                    requiredRoles: ['ROLE_UNUSED'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Sizes',
                path: 'sizes',
                component: Sizes,

                meta: {
                    breadcrumb: 'Sizes',
                    requiredRoles: ['ROLE_UNUSED'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Stores',
                path: 'stores',
                component: Stores,

                meta: {
                    breadcrumb: 'Stores',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Store',
                path: 'stores/:id',
                component: Store,
                props: true,

                meta: {
                    breadcrumb: 'Store',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'StoreTypes',
                path: 'store-types',
                component: StoreTypes,

                meta: {
                    breadcrumb: 'Store types',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Tags',
                path: 'tags',
                component: ScreenTags,

                meta: {
                    breadcrumb: 'Tags',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },

            {
                name: 'StoreTypeItem',
                path: 'store-types/edit/:id',
                component: StoreTypeItem,
                props: true,

                meta: {
                    breadcrumb: 'Store type item',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Cart',
                path: 'cart',
                component: Cart,

                meta: {
                    breadcrumb: 'Cart',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Campaigns',
                path: 'campaigns',
                component: Campaigns,

                meta: {
                    breadcrumb: 'Campaigns',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                path: 'packages',
                component: Packages,

                meta: {
                    breadcrumb: 'Packages',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'CampaignItem',
                path: 'campaigns/edit/:id',
                component: CampaignItem,
                props: true,

                meta: {
                    breadcrumb: 'Campaign Item',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Products',
                path: 'products',
                component: Products,

                meta: {
                    breadcrumb: 'Products',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Wizards',
                path: 'producten',
                component: WizardList,

                meta: {
                    breadcrumb: 'Producten',
                    requiredRoles: ['ROLE_UNUSED'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'WizardItem',
                path: 'producten/:id',
                component: Wizard,
                props: true,

                meta: {
                    breadcrumb: 'Producten',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Departments',
                path: 'departments',
                component: Departments,

                meta: {
                    breadcrumb: 'Afdelingen',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                name: 'Files',
                path: 'files',
                component: Files,

                meta: {
                    breadcrumb: 'Bestanden',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                path: 'templates',
                component: Templates,

                meta: {
                    breadcrumb: 'Templates',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },

                children: [
                    {
                        name: 'Templates',
                        path: '',
                        component: TemplateList,

                        meta: {
                            breadcrumb: 'Template list',
                            requiredRoles: ['ROLE_RELATION'],

                            accessControl: {
                                anonymous: false,
                                authenticated: true,
                            },
                        },
                    },
                    {
                        name: 'TemplateItem',
                        path: 'edit/:id',
                        component: TemplateEdit,

                        meta: {
                            breadcrumb: 'Template edit',
                            requiredRoles: ['ROLE_RELATION'],

                            accessControl: {
                                anonymous: false,
                                authenticated: true,
                            },
                        },
                    },
                ],
            },
            {
                name: 'Assets',
                path: 'assets',
                component: Assets,

                meta: {
                    breadcrumb: 'Assets',
                    requiredRoles: ['ROLE_RELATION'],

                    accessControl: {
                        anonymous: false,
                        authenticated: true,
                    },
                },
            },
            {
                path: 'login',
                component: Login,
                name: 'Login',

                meta: {
                    breadcrumb: 'Login',
                    title: 'Login',

                    accessControl: {
                        anonymous: true,
                        authenticated: false,
                    },
                },
            },
            {
                name: 'ForgotPasswordRequest',
                path: 'forgot-password/request',
                component: ResetPasswordRequest,

                meta: {
                    breadcrumb: 'Forgot password',
                    title: 'Forgot password',

                    accessControl: {
                        anonymous: true,
                        authenticated: false,
                    },
                },
            },
            {
                name: 'ForgotPasswordReset',
                path: 'forgot-password/reset',
                component: ResetPasswordReset,

                meta: {
                    breadcrumb: 'Reset password',
                    title: 'Reset password',

                    accessControl: {
                        anonymous: true,
                        authenticated: false,
                    },
                },
            },
            {
                path: '*',
                name: 'PageNotFound',
                component: PageNotFound,

                meta: {
                    breadcrumb: 'Page not found',
                    title: 'Page not found',

                    accessControl: {
                        anonymous: true,
                        authenticated: true,
                    },
                },
            },
            {
                path: '*',
                component: AccessDenied,
                name: 'AccessDenied',

                meta: {
                    breadcrumb: 'Geen toegang',
                    title: 'Geen toegang',

                    accessControl: {
                        anonymous: true,
                        authenticated: true,
                    },
                },
            },
        ],
    },
]

export default routes
