/* eslint-disable import/max-dependencies -- Come on, it is an index file! */

import Vuex, { Store } from 'vuex'
import Vue from 'vue'
import store from '@keicreations/sapphire-vue/store/index'

import tags from '@/store/modules/tags/index'
import auth from '@/store/modules/auth/index'
import app from '@/store/modules/app/index'
import side from '@/store/modules/nav/index'
import cart from '@/store/modules/cart/index'
import storeTypes from '@/store/modules/storeTypes/index'
import storeDepartments from '@/store/modules/storeDepartments/index'
import storeSegments from '@/store/modules/storeSegments/index'
import positions from '@/store/modules/positions/index'
import positionSizes from '@/store/modules/positionSizes/index'
import stores from '@/store/modules/stores/index'
import mediaObjects from '@/store/modules/mediaObjects/index'
import mediaObjectWatchers from '@/store/modules/mediaObjectWatchers/index'
import mediaObjectUploads from '@/store/modules/mediaObjectUploads/index'
import campaigns from '@/store/modules/campaigns/index'
import campaignPackages from '@/store/modules/campaignPackages/index'
import campaignPackageStoreTypes from '@/store/modules/campaignPackageStoreTypes/index'
import campaignItems from '@/store/modules/campaignItems/index'
import productionProducts from '@/store/modules/productionProducts/index'
import productionPackages from '@/store/modules/productionPackages/index'
import productionPackageItems from '@/store/modules/productionPackageItems/index'
import materials from '@/store/modules/materials/index'
import relations from '@/store/modules/relations/index'
import IRootState from '@/store/IRootState'
import users from '@/store/modules/users'

Vue.use(Vuex)

const typedStore: Store<IRootState> = store

/**
 * IMPORTANT!
 *
 * Please remember to register the modules IState in IRootState as well!
 * This is needed for the typescript compiler
 */

typedStore.registerModule('app', app)
typedStore.registerModule('nav.side', side)
typedStore.registerModule('cart', cart)
typedStore.registerModule('auth', auth)
typedStore.registerModule('storeTypes', storeTypes)
typedStore.registerModule('storeDepartments', storeDepartments)
typedStore.registerModule('positions', positions)
typedStore.registerModule('storeSegments', storeSegments)
typedStore.registerModule('stores', stores)
typedStore.registerModule('positionSizes', positionSizes)
typedStore.registerModule('mediaObjects', mediaObjects)
typedStore.registerModule('mediaObjectWatchers', mediaObjectWatchers)
typedStore.registerModule('mediaObjectUploads', mediaObjectUploads)
typedStore.registerModule('campaigns', campaigns)
typedStore.registerModule('campaignItems', campaignItems)
typedStore.registerModule('materials', materials)
typedStore.registerModule('relations', relations)
typedStore.registerModule('tags', tags)
typedStore.registerModule('productionPackages', productionPackages)
typedStore.registerModule('productionProducts', productionProducts)
typedStore.registerModule('productionPackageItems', productionPackageItems)
typedStore.registerModule('campaignPackages', campaignPackages)
typedStore.registerModule(
    'campaignPackageStoreTypes',
    campaignPackageStoreTypes
)
typedStore.registerModule('users', users)

export default typedStore
