import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class StoreDepartmentPersistence extends ApiPersistence {}

export default new StoreDepartmentPersistence(
    store.state.storeDepartments,
    api,
    '/api/store_departments'
)
