import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class ProductionPackageItemPersistence extends ApiPersistence {}

export default new ProductionPackageItemPersistence(
    store.state.productionPackageItems,
    api,
    '/api/relation_production_package_items'
)
