















import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'ArtworkProgress',

    props: {
        assignedArtworkCount: {
            type: Number,
            required: true,
        },

        unassignedArtworkCount: {
            type: Number,
            required: true,
        },
    },
})
