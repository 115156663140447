
















import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import { IProductionProduct } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'ProductionProductItem',
    components: { MaterialBlueprint },

    props: {
        productProp: {
            type: String,
            required: true,
        },
    },

    computed: {
        product(): IProductionProduct | undefined {
            return this.$store.state.productionProducts.items.find(
                (item: IProductionProduct): boolean =>
                    item['@id'] === this.productProp
            )
        },

        knownDimensions(): boolean {
            return (
                this.product?.height !== undefined &&
                this.product?.width !== undefined
            )
        },

        productHeight(): string | number {
            if (this.knownDimensions) {
                return this.product!.height
            }

            return '1'
        },

        productWidth(): string | number {
            if (this.knownDimensions) {
                return this.product!.width
            }

            return '1'
        },
    },
})
