var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "m-2 d-flex align-items-center"
  }, [!_vm.editTitle ? _c('div', {
    staticClass: "flex-grow-1 mb-2",
    on: {
      "click": function click($event) {
        _vm.editTitle = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.tag.title) + " ")]) : _vm._e(), _vm.editTitle ? _c('b-form-input', {
    staticClass: "flex-grow-1",
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.saveTag();
      }
    },
    model: {
      value: _vm.tag.title,
      callback: function callback($$v) {
        _vm.$set(_vm.tag, "title", $$v);
      },
      expression: "tag.title"
    }
  }) : _vm._e(), _vm.editTitle ? _c('b-button', {
    attrs: {
      "variant": "",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.saveTag();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'check']
    }
  })], 1) : _vm._e(), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.showLinkTag();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'link']
    }
  })], 1), !_vm.editTitle ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.editTitle = true;
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'pencil']
    }
  })], 1) : _vm._e(), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteTag();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'trash']
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }