
































import { PropType } from 'vue'

import OrderStatusDropdown from '@/components/ui/OrderStatusDropdown.vue'
import CampaignDates from '@/components/ui/CampaignDates.vue'
import { persistenceErrorOptions } from '@/constants/messages'
import orderStatus, { IOrderStatus } from '@/constants/order-status'
import RelationPersistence from '@/persistence/RelationPersistence'
import { ICampaign, IRelation } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import CampaignStatusTable from '@/components/ui/CampaignStatusTable.vue'

interface IData {
    orderStatus: Array<IOrderStatus>
}

export default TypedVue.extend({
    name: 'CampaignDetailItem',
    components: { CampaignStatusTable, CampaignDates, OrderStatusDropdown },

    props: {
        campaign: Object as PropType<ICampaign>,
    },

    data(): IData {
        return {
            orderStatus,
        }
    },

    async mounted(): Promise<void> {
        await RelationPersistence.getByIri(this.campaign?.relation)
    },

    methods: {
        status(name: string): IOrderStatus | undefined {
            return this.orderStatus.find(
                (item: IOrderStatus): boolean => item.status === name
            )
        },

        async downloadArtwork(): Promise<void> {
            try {
                const response = await this.$api
                    .authenticated()
                    .get(`/api/campaigns/${this.campaign.id}/request_download`)
                const token = response.data?.token

                // @ts-expect-error -- 1337 h4ck4r
                window.location = `${process.env.VUE_APP_API_URL}/downloads/campaign/${token}`
            } catch {
                await this.$bvModal.msgBoxOk(
                    'Het bestand kon niet worden gedownload.',
                    persistenceErrorOptions
                )
            }
        },
    },

    computed: {
        relation(): IRelation | undefined {
            return this.$store.state.relations.items.find(
                (item: IRelation): boolean =>
                    item['@id'] === this.campaign.relation
            )
        },
    },
})
