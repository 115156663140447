var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.product ? _c('MaterialBlueprint', {
    staticClass: "shadow-sm",
    attrs: {
      "title": _vm.product.name,
      "show-dimensions": !_vm.pendingMediaObject,
      "height": _vm.pendingMediaObject ? 1 : _vm.product.height,
      "width": _vm.pendingMediaObject ? 1 : _vm.product.width,
      "name": _vm.pendingMediaObject ? _vm.pendingMediaObject.status : undefined,
      "colors": _vm.product.colors,
      "front-img": _vm.pendingMediaObject || !_vm.mediaObject ? undefined : _vm.mediaObject.thumbnailUrl,
      "remarks": _vm.campaignItem.remarks
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.disabled ? _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top.noninteractive",
            modifiers: {
              "hover": true,
              "top": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "variant": "light",
            "size": "lg",
            "title": "Nieuw bestand uploaden"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('upload');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'file-upload']
          }
        })], 1) : _vm._e(), _c('hr'), !_vm.disabled ? _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.left.noninteractive",
            modifiers: {
              "hover": true,
              "left": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "variant": "light",
            "title": "Bestand selecteren"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('browse');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'folder-open']
          }
        })], 1) : _vm._e(), _vm.mediaObject && !_vm.disabled ? _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right.noninteractive",
            modifiers: {
              "hover": true,
              "right": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "variant": "light",
            "title": "Bestand ontkoppelen"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('reset');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'times']
          }
        })], 1) : _vm._e(), !_vm.disabled ? _c('hr') : _vm._e(), _vm.mediaObject ? _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.bottom.noninteractive",
            modifiers: {
              "hover": true,
              "bottom": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "title": "Voorbeeld bekijken",
            "variant": "light"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('preview', {
                mediaObject: _vm.mediaObject,
                product: _vm.product
              });
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'expand']
          }
        })], 1) : _vm._e(), !_vm.disabled ? _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.bottom.noninteractive",
            modifiers: {
              "hover": true,
              "bottom": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "variant": "light",
            "title": "Opmerking bewerken"
          },
          on: {
            "click": function click($event) {
              return _vm.showRemarksModal();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'comment-edit']
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 36444370)
  }) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": 'remarksModal' + _vm.campaignItem.id,
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light",
      "cancel-title": "Annuleer",
      "ok-title": "Toevoegen",
      "centered": "",
      "title": "Opmerkingen"
    },
    on: {
      "ok": function ok($event) {
        return _vm.patchRemarks();
      }
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.campaignItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.campaignItem, "remarks", $$v);
      },
      expression: "campaignItem.remarks"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }