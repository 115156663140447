var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Pakketten"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "lg"
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.show('create-modal');
            }
          }
        }, [_c('font-awesome-icon', {
          staticClass: "mr-1",
          attrs: {
            "icon": ['far', 'plus']
          }
        }), _vm._v(" Nieuw pakket ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-5"
  }, [!_vm.loaded ? _c('LoadingCard', {
    attrs: {
      "title": "Pakketten worden geladen..."
    }
  }) : _c('transition-group', {
    attrs: {
      "tag": "div",
      "name": "slide-fade"
    }
  }, _vm._l(_vm.productionPackages, function (productionPackage) {
    return _c('ProductionPackageItem', {
      key: productionPackage['@id'],
      staticClass: "mb-3",
      attrs: {
        "package-iri": productionPackage['@id']
      }
    });
  }), 1)], 1), _c('b-modal', {
    attrs: {
      "id": "create-modal",
      "title": "Nieuw pakket",
      "ok-title": "Toevoegen",
      "cancel-title": "Annuleer",
      "ok-disabled": !_vm.name,
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": _vm.newPackage,
      "hidden": function hidden($event) {
        _vm.name = undefined;
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-input', {
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }