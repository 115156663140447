import { Module } from 'vuex'

import IRootState from '@/store/IRootState'
import IState from '@/store/modules/stores/IState'
import state from '@/store/modules/stores/state'

const main: Module<IState, IRootState> = {
    namespaced: true,
    state,
}

export default main
