









































































































import { PropType } from 'vue'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import { IMediaObjectGalleryItem } from '@/types/IMediaObjectGallery'
import orderStatus, { IOrderStatus } from '@/constants/order-status'
import CampaignItemPersistence from '@/persistence/CampaignItemPersistence'
import TypedVue from '@/config/vue'
import { ICampaign, ICampaignItem } from '@/types/api/Interfaces'
import OrderProgress from '@/components/ui/OrderProgress.vue'
import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import CampaignItemPreview from '@/components/Resource/CampaignItemPreview.vue'
import CampaignDates from '@/components/ui/CampaignDates.vue'
import ArtworkProgress from '@/components/ui/ArtworkProgress.vue'
// eslint-disable-next-line import/max-dependencies -- mag van Alex
import { CampaignStatus } from '@/enums/CampaignStatus'

const maxPreviews = 4

dayjs.extend(weekOfYear)

interface IData {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- enum
    CampaignStatus: typeof CampaignStatus
}

export default TypedVue.extend({
    name: 'CampaignItem',

    data(): IData {
        return {
            CampaignStatus,
        }
    },

    components: {
        ArtworkProgress,
        CampaignDates,
        CampaignItemPreview,
        MaterialBlueprint,
        OrderProgress,
    },

    props: {
        campaign: {
            type: Object as PropType<ICampaign>,
            required: true,
        },
    },

    async mounted(): Promise<void> {
        await Promise.all(
            this.campaign.items.map(
                async (campaignItemIri: string): Promise<void> => {
                    await CampaignItemPersistence.load(campaignItemIri)
                }
            )
        )
    },

    computed: {
        campaignItems(): Array<ICampaignItem> {
            return this.$store.state.campaignItems.items
                .filter(
                    (campaignItem: ICampaignItem): boolean =>
                        this.campaign['@id'] === campaignItem.campaign
                )
                .slice()
                .sort(
                    (previous: ICampaignItem, next: ICampaignItem): number =>
                        previous.id - next.id
                )
        },

        campaignItemsWithMedia(): Array<ICampaignItem> {
            return this.campaignItems.filter(
                (campaignItem: ICampaignItem): boolean =>
                    campaignItem.mediaObject !== undefined
            )
        },

        gallery(): Array<IMediaObjectGalleryItem> {
            return this.campaignItemsWithMedia
                .filter((campaignItem: ICampaignItem): boolean => {
                    const mediaObject =
                        this.$store.state.mediaObjects.items.find(
                            (item): boolean =>
                                item['@id'] === campaignItem.mediaObject
                        )

                    return mediaObject?.previewUrl !== undefined
                })
                .map((campaignItem: ICampaignItem): IMediaObjectGalleryItem => {
                    const mediaObject =
                        this.$store.state.mediaObjects.items.find(
                            (item): boolean =>
                                item['@id'] === campaignItem.mediaObject
                        )

                    // eslint-disable-next-line init-declarations -- dont whine
                    let campaignPackage

                    if (campaignItem.campaignPackage !== undefined) {
                        campaignPackage =
                            this.$store.state.campaignPackages.items.find(
                                (item): boolean =>
                                    item['@id'] === campaignItem.campaignPackage
                            )
                    }

                    const productionProduct =
                        this.$store.state.productionProducts.items.find(
                            (item): boolean =>
                                item['@id'] ===
                                campaignItem.relationProductionProduct
                        )

                    return {
                        subTitle: `${campaignPackage?.name ?? ''}: ${
                            productionProduct?.name ?? ''
                        }`,

                        title: mediaObject!.name,
                        imageSrc: mediaObject!.previewUrl!,
                        thumbnailSrc: mediaObject!.thumbnailUrl,
                    }
                })
        },

        firstCampaignItemsWithMedia(): Array<ICampaignItem> {
            return this.campaignItemsWithMedia.slice(0, maxPreviews)
        },

        extraMedia(): number {
            return (
                this.campaignItems.length -
                this.firstCampaignItemsWithMedia.length
            )
        },

        statusObject(): IOrderStatus | undefined {
            return orderStatus.find(
                (item): boolean => item.status === this.campaign.status
            )
        },

        canDelete(): boolean {
            return this.campaign.status === CampaignStatus.Concept
        },
    },
})
