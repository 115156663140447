var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    staticClass: "mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [_vm._v(" " + _vm._s(_vm.position.name) + " ")]), _c('b-badge', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.sizeName))]), _c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.sizeWidth) + "x" + _vm._s(_vm.sizeHeight) + "mm ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }