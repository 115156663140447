import { AxiosResponse, AxiosRequestConfig } from 'axios'

import IApiPersistenceState from '@/store/IApiPersistenceState'
import IHydraCollection from '@/types/api/IHydraCollection'
import IApiResource from '@/types/api/IApiResource'
import ISapphireVueApi from '@/types/api/ISapphireVueApi'
import { ITransitionMetadata } from '@/types/api/Interfaces'

// eslint-disable-next-line @typescript-eslint/naming-convention -- constant
const DEFAULT_ITEMS_PER_PAGE = 20

export default abstract class ApiPersistence {
    protected api: ISapphireVueApi

    protected state: IApiPersistenceState

    protected endPoint: string

    protected additionalTypes: Array<string>

    protected itemsPerPage: number

    // eslint-disable-next-line max-params -- mag van martijn
    public constructor(
        state: IApiPersistenceState,
        api: ISapphireVueApi,
        endPoint: string,
        additionalTypes?: Array<string>,
        itemsPerPage?: number
    ) {
        this.state = state
        this.api = api
        this.endPoint = endPoint
        this.itemsPerPage = itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE
        this.additionalTypes = additionalTypes ?? []
    }

    public getEndpoint(): string {
        return this.endPoint
    }

    protected appendItems(items: Array<IApiResource>): void {
        items.forEach((item: IApiResource): void => {
            this.append(item)
        })
    }

    protected checkType(uri: string): boolean {
        if (uri.startsWith(`${this.endPoint}/`)) {
            return true
        }

        return this.additionalTypes.some((additionalType): boolean =>
            uri.startsWith(`${additionalType}/`)
        )
    }

    public append(item: IApiResource): void {
        if (this.exists(item['@id'])) {
            this.replace(item)
        } else {
            this.state.items.push(item)
        }
    }

    protected clear(): void {
        this.state.items.splice(0)
    }

    public replace(item: IApiResource): void {
        const index = this.state.items.findIndex(
            (x): boolean => x['@id'] === item['@id']
        )

        if (index > -1) {
            if (Object.keys(item).length === 1) {
                this.state.items.splice(index, 1)
            } else {
                this.state.items.splice(index, 1, item)
            }
        }
    }

    protected removeItem(uri: string): void {
        const index = this.state.items.findIndex(
            (x): boolean => x['@id'] === uri
        )

        if (index > -1) {
            this.state.items.splice(index, 1)
        }
    }

    private exists(iri: string): boolean {
        return this.findIndex(iri) > -1
    }

    protected findIndex(iri: string): number {
        return this.state.items.findIndex((x): boolean => x['@id'] === iri)
    }

    public async create(data: object): Promise<IApiResource> {
        return <IApiResource>await this.post(this.endPoint, data)
    }

    public async update(uri: string, data: object): Promise<IApiResource> {
        if (!this.checkType(uri)) {
            throw new Error(
                `Invalid uri supplied for endpoint "${this.endPoint}"`
            )
        }

        return <IApiResource>await this.patch(uri, data)
    }

    // eslint-disable-next-line max-params -- mag van Alex
    public async transition(
        uri: string,
        state: string,
        transition: string,
        metadata: ITransitionMetadata | undefined = undefined
    ): Promise<IApiResource> {
        if (!this.checkType(uri)) {
            throw new Error(
                `Invalid uri supplied for endpoint "${this.endPoint}"`
            )
        }

        return <IApiResource>await this.patch(`${uri}/${state}/${transition}`, {
            ...metadata,
        })
    }

    public async getByIri(iri: string): Promise<IApiResource | undefined> {
        if (!this.checkType(iri)) {
            throw new Error(
                `Supplied iri "${iri}" does not match Persistence base endpoint "${this.endPoint}"`
            )
        }

        let index = this.findIndex(iri)

        if (index === -1) {
            await this.fetchOne(iri)
            index = this.findIndex(iri)
        }

        return this.state.items[index]
    }

    public async remove(uri: string): Promise<IApiResource> {
        if (!this.checkType(uri)) {
            throw new Error(
                `Invalid uri supplied for endpoint "${this.endPoint}"`
            )
        }

        return <IApiResource>await this.delete(uri)
    }

    protected async delete(uri: string): Promise<IApiResource> {
        if (!this.checkType(uri)) {
            throw new Error(
                `Invalid uri supplied for endpoint "${this.endPoint}"`
            )
        }

        // eslint-disable-next-line no-useless-catch -- care
        try {
            const response: AxiosResponse = await this.api
                .authenticated()
                .delete(uri)

            const item: IApiResource = response.data

            this.removeItem(uri)

            return item
            // eslint-disable-next-line sonarjs/no-useless-catch -- maakt mij niet uit
        } catch (error) {
            throw error
        }
    }

    public async loadAll(): Promise<void> {
        await this.fetchAll(this.endPoint)
    }

    public async load(iri: string): Promise<void> {
        if (!this.checkType(iri)) {
            throw new Error(
                `Invalid uri supplied for endpoint "${this.endPoint}"`
            )
        }

        await this.fetchOne(iri)
    }

    protected async post(
        uri: string,
        data: object,
        config: AxiosRequestConfig | undefined = undefined
    ): Promise<IApiResource> {
        const response: AxiosResponse = await this.api
            .authenticated()
            .post(uri, data, config)

        const item: IApiResource = response.data

        this.append(item)

        return item
    }

    protected async patch(uri: string, data: object): Promise<IApiResource> {
        const response: AxiosResponse = await this.api
            .authenticated()
            .patch(uri, data)

        const item: IApiResource = response.data

        this.replace(item)

        return item
    }

    protected async fetchAll(uri: string): Promise<void> {
        await this.fetchPage(uri)
    }

    protected async fetchOne(uri: string): Promise<void> {
        const response = await this.api.authenticated().get(uri)
        const data = response.data as IApiResource

        if (this.exists(uri)) {
            this.replace(data)
        } else {
            this.append(data)
        }
    }

    protected async fetchPage(uri: string): Promise<void> {
        const response = await this.api
            .authenticated()
            .get(`${uri}?itemsPerPage=${this.itemsPerPage}`)

        const data = response.data as IHydraCollection
        const items: Array<IApiResource> = data[
            'hydra:member'
        ] as Array<IApiResource>

        this.appendItems(items)

        if (data?.['hydra:view']?.['hydra:next']) {
            await this.fetchPage(data['hydra:view']['hydra:next'])
        }
    }
}
