var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Geen toegang"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('b-card', {
    staticClass: "text-center"
  }, [_c('b-card-img', {
    staticClass: "w-100",
    staticStyle: {
      "max-width": "800px"
    },
    attrs: {
      "src": require("./../../assets/errors/403.png")
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }