


















import TypedVue from '@/config/vue'

interface IData {
    selectedFile: File | undefined
}

export default TypedVue.extend({
    name: 'MediaObjectUploadModal',

    data(): IData {
        return {
            selectedFile: undefined,
        }
    },

    methods: {
        uploadFile(): void {
            // dummy
        },
    },
})
