












































































































import axios, { AxiosError } from 'axios'

import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import ProductionProductItem from '@/components/Resource/ProductionProductItem.vue'
import {
    deleteConfirmOptions,
    persistenceErrorOptions,
} from '@/constants/messages'
import HttpStatusCodes from '@/enums/HttpStatusCodes'
import ProductionPackageItemPersistence from '@/persistence/ProductionPackageItemPersistence'
import ProductionPackagePersistence from '@/persistence/ProductionPackagePersistence'
import TypedVue from '@/config/vue'
import {
    IProductionPackage,
    IProductionPackageItem,
    IProductionProduct,
} from '@/types/api/Interfaces'

interface IData {
    selectedProduct?: IProductionProduct
    editName: boolean
}

export default TypedVue.extend({
    name: 'ProductionPackageItem',
    components: { ProductionProductItem, MaterialBlueprint },

    props: {
        packageIri: {
            type: String,
            required: true,
        },
    },

    data(): IData {
        return {
            editName: false,
            selectedProduct: undefined,
        }
    },

    methods: {
        async removeProduct(product: IProductionPackageItem): Promise<void> {
            await ProductionPackageItemPersistence.remove(product['@id'])
        },

        showProductModal(): void {
            if (this.productionPackage !== undefined)
                this.$bvModal.show(
                    `productModal${this.productionPackage['@id']}`
                )
        },

        async addProduct(): Promise<void> {
            if (this.selectedProduct && this.productionPackage) {
                await ProductionPackageItemPersistence.create({
                    relationProductionProduct: this.selectedProduct['@id'],
                    relationProductionPackage: this.productionPackage['@id'],
                })
            }
        },

        async deletePackage(): Promise<void> {
            if (this.productionPackage === undefined) {
                return
            }

            const result = await this.$bvModal.msgBoxConfirm(
                `Weet je zeker dat je "${this.productionPackage.name}" wilt verwijderen?`,
                deleteConfirmOptions
            )

            if (result) {
                try {
                    await ProductionPackagePersistence.remove(
                        this.productionPackage['@id']
                    )
                } catch (error) {
                    if (!axios.isAxiosError(error)) {
                        throw error
                    }

                    await this.handleAxiosError(error)
                }
            }
        },

        async handleAxiosError(error: AxiosError): Promise<void> {
            if (error.response === undefined) {
                throw error
            }

            if (error.response.status === HttpStatusCodes.BadRequest) {
                await this.$bvModal.msgBoxOk(
                    'Dit pakket kan niet verwijderd worden',
                    persistenceErrorOptions
                )
            }
        },
    },

    computed: {
        packageName: {
            get(): string | undefined {
                return this.productionPackage?.name
            },

            async set(newValue: string): Promise<void> {
                if (this.productionPackage === undefined) {
                    return
                }

                if (newValue.trim().length === 0) {
                    this.$bvToast.toast('Naam mag niet leeg zijn', {
                        title: 'Pakket opslaan',
                        variant: 'danger',
                    })

                    return
                }

                await ProductionPackagePersistence.update(
                    this.productionPackage['@id'],
                    { name: newValue }
                )
            },
        },

        productionPackage(): IProductionPackage | undefined {
            return this.$store.state.productionPackages.items.find(
                (productionPackage): boolean =>
                    this.packageIri === productionPackage['@id']
            )
        },

        productionProducts(): Array<IProductionProduct> {
            return this.$store.state.productionProducts.items
        },

        productionPackageItems(): Array<IProductionPackageItem> {
            return this.$store.state.productionPackageItems.items.filter(
                (item: IProductionPackageItem): boolean =>
                    item.relationProductionPackage ===
                    this.productionPackage?.['@id']
            )
        },
    },
})
