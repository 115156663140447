




























































import Vue from 'vue'

import Heading from '@/components/ui/Heading.vue'
import PositionConfigurator from '@/components/ui/PositionConfigurator.vue'
import StoreTypePersistence from '@/persistence/StoreTypePersistence'
import { IStoreType } from '@/types/api/Interfaces'

interface IData {
    editing: boolean
}

export default Vue.extend({
    name: 'StoreTypeItem',

    components: {
        PositionConfigurator,
        Heading,
    },

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },

    data(): IData {
        return {
            editing: false,
        }
    },

    computed: {
        item(): IStoreType | undefined {
            return this.$store.state.storeTypes.items.find(
                (item: IStoreType): boolean =>
                    item.id.toString() === this.id.toString()
            )
        },

        types(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },
    },

    async mounted(): Promise<void> {
        await StoreTypePersistence.loadAll()
    },

    methods: {
        async saveStoreTypeName(): Promise<void> {
            if (this.item) {
                await StoreTypePersistence.update(this.item['@id'], {
                    name: this.item.name,
                })
            }

            this.editing = false
        },
    },
})
