var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Bestanden"
    }
  }), _c('b-container', {
    staticClass: "my-5"
  }, [_c('div', {
    staticClass: "files"
  }, [_c('b-row', _vm._l(_vm.mediaObjects, function (mediaObject) {
    return _c('b-col', {
      key: mediaObject['@id'],
      attrs: {
        "cols": "6",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('FilePreview', {
      staticClass: "mb-3",
      attrs: {
        "media-object": mediaObject,
        "editable": ""
      },
      on: {
        "edit": function edit($event) {
          return _vm.edit(mediaObject);
        },
        "delete": function _delete($event) {
          return _vm.remove(mediaObject);
        },
        "preview": function preview($event) {
          return _vm.showImage(mediaObject);
        }
      }
    })], 1);
  }), 1), _vm.mediaObjects.length === 0 ? _c('b-card', {
    staticClass: "mb-3"
  }, [_vm._v(" Geen bestanden gevonden ")]) : _vm._e()], 1)]), _c('MediaObjectGallery', {
    ref: "gallery"
  }), _c('UpdateFileModal', {
    attrs: {
      "file": _vm.editObject
    },
    on: {
      "update": _vm.update
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }