import { render, staticRenderFns } from "./TemplateEdit.vue?vue&type=template&id=aea24cec&scoped=true&"
import script from "./TemplateEdit.vue?vue&type=script&lang=ts&"
export * from "./TemplateEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aea24cec",
  null
  
)

export default component.exports