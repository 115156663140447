import { render, staticRenderFns } from "./DnaItem.vue?vue&type=template&id=d0411514&scoped=true&"
import script from "./DnaItem.vue?vue&type=script&lang=ts&"
export * from "./DnaItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0411514",
  null
  
)

export default component.exports