import {
    faFileAlt,
    faFileInvoiceDollar,
    faUserTie,
    faUserLock,
    faMegaphone,
    faBox,
    faFile,
    faStore,
    faTag,
} from '@fortawesome/pro-duotone-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
    faFileAlt,
    faFileInvoiceDollar,
    faUserTie,
    faUserLock,
    faMegaphone,
    faBox,
    faFile,
    faStore,
    faTag
)
