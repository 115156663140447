import { render, staticRenderFns } from "./DnaPositionItem.vue?vue&type=template&id=a4e36066&scoped=true&"
import script from "./DnaPositionItem.vue?vue&type=script&lang=ts&"
export * from "./DnaPositionItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4e36066",
  null
  
)

export default component.exports