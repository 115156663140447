






















import QuantitySelector from '@/components/ui/QuantitySelector.vue'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'CartItem',
    components: { QuantitySelector },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },
})
