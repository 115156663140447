var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "status-badge",
    class: _vm.bg()
  }, [_vm.label ? _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.value))]), _vm.unit ? _c('div', {
    staticClass: "unit"
  }, [_vm._v(_vm._s(_vm.unit))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }