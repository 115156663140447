var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'side-navigation' + (_vm.parentClosed ? ' closed' : '') + (_vm.parentCollapsed ? ' collapsed' : '')
  }, [_vm._m(0), _c('main', [_vm._m(1), _c('b-nav', {
    attrs: {
      "vertical": ""
    }
  }, [_vm._l(_vm.parentItems, function (item, index) {
    return [!item.children ? _c('b-nav-item', {
      key: index,
      attrs: {
        "to": item.to
      },
      on: {
        "click": _vm.parentClose
      }
    }, [item.icon ? _c('font-awesome-icon', {
      attrs: {
        "fixed-width": "",
        "size": "lg",
        "icon": [_vm.parentIconStyle, item.icon]
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(item.text))])], 1) : _c('b-nav-item-dropdown', {
      key: index,
      attrs: {
        "boundary": "window",
        "dropright": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [item.icon ? _c('font-awesome-icon', {
            attrs: {
              "fixed-width": "",
              "size": "lg",
              "icon": [_vm.parentIconStyle, item.icon]
            }
          }) : _vm._e(), _c('span', [_vm._v(_vm._s(item.text))])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(_vm.accessibleItems(item.children), function (subItem, subIndex) {
      return _c('b-dropdown-item', {
        key: subIndex,
        attrs: {
          "to": subItem.to
        },
        on: {
          "click": _vm.parentClose
        }
      }, [subItem.icon ? _c('font-awesome-icon', {
        attrs: {
          "fixed-width": "",
          "icon": [_vm.parentIconStyle, subItem.icon]
        }
      }) : _vm._e(), _c('span', [_vm._v(_vm._s(subItem.text))])], 1);
    }), 1)];
  })], 2)], 1), _c('footer', [_c('b-nav', {
    attrs: {
      "vertical": ""
    }
  }, [_c('b-nav-item', {
    staticClass: "nav-item-collapse",
    on: {
      "click": _vm.parentCollapse
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": [_vm.parentIconStyle, 'arrow-circle-left'],
      "fixed-width": "",
      "size": "lg"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.parentClosed ? 'Inklappen' : 'Uitklappen'))])], 1)], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('strong', [_vm._v("Navigatie")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/logo.webp"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }