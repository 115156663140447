var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swatches"
  }, _vm._l(_vm.swatches, function (swatch, index) {
    return _c('Swatch', {
      key: index,
      attrs: {
        "name": swatch.name,
        "space": swatch.space,
        "selected": _vm.selected === index,
        "value": swatch.value
      },
      on: {
        "select": function select($event) {
          _vm.selected = _vm.selected === index ? undefined : index;
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }