var render = function () {
  var _vm$item;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.name
    },
    scopedSlots: _vm._u([{
      key: "breadcrumbs",
      fn: function fn() {
        return [_c('b-breadcrumb-item', {
          attrs: {
            "to": {
              name: 'Orders'
            }
          }
        }, [_vm._v(" Orders ")])];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_vm.item ? _c('CampaignDetailItem', {
    attrs: {
      "campaign": _vm.item
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }