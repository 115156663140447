import { GetterTree } from 'vuex'

import { ISize } from '@/types/api/Interfaces'
import IState from '@/store/modules/positionSizes/IState'
import IRootState from '@/store/IRootState'

const getters: GetterTree<IState, IRootState> = {
    getSizeByUri:
        (state) =>
        (uri: string): ISize | undefined =>
            state.items.find((item): boolean => item['@id'] === uri),
}

export default getters
