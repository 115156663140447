



























































































import Vue from 'vue'

import PositionSizesPersistence from '@/persistence/PositionSizesPersistence'
import { ISize } from '@/types/api/Interfaces'

interface IData {
    editWidth: boolean
    editHeight: boolean
    editName: boolean
    editAll: boolean
    name: string
    width: number
    height: number
}

export default Vue.extend({
    name: 'SizeItem',

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data(): IData {
        return {
            editWidth: false,
            editHeight: false,
            editName: false,
            editAll: false,
            name: '',
            width: 0,
            height: 0,
        }
    },

    computed: {
        storeItem(): ISize {
            return this.$store.state.positionSizes.items.find(
                (item: ISize): boolean =>
                    item['@id'].toString() === this.id.toString()
            )
        },
    },

    watch: {
        storeItem(newValue: ISize): void {
            this.name = newValue.name
            this.height = newValue.height
            this.width = newValue.width
        },
    },

    mounted(): void {
        this.name = this.storeItem.name
        this.height = this.storeItem.height
        this.width = this.storeItem.width
    },

    methods: {
        async remove(): Promise<void> {
            await PositionSizesPersistence.remove(this.storeItem['@id'])
        },

        async saveSizeName(): Promise<void> {
            if (this.name === '') return

            this.editName = false
            await PositionSizesPersistence.update(this.storeItem['@id'], {
                name: this.name,
            })
        },

        async saveSizeWidth(): Promise<void> {
            if (this.width === 0) return

            this.editWidth = false
            await PositionSizesPersistence.update(this.storeItem['@id'], {
                width: Number.parseInt(this.width.toString(), 10),
            })
        },

        async saveSizeHeight(): Promise<void> {
            if (this.height === 0) return

            this.editHeight = false
            await PositionSizesPersistence.update(this.storeItem['@id'], {
                height: Number.parseInt(this.height.toString(), 10),
            })
        },

        async saveAll(): Promise<void> {
            this.editAll = false
            await PositionSizesPersistence.update(this.storeItem['@id'], {
                name: this.name,
                height: Number.parseInt(this.height.toString(), 10),
                width: Number.parseInt(this.width.toString(), 10),
            })
        },
    },
})
