var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('b-navbar-nav', [_c('b-nav-item', {
    staticClass: "cart-item",
    attrs: {
      "to": "/cart"
    }
  }, [_c('font-awesome-layers', {
    staticClass: "fa-lg",
    attrs: {
      "full-width": ""
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'shopping-bag']
    }
  }), _c('font-awesome-layers-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.quantity,
      expression: "quantity"
    }],
    staticClass: "counter",
    attrs: {
      "counter": "",
      "value": _vm.quantity
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }