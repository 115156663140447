












































































import { PropType } from 'vue'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import OrderProgress from '@/components/ui/OrderProgress.vue'
import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import CampaignDates from '@/components/ui/CampaignDates.vue'
import ArtworkProgress from '@/components/ui/ArtworkProgress.vue'
import { ICampaign } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import orderStatus, { IOrderStatus } from '@/constants/order-status'
// eslint-disable-next-line import/max-dependencies -- mag van Alex
import { CampaignStatus } from '@/enums/CampaignStatus'

dayjs.extend(weekOfYear)

interface IData {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- enum
    CampaignStatus: typeof CampaignStatus
}

export default TypedVue.extend({
    name: 'CampaignListItem',

    data(): IData {
        return {
            CampaignStatus,
        }
    },

    components: {
        ArtworkProgress,
        CampaignDates,
        MaterialBlueprint,
        OrderProgress,
    },

    props: {
        campaign: {
            type: Object as PropType<ICampaign>,
            required: true,
        },
    },

    methods: {
        dayjs,
    },

    computed: {
        statusObject(): IOrderStatus | undefined {
            return orderStatus.find(
                (item): boolean => item.status === this.campaign.status
            )
        },

        canDelete(): boolean {
            return this.campaign.status === CampaignStatus.Concept
        },
    },
})
