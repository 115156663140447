















































import Vue, { PropType } from 'vue'

import TagPersistence from '@/persistence/TagPersistence'
import { IStore, IStoreType, ITag } from '@/types/api/Interfaces'

export default Vue.extend({
    name: 'BranchItem',

    props: {
        item: {
            type: Object as PropType<IStore>,
            required: true,
        },
    },

    computed: {
        storeTags(): Array<ITag> {
            return this.$store.state.tags.items.filter((tag: ITag): boolean =>
                this.item.tags.includes(tag['@id'])
            )
        },

        storeType(): IStoreType | undefined {
            if (this.item.storeType) {
                return this.$store.getters['storeTypes/get'](
                    this.item.storeType
                )
            }

            return undefined
        },
    },

    async mounted(): Promise<void> {
        await TagPersistence.loadAll()
    },

    methods: {
        initials(): string {
            const length = 2

            return this.item
                ? this.item.name
                      .split(' ')
                      .map((item: string): string => item[0])
                      .join('')
                      .slice(0, length)
                : ''
        },
    },
})
