

















import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'UserNavigation',

    computed: {
        user(): string {
            return this.$store.state.user.token
        },
    },

    methods: {
        logout(): void {
            void this.$store.dispatch('user/clear')
            void this.$router.push('/login')
        },
    },
})
