

















































































import Heading from '@/components/ui/Heading.vue'
import TemplateItem from '@/components/Resource/TemplateItem.vue'
import TypedVue from '@/config/vue'

interface IData {
    name?: string
    tag: object
    media: object
    type: object
}

export default TypedVue.extend({
    name: 'TemplateList',
    components: { TemplateItem, Heading },

    data(): IData {
        return {
            name: undefined,
            tag: {},
            media: {},
            type: {},
        }
    },
})
