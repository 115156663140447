var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [_vm._v(_vm._s(_vm.department.name))]), _c('div', [_c('b-btn', {
          staticClass: "ml-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'pencil'],
            "fixed-width": ""
          }
        })], 1), _c('b-btn', {
          staticClass: "ml-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'trash'],
            "fixed-width": ""
          }
        })], 1)], 1)])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "float-right"
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus'],
            "fixed-width": ""
          }
        }), _vm._v(" New Position ")], 1)];
      },
      proxy: true
    }])
  }, [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, _vm._l(_vm.department.segments, function (position) {
    return _c('DnaPositionItem', {
      key: position.id,
      attrs: {
        "dna-position": position
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }