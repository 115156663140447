var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "createSize",
      "title": "Maak nieuwe afmeting",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": _vm.create
    }
  }, [_c('b-form', {
    ref: "sizeForm",
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submit();
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Width"
    }
  }, [_c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('b-input-group-text', [_vm._v("mm")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    model: {
      value: _vm.width,
      callback: function callback($$v) {
        _vm.width = $$v;
      },
      expression: "width"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Height"
    }
  }, [_c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('b-input-group-text', [_vm._v("mm")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    model: {
      value: _vm.height,
      callback: function callback($$v) {
        _vm.height = $$v;
      },
      expression: "height"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }