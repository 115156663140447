var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-badge', {
    staticClass: "d-flex w-100 justify-content-stretch align-items-center px-3 py-2",
    staticStyle: {
      "border-radius": "2px 2px 0 0",
      "overflow-x": "auto",
      "overflow-y": "hidden"
    },
    attrs: {
      "variant": _vm.statusObject !== undefined ? _vm.statusObject.type : null
    }
  }, _vm._l(_vm.statusList, function (status, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "rounded m-0 p-2",
      style: {
        opacity: status.status !== _vm.currentStatus ? 0.5 : 1,
        background: status.status === _vm.currentStatus ? index === 0 ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.3)' : ''
      }
    }, [_vm._v(" " + _vm._s(status.label) + " ")]), Number(index + 1) < _vm.statusList.length ? _c('strong', {
      staticClass: "flex-grow-1 text-center ml-2 mr-2",
      style: {
        opacity: Number(index + 1) > _vm.statusIndex ? 0.5 : 1
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": ['far', 'angle-right']
      }
    })], 1) : _vm._e()]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }