var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "label": "Geselecteerde vestigingen"
    }
  }, [_c('div', [_c('h5', _vm._l(_vm.tags, function (tag) {
    return _c('b-badge', {
      key: tag['@id'],
      staticClass: "mr-1 align-items-center",
      on: {
        "click": function click($event) {
          return _vm.removeTag(tag);
        }
      }
    }, [_vm.editable ? _c('font-awesome-icon', {
      staticClass: "mr-1",
      attrs: {
        "icon": ['far', 'times']
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(tag.title) + " "), _c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": "light"
      }
    }, [_vm._v(" " + _vm._s(_vm.storesWithTag(tag).length) + " ")])], 1);
  }), 1)]), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "size": "sm",
      "disabled": !_vm.editable
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('addTag');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'plus']
    }
  })], 1), _c('b-modal', {
    attrs: {
      "id": "addTag",
      "title": "Tag toevoegen",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light",
      "centered": ""
    },
    on: {
      "ok": function ok($event) {
        return _vm.addTag();
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "selecteer bestaande tag"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.state.tags.items.map(function (tag) {
        return {
          value: tag['@id'],
          text: tag.title
        };
      }),
      "value": ""
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v(" -- Selecteer een tag AUB -- ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedTag,
      callback: function callback($$v) {
        _vm.selectedTag = $$v;
      },
      expression: "selectedTag"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "disabled": _vm.selectedTag !== '',
      "label": "Maak een nieuwe tag"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    model: {
      value: _vm.newTag,
      callback: function callback($$v) {
        _vm.newTag = $$v;
      },
      expression: "newTag"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }