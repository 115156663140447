import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

dayjs.tz.setDefault('Europe/Amsterdam')
dayjs.locale('nl')

export default dayjs
