








import Vue from 'vue'

export default Vue.extend({
    name: 'StatusBadge',

    props: {
        label: {
            type: String,
            required: false,
            default: undefined,
        },

        value: {
            type: [String, Number],
            required: true,
        },

        unit: {
            type: String,
            required: false,
            default: undefined,
        },

        variant: {
            type: String,
            required: false,
            default: 'secondary',
        },
    },

    methods: {
        bg(): string {
            return `status-${this.variant}`
        },
    },
})
