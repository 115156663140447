
























import { PropType } from 'vue'

import TypedVue from '@/config/vue'
import { IPosition } from '@/types/api/Interfaces'

export default TypedVue.extend({
    name: 'DnaPositionItem',

    props: {
        dnaPosition: {
            type: Object as PropType<IPosition>,
            default: undefined,
        },
    },
})
