import { GetterTree } from 'vuex'

import { IStoreType } from '@/types/api/Interfaces'
import IState from '@/store/modules/storeTypes/IState'
import IRootState from '@/store/IRootState'

const getters: GetterTree<IState, IRootState> = {
    get:
        (state) =>
        (iri: string): IStoreType | undefined =>
            state.items.find((item): boolean => item['@id'] === iri),
}

export default getters
