import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faShoppingBag,
    faArrowCircleLeft,
    faHomeAlt,
    faMegaphone,
    faBox,
    faFile,
    faStore,
    faStoreAlt,
    faCog,
    faBars,
    faArrowToTop,
    faUser,
    faTag,
    faPlus,
    faTimes,
    faMinus,
    faFileAlt,
    faFileInvoiceDollar,
    faPrint,
    faScroll,
    faUserTie,
    faUserLock,
} from '@fortawesome/pro-light-svg-icons'

library.add(
    faShoppingBag,
    faArrowCircleLeft,
    faHomeAlt,
    faMegaphone,
    faBox,
    faFile,
    faTag,
    faStore,
    faStoreAlt,
    faCog,
    faBars,
    faArrowToTop,
    faUser,
    faPlus,
    faTimes,
    faMinus,
    faFileAlt,
    faFileInvoiceDollar,
    faPrint,
    faScroll,
    faUserTie,
    faUserLock
)
