







































































































































































































































































































































































































/* eslint-disable import/max-dependencies -- will probably forget to correct this */
/* eslint-disable max-lines -- will probably forget to correct this */
import lodash, { groupBy } from 'lodash'
// eslint-disable-next-line import/no-unresolved -- DE BUG
import { Dictionary } from 'vue-router/types/router'
import axios, { AxiosError } from 'axios'

import Heading from '@/components/ui/Heading.vue'
import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import QuantitySelector from '@/components/ui/QuantitySelector.vue'
import CampaignReport from '@/screens/Relation/CampaignReport.vue'
import OrderProgress from '@/components/ui/OrderProgress.vue'
import FilePreview from '@/components/Resource/FilePreview.vue'
import MediaObjectBrowser from '@/components/ui/MediaObjectBrowser.vue'
import CampaignItemPackage from '@/components/Resource/CampaignItemPackage.vue'
import CampaignPackageStoreType from '@/components/Resource/CampaignPackageStoreType.vue'
import MediaObjectUploadModal from '@/components/ui/MediaObjectUploadModal.vue'
import IDatePickerFormatter from '@/types/IDatePickerFormatter'
import dayjs from '@/config/dayjs'
import TagPersistence from '@/persistence/TagPersistence'
import HttpStatusCodes from '@/enums/HttpStatusCodes'
import { persistenceErrorOptions } from '@/constants/messages'
import { IMediaObjectGalleryItem } from '@/types/IMediaObjectGallery'
import CampaignItemPersistence from '@/persistence/CampaignItemPersistence'
import CampaignPackageStoreTypePersistence from '@/persistence/CampaignPackageStoreTypePersistence'
import ProductionPackagePersistence from '@/persistence/ProductionPackagePersistence'
import CampaignPackagePersistence from '@/persistence/CampaignPackagePersistence'
import IPackage from '@/types/IPackage'
import PositionSizesPersistence from '@/persistence/PositionSizesPersistence'
import StoreTypePersistence from '@/persistence/StoreTypePersistence'
import PositionPersistence from '@/persistence/PositionPersistence'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import {
    IDepartment,
    IPosition,
    ISegment,
    IStoreType,
    ISize,
    IMediaObject,
    IProductionPackage,
    ICampaignPackage,
    ICampaignItem,
    ICampaign,
    ICampaignPackageStoreType,
    IProductionProduct,
} from '@/types/api/Interfaces'
import StoreDepartmentPersistence from '@/persistence/StoreDepartmentPersistence'
import MediaObjectPersistence from '@/persistence/MediaObjectPersistence'
import TypedVue from '@/config/vue'
import SegmentPersistence from '@/persistence/SegmentPersistence'
import Products from '@/constants/products'
import OrderStatusDropdown from '@/components/ui/OrderStatusDropdown.vue'
import MediaObjectGallery from '@/components/ui/MediaObjectGallery.vue'
import LoadingCard from '@/components/ui/LoadingCard.vue'
import CampaignTags from '@/components/Resource/CampaignTags.vue'
import ArtworkProgress from '@/components/ui/ArtworkProgress.vue'
import { CampaignStatus } from '@/enums/CampaignStatus'
import CampaignStatusTable from '@/components/ui/CampaignStatusTable.vue'

interface ICampaignPackageCollection {
    campaignPackageIri: string
    campaignPackageStoreTypes: Array<ICampaignPackageStoreType>
    campaignItems: Array<ICampaignItem>
}

interface IData {
    packageMoveExisting: boolean | undefined
    packageMovePackageIri: string | undefined
    packageMoveCampaignId: number | undefined
    packageMoveCampaignName: string | undefined
    filterArtwork: boolean
    loaded: boolean
    selectedCampaignItemIri: string | undefined
    newPackage: number | undefined
    newPackageName: string | undefined
    selectedFile?: File

    selectedUploadPosition?: IPosition
    selectedPackage?: IProductionPackage
    // campaignOrder: ICampaignOrder
    showReport: boolean
    pendingUploads: {
        [key: number]: {
            step: 'uploading' | 'pending'
            uploadProgress: number
        }
    }
    selectedProductId?: number
    availablePreviews: {
        [key: number]: string
    }
    dateFormatter: IDatePickerFormatter
    campaignPeriod: Array<Date | undefined>
}

interface IDimensions {
    name: string
    width: number
    height: number
}

export default TypedVue.extend({
    name: 'CampaignItem',

    components: {
        CampaignStatusTable,
        ArtworkProgress,
        CampaignTags,
        LoadingCard,
        MediaObjectGallery,
        OrderStatusDropdown,
        CampaignPackageStoreType,
        MediaObjectUploadModal,
        CampaignItemPackage,
        MediaObjectBrowser,
        FilePreview,
        OrderProgress,
        QuantitySelector,
        CampaignReport,
        MaterialBlueprint,
        Heading,
    },

    props: {
        id: {
            type: String,
            required: true,
        },
    },

    data(): IData {
        return {
            packageMoveExisting: undefined,
            packageMovePackageIri: undefined,
            packageMoveCampaignName: undefined,
            packageMoveCampaignId: undefined,
            filterArtwork: false,
            loaded: false,
            selectedCampaignItemIri: undefined,
            selectedFile: undefined,
            selectedUploadPosition: undefined,
            selectedPackage: undefined,
            newPackage: undefined,
            newPackageName: undefined,
            showReport: false,
            pendingUploads: {},
            selectedProductId: undefined,
            availablePreviews: {},

            dateFormatter: {
                // [optional] Date to String
                stringify: (date: Date): string =>
                    date ? dayjs(date).format('LL') : '',

                // [optional]  String to Date
                parse: (value: string): Date | undefined =>
                    value ? dayjs(value, 'LL').toDate() : undefined,
            },

            campaignPeriod: [],
        }
    },

    async mounted(): Promise<void> {
        this.loaded = false
        await this.loadFormData()

        this.$watch(
            'activeStoreTypes',
            (newStoreTypes, oldStoreTypes): void => {
                void (async (): Promise<void> => {
                    if (lodash.isEqual(newStoreTypes, oldStoreTypes)) {
                        return
                    }

                    const newStoreTypeIris = newStoreTypes.map(
                        (item: IStoreType): string => item['@id']
                    )

                    await CampaignPersistence.update(
                        `${CampaignPersistence.getEndpoint()}/${this.id}`,
                        {
                            selectedStoreTypes: newStoreTypeIris,
                        }
                    )
                })()
            }
        )

        this.$watch(
            'activeDepartments',
            (newDepartments, oldDepartments): void => {
                void (async (): Promise<void> => {
                    if (lodash.isEqual(newDepartments, oldDepartments)) {
                        return
                    }

                    const newDepartmentIris = newDepartments.map(
                        (item: IDepartment): string => item['@id']
                    )

                    await CampaignPersistence.update(
                        `${CampaignPersistence.getEndpoint()}/${this.id}`,
                        {
                            selectedStoreDepartments: newDepartmentIris,
                        }
                    )
                })()
            }
        )
        this.loaded = true
    },

    methods: {
        // eslint-disable-next-line max-statements -- shhh */
        async loadFormData(): Promise<void> {
            await StoreDepartmentPersistence.loadAll()
            await MediaObjectPersistence.loadAll()
            await ProductionPackagePersistence.loadAll()
            await SegmentPersistence.loadAll()
            await PositionPersistence.loadAll()
            await PositionSizesPersistence.loadAll()
            await CampaignPackagePersistence.loadAll()
            await StoreTypePersistence.loadAll()
            await CampaignPackageStoreTypePersistence.loadAll()
            await TagPersistence.loadAll()

            try {
                await CampaignPersistence.load(
                    `${CampaignPersistence.getEndpoint()}/${this.id}`
                )

                if (this.campaign?.startAt && this.campaign?.endAt) {
                    this.campaignPeriod = [
                        new Date(this.campaign?.startAt),
                        new Date(this.campaign?.endAt),
                    ]
                }
            } catch {
                await this.$router.replace({
                    name: 'PageNotFound',
                })
            }

            if (this.campaign !== undefined) {
                await Promise.all(
                    this.campaign.items.map(
                        async (item: string): Promise<void> => {
                            await CampaignItemPersistence.load(item)
                        }
                    )
                )
            }
        },

        async updateCampaignPeriod(
            newValue: Array<Date | undefined>
        ): Promise<void> {
            if (this.campaign === undefined) {
                return
            }

            await CampaignPersistence.update(
                `${CampaignPersistence.getEndpoint()}/${this.id}`,
                { startAt: newValue[0], endAt: newValue[1] }
            )
        },

        async setCampaignItemMediaObject(
            mediaObject: string | undefined
        ): Promise<void> {
            if (this.selectedCampaignItemIri === undefined) {
                return
            }

            await CampaignItemPersistence.update(this.selectedCampaignItemIri, {
                mediaObject,
            })
        },

        showMove(iri: string): void {
            this.packageMovePackageIri = iri
            this.$bvModal.show('package-move-modal')
        },

        showPackageModal(): void {
            this.$bvModal.show('packageModal')
        },

        showPreview(item: IMediaObjectGalleryItem): void {
            // @ts-expect-error -- ref
            this.$refs.gallery.open([item])
        },

        packageCount(packageItem: IPackage): number {
            return packageItem.orderEntries
                .map((item): number => item.amount)
                .reduce((partialSum, a): number => partialSum + a, 0)
        },

        campaignPackageCount(campaignPackageIri: string): number {
            let count = 0

            this.$store.state.campaignPackageStoreTypes.items.forEach(
                (item: ICampaignPackageStoreType): void => {
                    if (item.campaignPackage === campaignPackageIri) {
                        count += item.quantity
                    }
                }
            )

            return count
        },

        // eslint-disable-next-line max-statements,sonarjs/cognitive-complexity,complexity -- bla
        async movePackage(event: Event): Promise<void> {
            if (
                this.packageMovePackageIri === undefined ||
                this.packageMoveExisting === undefined ||
                (this.packageMoveExisting &&
                    this.packageMoveCampaignId === undefined) ||
                (!this.packageMoveExisting &&
                    (this.packageMoveCampaignName === undefined ||
                        this.packageMoveCampaignName.trim().length === 0))
            ) {
                event.preventDefault()

                return
            }

            let campaignId = this.packageMoveCampaignId

            if (!this.packageMoveExisting) {
                const newCampaign: ICampaign =
                    (await CampaignPersistence.create({
                        name: this.packageMoveCampaignName,
                    })) as ICampaign

                campaignId = newCampaign.id
            }

            if (campaignId === undefined) {
                this.clearMovePackage()

                return
            }

            try {
                await CampaignPackagePersistence.move(
                    this.packageMovePackageIri,
                    campaignId
                )
                this.clearMovePackage()
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                const { response } = error

                await (response.status === HttpStatusCodes.BadRequest ||
                response.status === HttpStatusCodes.UnprocessableEntity
                    ? this.$bvModal.msgBoxOk(
                          'Het pakket kon niet verplaatst worden',
                          persistenceErrorOptions
                      )
                    : this.$bvModal.msgBoxOk(
                          'Er is iets misgegaan',
                          persistenceErrorOptions
                      ))
                event.preventDefault()
            }
        },

        clearMovePackage(): void {
            this.packageMoveCampaignName = undefined
            this.packageMoveCampaignId = undefined
            this.packageMovePackageIri = undefined
            this.packageMoveExisting = undefined
        },

        resetPackageModal(): void {
            this.$bvModal.hide('packageModal')
            this.newPackageName = undefined
            this.newPackage = undefined
        },

        async addPackage(event: Event): Promise<void> {
            if (!this.validateNewPackage(event)) {
                return
            }

            try {
                await CampaignPackagePersistence.create({
                    campaign: `${CampaignPersistence.getEndpoint()}/${this.id}`,
                    name: this.newPackageName,
                    relationProductionPackage: this.newPackage,
                })
                this.resetPackageModal()
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                if (
                    error.response.status === HttpStatusCodes.BadRequest ||
                    error.response.status ===
                        HttpStatusCodes.UnprocessableEntity
                ) {
                    await this.$bvModal.msgBoxOk(
                        'Dit pakket kan niet toegevoegd worden.',
                        persistenceErrorOptions
                    )
                }
            }

            await this.loadFormData()
        },

        validateNewPackage(event: Event): boolean {
            if (
                this.newPackageName === undefined ||
                this.newPackageName.trim().length === 0
            ) {
                this.displayError('Naam mag niet leeg zijn', 'Pakket aanmaken')
                event.preventDefault()

                return false
            }

            if (this.newPackage === undefined) {
                this.displayError(
                    'selecteer een pakketindeling',
                    'Pakket aanmaken'
                )
                event.preventDefault()

                return false
            }

            return true
        },

        prepareUpload(productId: number): void {
            if (this.pendingUploads[productId]) {
                return
            }

            this.selectedProductId = productId
            this.$bvModal.show('uploadModal')
        },

        showBrowse(iri: string): void {
            this.selectedCampaignItemIri = iri
            this.$bvModal.show('media-object-browser-modal')
        },

        showUpload(iri: string): void {
            this.selectedCampaignItemIri = iri
            this.$bvModal.show('media-object-upload-modal')
        },

        displayError(
            message: string,
            title: string,
            shouldThrow: boolean = false
        ): void {
            this.$bvToast.toast(message, {
                title,
                variant: 'danger',
            })

            if (shouldThrow) {
                throw new Error(`An unexpected error occurred: ${message}`)
            }
        },

        verifyBeforeUpload(): boolean {
            if (this.selectedFile === undefined) {
                this.displayError(
                    'Er is geen bestand gekozen',
                    'Geen bestand gekozen',
                    true
                )
            }

            if (this.selectedProductId === undefined) {
                this.displayError(
                    'Er is geen product geselecteerd',
                    'Geen product geselecteerd',
                    true
                )
            }

            return true
        },

        async reset(iri: string): Promise<void> {
            await CampaignItemPersistence.update(iri, {
                mediaObject: null,
            })
        },

        // eslint-disable-next-line consistent-return -- needs to be fixed
        async upload(file: File): Promise<IMediaObject | void> {
            try {
                const mediaObject = await MediaObjectPersistence.upload(
                    file,
                    (): void => {
                        // dummy
                    }
                )

                await this.attachPendingMediaObject(mediaObject)

                return mediaObject
            } catch (error) {
                if (!axios.isAxiosError(error)) {
                    throw error
                }

                const axiosError = error as AxiosError<any>

                if (
                    axiosError.response?.data['hydra:description'].includes(
                        'hash'
                    )
                ) {
                    await this.duplicateHandeling(axiosError)
                } else {
                    this.displayUploadError(error)
                }
            }
        },

        async duplicateHandeling(error: AxiosError): Promise<void> {
            const mediaObject = this.findDuplicateMediaObject(error)

            const productIri = this.$store.state.campaignItems.items.find(
                (campaignItem): boolean =>
                    campaignItem['@id'] === this.selectedCampaignItemIri
            )?.relationProductionProduct

            const product = this.$store.state.productionProducts.items.find(
                (productionProduct): boolean =>
                    productionProduct['@id'] === productIri
            )

            if (!this.checkDimensions(mediaObject, product)) {
                return
            }

            if (
                mediaObject !== undefined &&
                this.selectedCampaignItemIri !== undefined
            ) {
                await CampaignItemPersistence.update(
                    this.selectedCampaignItemIri,
                    { mediaObject: mediaObject['@id'] }
                )

                this.$bvToast.toast('Bestaande bestand is gebruikt', {
                    title: 'Dit bestand bestaat al',
                    variant: 'info',
                })
            }
        },

        async attachPendingMediaObject(
            mediaObject: IMediaObject
        ): Promise<void> {
            this.$store.commit('mediaObjectWatchers/add', mediaObject['@id'])

            if (this.selectedCampaignItemIri !== undefined) {
                await CampaignItemPersistence.update(
                    this.selectedCampaignItemIri,
                    {
                        pendingMediaObject: mediaObject['@id'],
                    }
                )
            }
        },

        checkDimensions(
            mediaObject: IMediaObject | undefined,
            productionProduct: IProductionProduct | undefined
        ): boolean {
            if (
                mediaObject?.heightMm !== productionProduct?.height ||
                mediaObject?.widthMm !== productionProduct?.width
            ) {
                this.displayError(
                    'Dit bestand heeft niet de juist afmetingen',
                    'Verkeerde afmetingen'
                )

                return false
            }

            return true
        },

        findDuplicateMediaObject(
            error: AxiosError<any>
        ): IMediaObject | undefined {
            if (error.response === undefined) {
                return
            }

            const duplicateHash = error.response.data[
                'hydra:description'
            ].slice(
                error.response.data['hydra:description'].indexOf('"') + 1,
                -1
            )

            // eslint-disable-next-line consistent-return -- needs to be fixed
            return this.$store.state.mediaObjects.items.find(
                (mediaObject): boolean =>
                    mediaObject.hash === duplicateHash.trim()
            )
        },

        displayUploadError(error: any): void {
            if (!error.response) {
                this.displayError(
                    'Er heeft zich een netwerkfout voorgedaan.',
                    'Netwerkfout.'
                )
            } else if (
                error.response.data?.['hydra:description'] ===
                'Incompatible file type.'
            ) {
                this.displayError(
                    'Dit bestandstype wordt niet ondersteund.',
                    'Bestandstype niet ondersteund.'
                )
            } else if (
                error.response.data?.['hydra:description']?.includes(
                    'exceeds your upload_max_filesize ini directive'
                )
            ) {
                this.displayError(
                    'Dit bestand is te groot.',
                    'Bestand te groot.'
                )
            } else {
                this.displayError(
                    'Er heeft zich een onbekende fout voorgedaan.',
                    'Onbekende fout.'
                )
            }
        },

        positionSize(uri: string): IDimensions {
            const size = this.$store.state.positionSizes.items.find(
                (item: ISize): boolean => item['@id'] === uri
            )

            if (size) {
                return size
            }

            return {
                name: 'Unknown',
                width: 1,
                height: 1,
            }
        },

        disabledCloseUploadAtDates(date: Date): boolean {
            if (this.campaign === undefined) {
                return true
            }

            const newDate = new Date(date)
            const week = 7

            newDate.setDate(newDate.getDate() + week)

            return (
                (this.campaign.startAt !== undefined &&
                    newDate > new Date(this.campaign.startAt)) ||
                (this.campaign.closeAcceptAt !== undefined &&
                    newDate > new Date(this.campaign.closeAcceptAt))
            )
        },

        disabledCloseAcceptAtDates(date: Date): boolean {
            if (this.campaign === undefined) {
                return true
            }

            const newDate = new Date(date)
            const week = 7

            newDate.setDate(newDate.getDate() + week)

            return (
                (this.campaign.startAt !== undefined &&
                    newDate > new Date(this.campaign.startAt)) ||
                (this.campaign.closeUploadAt !== undefined &&
                    newDate < new Date(this.campaign.closeUploadAt))
            )
        },

        disabledStartDates(date: Date): boolean {
            if (this.campaign === undefined) {
                return true
            }

            const newDate = new Date(date)
            const week = 7

            newDate.setDate(newDate.getDate() + week)

            return (
                this.campaign.endAt !== undefined &&
                newDate > new Date(this.campaign.endAt)
            )
        },

        segmentsByDepartment(uri: string): Array<ISegment> {
            return this.$store.state.storeSegments.items.filter(
                (item: ISegment): boolean =>
                    item.department === uri &&
                    this.activeStoreTypes.some(
                        (storeType: IStoreType): boolean =>
                            storeType.segments.includes(item['@id'])
                    )
            )
        },

        positionsBySegment(uri: string): Array<IPosition> {
            return this.$store.state.positions.items.filter(
                (item: IPosition): boolean => item.segment === uri
            )
        },

        async saveCampaign(): Promise<void> {
            if (this.campaign === undefined) {
                return
            }

            await CampaignPersistence.update(
                `${CampaignPersistence.getEndpoint()}/${this.id}`,
                this.campaign
            )
        },

        async saveCampaignAndReturn(): Promise<void> {
            await this.saveCampaign()
            await this.$router.push('/campaigns')
        },

        getCampaignPackageStoreTypes(
            campaignPackageIri: string
        ): Array<ICampaignPackageStoreType> {
            if (
                this.$store.state.campaignPackageStoreTypes.items === undefined
            ) {
                return []
            }

            return this.$store.state.campaignPackageStoreTypes.items.filter(
                (item): boolean => item.campaignPackage === campaignPackageIri
            )
        },
    },

    computed: {
        availableCampaigns(): Array<ICampaign> {
            return this.$store.state.campaigns.items
                .filter(
                    (item: ICampaign): boolean =>
                        item.status === CampaignStatus.Concept
                )
                .slice()
                .sort((previous: ICampaign, next: ICampaign): number => {
                    const previousValue = new Date(
                        previous.updatedAt ?? previous.createdAt
                    )
                    const nextValue = new Date(next.updatedAt ?? next.createdAt)

                    return nextValue.getTime() - previousValue.getTime()
                })
        },

        campaignIsEditable(): boolean {
            return this.campaign?.status === CampaignStatus.Concept
        },

        isArtworkMutable(): boolean {
            return (
                this.campaignIsEditable ||
                this.campaign?.status === CampaignStatus.ArtworkCorrectionNeeded
            )
        },

        campaignPeriodString(): string {
            return `${
                this.campaignStartAt === undefined ||
                this.dateFormatter.stringify(this.campaignStartAt) ===
                    'Invalid Date'
                    ? 'Selecteer startdatum'
                    : this.dateFormatter.stringify(this.campaignStartAt)
            } - ${
                this.campaignEndAt === undefined ||
                this.dateFormatter.stringify(this.campaignEndAt) ===
                    'Invalid Date'
                    ? 'Selecteer einddatum'
                    : this.dateFormatter.stringify(this.campaignEndAt)
            }`
        },

        campaignStartAt: {
            get(): Date | undefined {
                if (this.campaign?.startAt) {
                    return new Date(this.campaign?.startAt)
                }

                return undefined
            },

            async set(newValue: Date): Promise<void> {
                if (this.campaign === undefined) {
                    return
                }

                await CampaignPersistence.update(
                    `${CampaignPersistence.getEndpoint()}/${this.id}`,
                    { startAt: newValue }
                )
            },
        },

        campaignEndAt: {
            get(): Date | undefined {
                if (this.campaign?.endAt) {
                    return new Date(this.campaign?.endAt)
                }

                return undefined
            },

            async set(newValue: Date): Promise<void> {
                if (this.campaign === undefined) {
                    return
                }

                await CampaignPersistence.update(
                    `${CampaignPersistence.getEndpoint()}/${this.id}`,
                    { endAt: newValue }
                )
            },
        },

        campaignExpectedDeliveryDate: {
            get(): Date | undefined {
                if (this.campaign?.expectedDeliveryDate) {
                    return new Date(this.campaign?.expectedDeliveryDate)
                }

                return undefined
            },

            async set(newValue: Date): Promise<void> {
                if (this.campaign === undefined) {
                    return
                }

                await CampaignPersistence.update(
                    `${CampaignPersistence.getEndpoint()}/${this.id}`,
                    { expectedDeliveryDate: newValue }
                )
            },
        },

        campaignName: {
            get(): string | undefined {
                return this.campaign?.name
            },

            async set(newValue: string): Promise<void> {
                if (this.campaign === undefined) {
                    return
                }

                if (newValue.length === 0) {
                    this.displayError(
                        'Naam mag niet leeg  zijn',
                        'Campagne naam'
                    )

                    return
                }

                await CampaignPersistence.update(
                    `${CampaignPersistence.getEndpoint()}/${this.id}`,
                    { name: newValue }
                )
            },
        },

        campaignDescription: {
            get(): string | undefined {
                return this.campaign?.description
            },

            async set(newValue: Date): Promise<void> {
                if (this.campaign === undefined) {
                    return
                }

                await CampaignPersistence.update(
                    `${CampaignPersistence.getEndpoint()}/${this.id}`,
                    { description: newValue }
                )
            },
        },

        filteredCampaignItemsByCampaignPackage(): Array<ICampaignPackageCollection> {
            if (!this.filterArtwork) {
                return this.campaignItemsByCampaignPackage
            }

            return this.campaignItemsByCampaignPackage
                .map((collection): ICampaignPackageCollection => {
                    const filteredCollection = Object.create(collection)

                    filteredCollection.campaignItems =
                        collection.campaignItems.filter(
                            (item): boolean => item.mediaObject === undefined
                        )

                    return filteredCollection
                })
                .filter(
                    (collection): boolean => collection.campaignItems.length > 0
                )
        },

        campaignItemsByCampaignPackage(): Array<ICampaignPackageCollection> {
            if (this.campaign === undefined) {
                return []
            }

            const result = groupBy(
                this.campaignItems
                    .filter(
                        (campaignItem: ICampaignItem): boolean =>
                            campaignItem.campaignPackage !== undefined
                    )
                    .slice()
                    .sort(
                        (
                            previous: ICampaignItem,
                            next: ICampaignItem
                        ): number => previous.id - next.id
                    ),
                'campaignPackage'
            ) as Dictionary<any>

            return Object.keys(result).map(
                (key: string): ICampaignPackageCollection => ({
                    campaignPackageIri: key,

                    campaignPackageStoreTypes:
                        this.getCampaignPackageStoreTypes(key),

                    campaignItems: result[key],
                })
            )
        },

        campaignItems(): Array<ICampaignItem> {
            if (this.campaign === undefined) {
                return []
            }

            return this.$store.state.campaignItems.items.filter(
                (item): boolean => item.campaign === this.campaign?.['@id']
            )
        },

        productionPackages(): Array<IProductionPackage> {
            return this.$store.state.productionPackages.items.filter(
                (item: IProductionPackage): boolean =>
                    item.relationProductionPackageItems.length > 0
            )
        },

        campaignPackages(): Array<ICampaignPackage> {
            return this.$store.state.campaignPackages.items.filter(
                (item: ICampaignPackage): boolean =>
                    item.campaign ===
                    `${CampaignPersistence.getEndpoint()}/${this.id}`
            )
        },

        products(): Array<IProductionProduct> {
            return Products
        },

        storeTypes(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },

        activeStoreTypes(): Array<IStoreType> {
            return this.campaign === undefined
                ? []
                : this.storeTypes.filter((item: IStoreType): boolean =>
                      this.campaign?.selectedStoreTypes
                          ? this.campaign.selectedStoreTypes.includes(
                                item['@id']
                            )
                          : false
                  )
        },

        departments(): Array<IDepartment> {
            return this.$store.state.storeDepartments.items
        },

        activeDepartments(): Array<IDepartment> {
            if (this.campaign === undefined) {
                return []
            }

            return this.departments.filter((item: IDepartment): boolean =>
                this.campaign?.selectedStoreDepartments === undefined
                    ? false
                    : this.campaign.selectedStoreDepartments.includes(
                          item['@id']
                      )
            )
        },

        campaign(): ICampaign | undefined {
            return this.$store.state.campaigns.items.find(
                (item): boolean => item.id.toString() === this.id
            )
        },
    },
})
