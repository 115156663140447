


























import { PropType } from 'vue'

import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import ProductionProductPersistence from '@/persistence/ProductionProductPersistence'
import {
    ICampaignItem,
    IMediaObject,
    IProductionProduct,
} from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'CampaignItemPreview',
    components: { MaterialBlueprint },

    props: {
        campaignItem: {
            type: Object as PropType<ICampaignItem>,
        },
    },

    async mounted(): Promise<void> {
        if (this.campaignItem?.relationProductionProduct) {
            await ProductionProductPersistence.getByIri(
                this.campaignItem?.relationProductionProduct
            )
        }
    },

    computed: {
        relationProductionProduct(): IProductionProduct | undefined {
            return this.$store.state.productionProducts.items.find(
                (item): boolean =>
                    item['@id'] === this.campaignItem?.relationProductionProduct
            )
        },

        mediaObject(): IMediaObject | undefined {
            return this.$store.state.mediaObjects.items.find(
                (item): boolean =>
                    item['@id'] === this.campaignItem?.mediaObject
            )
        },

        pendingMediaObject(): IMediaObject | undefined {
            return this.$store.state.mediaObjects.items.find(
                (item): boolean =>
                    item['@id'] === this.campaignItem?.pendingMediaObject
            )
        },
    },
})
