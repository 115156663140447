



























































import Vue from 'vue'

import Heading from '@/components/ui/Heading.vue'
import StoreTypePersistence from '@/persistence/StoreTypePersistence'
import { IStoreType } from '@/types/api/Interfaces'

export default Vue.extend({
    name: 'StoreTypes',

    components: {
        Heading,
    },

    computed: {
        types(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },
    },

    async mounted(): Promise<void> {
        await StoreTypePersistence.loadAll()
    },

    methods: {
        async createStoreType(): Promise<void> {
            await this.$api
                .authenticated()
                .post('/api/store_types', { name: 'Nieuw' })
            await StoreTypePersistence.loadAll()
        },

        async deleteStoreType(storeType: IStoreType): Promise<void> {
            await this.$api.authenticated().delete(storeType['@id'])
            await StoreTypePersistence.loadAll()
        },
    },
})
