enum CampaignStatus {
    Concept = 'concept',
    ArtworkAttaching = 'artwork_attaching',
    PriceInReview = 'price_in_review',
    ArtworkCorrectionNeeded = 'artwork_correction_needed',
    ArtworkInReview = 'artwork_in_review',
    Producing = 'producing',
    Shipped = 'shipped',
    Finalized = 'finalized',
}

const campaignStatus: typeof CampaignStatus = {
    Concept: CampaignStatus.Concept,
    ArtworkAttaching: CampaignStatus.ArtworkAttaching,
    PriceInReview: CampaignStatus.PriceInReview,
    ArtworkCorrectionNeeded: CampaignStatus.ArtworkCorrectionNeeded,
    ArtworkInReview: CampaignStatus.ArtworkInReview,
    Producing: CampaignStatus.Producing,
    Shipped: CampaignStatus.Shipped,
    Finalized: CampaignStatus.Finalized,
}

export { CampaignStatus, campaignStatus }
