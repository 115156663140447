
















import Vue from 'vue'

interface IData {
    name: string
    department: string | undefined
}

export default Vue.extend({
    name: 'CreateSegmentModal',

    data(): IData {
        return {
            name: '',
            department: undefined,
        }
    },

    methods: {
        create(): void {
            this.$emit('create', {
                name: this.name,
                department: this.department,
            })
            this.name = ''
            this.department = undefined
        },

        submit(): void {
            this.create()
            this.$bvModal.hide('createSegment')
        },
    },
})
