





































import Vue from 'vue'

import Heading from '@/components/ui/Heading.vue'

interface IData {
    newDepartment: string
    departments: Array<string>
}

export default Vue.extend({
    name: 'Departments',
    components: { Heading },

    data(): IData {
        return {
            newDepartment: '',

            departments: [
                'Groente en Fruit',
                'Zoetwaren',
                'Kaas',
                'Vers Brood',
                'Rest',
            ],
        }
    },

    methods: {
        addDepartment(): void {
            this.departments.push(this.newDepartment)
            this.newDepartment = ''
        },

        deleteDepartment(department: string): void {
            this.departments = this.departments.filter(
                (index): boolean => index !== department
            )
        },
    },
})
