





































import Vue, { PropType } from 'vue'

import { IMediaObject } from '@/types/api/Interfaces'

interface IData {
    name: string | undefined
    tags: Array<string>
}

export default Vue.extend({
    name: 'UpdateFileModal',

    props: {
        file: {
            type: Object as PropType<IMediaObject>,
            default: undefined,
        },
    },

    data(): IData {
        return {
            name: undefined,
            tags: [],
        }
    },

    watch: {
        file(): void {
            this.load()
        },
    },

    mounted(): void {
        this.load()
    },

    methods: {
        load(): void {
            this.name = this.file?.name

            this.tags = []
        },

        update(): void {
            this.$emit('update', {
                name: this.name,
                tags: this.tags,
            })
        },

        submit(): void {
            this.update()
            this.$bvModal.hide('updateFile')
        },
    },
})
