import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class MaterialPersistence extends ApiPersistence {}

export default new MaterialPersistence(
    store.state.materials,
    api,
    '/api/materials'
)
