
























































































































import Vue, { PropType } from 'vue'
import { BFormInput } from 'bootstrap-vue'

import StoreTypePosition from '@/components/StoreTypePosition.vue'
import StorePersistence from '@/persistence/StorePersistence'
import StoreTypePersistence from '@/persistence/StoreTypePersistence'
import SegmentPersistence from '@/persistence/SegmentPersistence'
import { IPosition, ISegment, ISegmentContainer } from '@/types/api/Interfaces'

interface IData {
    editing: boolean
}

export default Vue.extend({
    name: 'SegmentConfigure',
    components: { StoreTypePosition },

    props: {
        segment: {
            type: Object as PropType<ISegment>,
            required: true,
        },

        item: {
            type: Object as PropType<ISegmentContainer>,
            required: true,
        },

        type: {
            type: String,
            required: true,

            validator(value): boolean {
                return ['Store', 'StoreType'].includes(value)
            },
        },
    },

    data(): IData {
        return {
            editing: false,
        }
    },

    computed: {
        hasSegment(): boolean {
            return this.item.segments.includes(this.segment['@id'])
        },
    },

    methods: {
        positionItem(iri: string): IPosition {
            return this.$store.state.positions.items.find(
                (item: IPosition): boolean => item['@id'] === iri
            )
        },

        async toggle(): Promise<void> {
            let newSegments = []

            if (this.hasSegment) {
                newSegments = this.item.segments?.filter(
                    (item): boolean => item !== this.segment['@id']
                )
            } else {
                newSegments = this.item.segments
                newSegments?.push(this.segment['@id'])
            }

            await (this.type === 'StoreType'
                ? StoreTypePersistence.update(this.item['@id'], {
                      segments: newSegments,
                  })
                : StorePersistence.update(this.item['@id'], {
                      segments: newSegments,
                  }))
        },

        duplicate(): void {
            this.$emit('showModal', {
                type: 'duplicateSegment',

                payload: {
                    parentSegment: this.segment.id,
                    department: this.segment.department,
                    name: this.segment.name,
                },
            })
        },

        showCreatePositionModal(): void {
            this.$emit('showModal', {
                type: 'createPosition',

                payload: {
                    segment: this.segment['@id'],
                },
            })
        },

        edit(): void {
            this.editing = true
            this.$nextTick((): void => {
                const input: BFormInput = this.$refs.segmentName as BFormInput

                input.focus()
            })
        },

        async saveItem(): Promise<void> {
            const input: BFormInput = this.$refs.segmentName as BFormInput

            await SegmentPersistence.update(this.segment['@id'], {
                name: (input.$el as HTMLInputElement).value,
            })
            this.editing = false
        },

        async removeItem(segment: ISegment): Promise<void> {
            await SegmentPersistence.remove(segment['@id'])
        },
    },
})
