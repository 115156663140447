
























































































import { PropType } from 'vue'
import axios from 'axios'

import CampaignItemPackageProduct from '@/components/Resource/CampaignItemPackageProduct.vue'
import MediaObjectGallery from '@/components/ui/MediaObjectGallery.vue'
import HttpStatusCodes from '@/enums/HttpStatusCodes'
import {
    deleteConfirmOptions,
    persistenceErrorOptions,
} from '@/constants/messages'
import CampaignPackagePersistence from '@/persistence/CampaignPackagePersistence'
import { ICampaignItem, ICampaignPackage } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

interface IData {
    editName: Boolean
    debounceDelay: number
}

export default TypedVue.extend({
    name: 'CampaignItemPackage',
    components: { MediaObjectGallery, CampaignItemPackageProduct },

    data(): IData {
        return {
            editName: false,
            debounceDelay: 500,
        }
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        packageIri: {
            type: String,
            required: true,
        },

        items: {
            type: Array as PropType<Array<ICampaignItem>>,
            required: true,
        },
    },

    methods: {
        showMovePackageModal(): void {
            this.$emit('move', this.packageIri)
        },

        async deletePackage(): Promise<void> {
            const result = await this.$bvModal.msgBoxConfirm(
                `Weet je zeker dat je "${
                    this.campaignPackage?.name ?? 'Geen naam'
                }" wilt verwijderen?`,
                deleteConfirmOptions
            )

            if (result) {
                try {
                    await CampaignPackagePersistence.remove(this.packageIri)
                } catch (error) {
                    if (!axios.isAxiosError(error)) {
                        throw error
                    }

                    await (error.response?.status === HttpStatusCodes.BadRequest
                        ? this.$bvModal.msgBoxOk(
                              'Dit pakket kan niet verwijderd worden',
                              persistenceErrorOptions
                          )
                        : this.$bvModal.msgBoxOk(
                              'Dit pakket kan niet verwijderd worden, door een onbekende fout',
                              persistenceErrorOptions
                          ))
                }
            }
        },

        getPackage(uri: string | undefined): ICampaignPackage | undefined {
            return this.$store.state.campaignPackages.items.find(
                (item: ICampaignPackage): boolean => item['@id'] === uri
            )
        },
    },

    computed: {
        campaignPackage(): ICampaignPackage | undefined {
            return this.getPackage(this.packageIri)
        },

        campaignPackageName: {
            get(): string | undefined {
                return this.campaignPackage?.name
            },

            async set(newValue: string): Promise<void> {
                if (newValue.trim().length === 0) {
                    this.$bvToast.toast('Pakket naam aanpassen', {
                        title: 'Naam mag niet leeg zijn',
                        variant: 'danger',
                    })

                    return
                }

                await CampaignPackagePersistence.update(this.packageIri, {
                    name: newValue,
                })
            },
        },
    },
})
