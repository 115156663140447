import IState from '@/store/modules/nav/IState'

const state: IState = {
    iconStyle: 'fal',
    collapsed: false,
    closed: true,

    items: [
        {
            text: 'Dashboard',
            to: '/dashboard',
            icon: 'home-alt',
        },
        {
            text: 'Orders',
            to: '/orders',
            icon: 'file-alt',
        },
        {
            text: 'Facturen',
            to: '/invoices',
            icon: 'file-invoice-dollar',
        },
        {
            text: 'Producten',
            icon: 'store',

            children: [
                {
                    text: 'Productie',
                    to: '/production_products',
                    icon: 'print',
                },
                {
                    text: 'Voorraad',
                    to: '/stock_products',
                    icon: 'box',
                },
            ],
        },
        {
            text: 'Drukwerk',
            icon: 'print',

            children: [
                {
                    text: 'Materialen',
                    to: '/materials',
                    icon: 'scroll',
                },
            ],
        },

        {
            text: 'Relaties',
            to: '/relations',
            icon: 'user-tie',
        },
        {
            text: 'Gebruikers',
            to: '/users',
            icon: 'user-lock',
        },
        {
            text: 'Bestellen',
            to: '/producten',
            icon: 'copy',
        },
        {
            text: 'Campagnes',
            icon: 'megaphone',
            to: '/campaigns',
        },
        {
            text: 'Pakketten',
            to: '/packages',
            icon: 'box',
        },
        {
            text: 'Bestanden',
            icon: 'file',
            to: '/files',
        },
        {
            text: 'Vestigingen',
            icon: 'store',
            to: '/stores',
        },
        {
            text: 'Tags',
            icon: 'tag',
            to: '/tags',
        },
        {
            text: 'Instellingen',

            children: [
                {
                    text: 'Winkeltypes',
                    icon: 'store-alt',
                    to: '/store-types',
                },
                {
                    text: 'Afmetingen',
                    icon: 'sticky-note',
                    to: '/sizes',
                },
                {
                    text: 'Globaal DNA',
                    icon: 'dna',
                    to: '/globaldna',
                },
            ],

            icon: 'cog',
        },

        // {
        //     text: 'Producten',
        //     to: '/products',
        //     icon: 'shopping-bag',
        // },
        // {
        //     text: 'Orders',
        //     to: '/orders',
        //     icon: 'clipboard-list',
        // },
        // {
        //     text: 'Offertes',
        //     to: '/quotes',
        //     icon: 'clipboard-check',
        // },
        // {
        //     text: 'Sjablonen',
        //     to: '/templates',
        //     icon: 'ruler-triangle',
        // },
        // {
        //     text: 'Assets',
        //     to: '/assets',
        //     icon: 'images',
        // },
    ],
}

export default state
