










import SideNavigation from '@/components/SideNavigation.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'App',

    components: {
        TopNavigation,
        SideNavigation,
    },
})
