















































































import Vue, { PropType } from 'vue'
import { BFormInput } from 'bootstrap-vue'

import SegmentConfigure from '@/components/Resource/SegmentConfigure.vue'
import StoreDepartmentPersistence from '@/persistence/StoreDepartmentPersistence'
import {
    IDepartment,
    IPosition,
    ISegment,
    ISegmentContainer,
} from '@/types/api/Interfaces'

interface IData {
    editing: boolean
}

export default Vue.extend({
    name: 'StoreDepartmentConfigure',

    components: {
        SegmentConfigure,
    },

    props: {
        department: {
            type: Object as PropType<IDepartment>,
            required: true,
        },

        item: {
            type: Object as PropType<ISegmentContainer>,
            required: true,
        },

        type: {
            type: String,
            required: true,

            validator(value): boolean {
                return ['Store', 'StoreType'].includes(value)
            },
        },
    },

    data(): IData {
        return {
            editing: false,
        }
    },

    computed: {
        segments(): Array<ISegment> {
            return this.$store.state.storeSegments.items.filter(
                (item: ISegment): boolean =>
                    item.department === this.department['@id']
            )
        },

        departments(): Array<IDepartment> {
            return this.$store.state.storeDepartments.items
        },

        positions(): Array<IPosition> {
            return this.$store.state.positions.items
        },
    },

    methods: {
        showCreateSegmentModal(): void {
            this.$emit('showModal', {
                type: 'createSegment',

                payload: {
                    department: this.department['@id'],
                },
            })
        },

        edit(): void {
            this.editing = true

            const input: BFormInput = this.$refs.departmentName as BFormInput

            this.$nextTick((): void => {
                input.focus()
            })
        },

        async removeItem(): Promise<void> {
            await StoreDepartmentPersistence.remove(this.department['@id'])
        },

        async saveItem(): Promise<void> {
            const input: BFormInput = this.$refs.departmentName as BFormInput

            await StoreDepartmentPersistence.update(this.department['@id'], {
                name: (input.$el as HTMLInputElement).value,
            })
            this.editing = false
        },
    },
})
