




import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'Templates',
})
