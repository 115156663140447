import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate, {
    classes: {
        outer: '',
        wrapper: 'form-group',
        label: 'form-label',

        input: (context: any): string => {
            switch (context.type) {
                case 'reset':
                    return 'btn btn-danger'
                case 'button':
                    return 'btn btn-light'
                case 'submit':
                    return 'btn btn-primary'

                default:
                    return 'form-control'
            }
        },

        help: 'form-text text-muted',
        errors: 'invalid-feedback',
    },
})
