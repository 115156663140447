var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-3 py-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1 text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.mediaObject.name) + " ")]), _c('font-awesome-icon', {
    staticClass: "text-muted ml-4",
    attrs: {
      "icon": ['far', 'times-circle']
    },
    on: {
      "!click": function click($event) {
        $event.stopPropagation();
        return _vm.$emit('clear');
      }
    }
  })], 1), _vm.mediaObject.status === _vm.mediaObjectStatus.Completed ? [_c('div', {
    staticClass: "d-flex small text-muted"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'check']
    }
  }), _c('span', [_vm._v("Klaar")])], 1)]), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "height": "10px"
    }
  }, [_c('b-progress-bar', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "variant": "success"
    }
  })], 1)] : _vm.mediaObject.status === _vm.mediaObjectStatus.Invalid ? [_c('div', {
    staticClass: "d-flex small text-muted"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'times']
    }
  }), _c('span', [_vm._v("Mislukt, document is niet geldig")])], 1)]), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "height": "10px"
    }
  }, [_c('b-progress-bar', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "variant": "danger"
    }
  })], 1)] : _vm.mediaObject.status === _vm.mediaObjectStatus.Failed ? [_c('div', {
    staticClass: "d-flex small text-muted"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'times']
    }
  }), _c('span', [_vm._v(" Mislukt. " + _vm._s(_vm.mediaObject.rejectReason === 'Document has multiple pages.' ? "Document heeft meerdere pagina's" : 'onbekende fout') + " ")])], 1)]), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "height": "10px"
    }
  }, [_c('b-progress-bar', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "variant": "danger"
    }
  })], 1)] : [_c('div', {
    staticClass: "d-flex small text-muted"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'spinner-third'],
      "spin": ""
    }
  }), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.mediaObject.status) + "... ")])], 1), _c('div', [_vm._v(_vm._s(_vm.size))])]), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "height": "10px"
    }
  }, [_c('b-progress-bar', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "variant": "info",
      "animated": ""
    }
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }