






































































import { PropType } from 'vue'
import axios from 'axios'
import lodash from 'lodash'

import HttpStatusCodes from '@/enums/HttpStatusCodes'
import { persistenceErrorOptions } from '@/constants/messages'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import orderStatus, { IOrderStatus } from '@/constants/order-status'
import {
    ICampaign,
    ITransitionMetadata,
    IWorkFlow,
} from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

interface IData {
    orderStatus: Array<IOrderStatus>
    reason: string | undefined
    pendingTransition: string | undefined
}

export default TypedVue.extend({
    name: 'OrderStatusDropdown',

    props: {
        campaign: Object as PropType<ICampaign>,

        size: {
            type: String,
            default: 'md',
        },
    },

    data(): IData {
        return {
            orderStatus,
            reason: undefined,
            pendingTransition: undefined,
        }
    },

    methods: {
        status(name: string): IOrderStatus {
            const status = this.orderStatus.find(
                (item: IOrderStatus): boolean => item.status === name
            )

            return status ?? this.orderStatus[0]
        },

        async onReasonModalOk(): Promise<void> {
            if (this.pendingTransition && this.reason) {
                await this.transition(this.pendingTransition, {
                    reason: this.reason,
                })
            }

            this.pendingTransition = undefined
        },

        onReasonModalShow(): void {
            this.reason = undefined
        },

        async clickWorkFlow(transition: string): Promise<void> {
            if (['deny_price', 'deny_artwork'].includes(transition)) {
                this.pendingTransition = transition
                this.$bvModal.show(`reason-modal-${this.campaign.id}`)

                return
            }

            await this.transition(transition)
        },

        async transition(
            transition: string,
            metadata: ITransitionMetadata | undefined = undefined
        ): Promise<void> {
            try {
                await CampaignPersistence.transition(
                    this.campaign['@id'],
                    'status',
                    transition,
                    metadata
                )
                this.$emit('update')
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                const { response } = error

                if (response.status === HttpStatusCodes.BadRequest) {
                    await this.$bvModal.msgBoxOk(
                        'De status van deze campagne kan niet gewijzigd worden',
                        persistenceErrorOptions
                    )
                }
            }
        },
    },

    computed: {
        availableFlows(): Array<IWorkFlow> {
            return lodash.uniqBy(
                this.campaign['@workFlows'].campaign_status.filter(
                    (workFlow): boolean =>
                        workFlow.enabled || workFlow.blockers.length > 0
                ),
                'transition'
            )
        },
    },
})
