var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "asset-item",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "labels position-absolute top"
  }, [_c('b-badge', [_vm._v("10 mb")]), _c('b-badge', [_vm._v("1024x874")])], 1), _c('div', {
    staticClass: "labels position-absolute bottom"
  }, [_c('b-badge', [_vm._v("JPG")])], 1), _c('b-card-img', {
    ref: "image",
    attrs: {
      "src": require("./../../assets/product.jpg")
    }
  })], 1), _c('b-card-footer', {
    staticClass: "py-1 px-2"
  }, [_vm._v(" Filename "), _c('div', {
    staticClass: "tags small"
  }, [_c('b-badge', {
    attrs: {
      "variant": "dark",
      "pill": ""
    }
  }, [_vm._v("Vleeswaren")]), _c('b-badge', {
    attrs: {
      "variant": "dark",
      "pill": ""
    }
  }, [_vm._v("Houdbaar")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }