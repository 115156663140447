var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Cart"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [!_vm.cart.items || _vm.cart.items.length === 0 ? _c('b-card', {
    staticClass: "mb-3 text-center"
  }, [_vm._v(" Er zitten nog geen items in de winkelwagen ")]) : _c('div', [_c('b-list-group', {
    staticClass: "cart mb-3"
  }, _vm._l(_vm.cart.items, function (item, key) {
    return _c('CartItem', {
      key: key,
      attrs: {
        "item": item
      }
    });
  }), 1), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-btn', {
    attrs: {
      "to": "/products",
      "size": "lg"
    }
  }, [_vm._v("Meer bestellen")]), _vm.cart.items && _vm.cart.items.length > 0 ? _c('b-btn', {
    attrs: {
      "to": "/cart/checkout",
      "size": "lg",
      "variant": "primary"
    }
  }, [_vm._v(" Afrekenen ")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }