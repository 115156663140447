import Vue from 'vue'
import VueKonva from 'vue-konva'
import Konva from 'konva'

import Layer = Konva.Layer
import Stage = Konva.Stage
// eslint-disable-next-line @typescript-eslint/no-shadow -- don't see the problem
import Transformer = Konva.Transformer

// needed so Vue + typescript understand Konva's components. Add any Konva components you need here
Vue.component('VStage', Stage)
Vue.component('VLayer', Layer)
Vue.component('VTransformer', Transformer)

Vue.use(VueKonva)
