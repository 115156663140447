



























































































































































import axios from 'axios'

import CampaignItem from '@/components/Resource/CampaignItem.vue'
import Heading from '@/components/ui/Heading.vue'
import CreateCampaignModal from '@/components/Resource/Create/CreateCampaignModal.vue'
import CampaignListItem from '@/components/Resource/CampaignListItem.vue'
import MediaObjectGallery from '@/components/ui/MediaObjectGallery.vue'
import LoadingCard from '@/components/ui/LoadingCard.vue'
import { IMediaObjectGalleryItem } from '@/types/IMediaObjectGallery'
import HttpStatusCodes from '@/enums/HttpStatusCodes'
import {
    deleteConfirmOptions,
    persistenceErrorOptions,
} from '@/constants/messages'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import { ICampaign } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import ProductionPackageItemPersistence from '@/persistence/ProductionPackageItemPersistence'
// eslint-disable-next-line import/max-dependencies -- mag van Alex
import { CampaignStatus } from '@/enums/CampaignStatus'

interface IPreferences {
    display: string
    sortField: string
    sortDirection: string
    filters: {
        state: string
    }
}

interface IData {
    loaded: boolean
    selectedName: string | undefined
    preferences: IPreferences
}

const defaultPreferences = {
    display: 'detail',
    sortField: 'createdAt',
    sortDirection: 'DESC',

    filters: {
        state: 'notComplete',
    },
}

const galleryTimeout = 0

export default TypedVue.extend({
    name: 'Campaigns',

    components: {
        LoadingCard,
        MediaObjectGallery,
        CampaignListItem,
        CreateCampaignModal,
        Heading,
        CampaignItem,
    },

    data(): IData {
        return {
            loaded: false,
            preferences: defaultPreferences,

            selectedName: undefined,
        }
    },

    async mounted(): Promise<void> {
        this.loaded = false
        this.loadPreferences()
        await CampaignPersistence.loadAll()
        await ProductionPackageItemPersistence.loadAll()
        this.loaded = true
    },

    watch: {
        preferences: {
            handler(): void {
                this.savePreferences()
            },

            deep: true,
        },
    },

    computed: {
        campaigns(): Array<ICampaign> {
            return (
                this.$store.state.campaigns.items
                    .filter((item): boolean => {
                        if (
                            this.preferences.filters.state === 'complete' &&
                            item.status !== CampaignStatus.Finalized
                        ) {
                            return false
                        }

                        if (
                            this.preferences.filters.state === 'notComplete' &&
                            item.status === CampaignStatus.Finalized
                        ) {
                            return false
                        }

                        if (this.selectedName === undefined) {
                            return true
                        }

                        return item.name
                            .toLowerCase()
                            .includes(this.selectedName.toLowerCase())
                    })

                    .slice()
                    // eslint-disable-next-line max-statements -- care
                    .sort((previous: ICampaign, next: ICampaign): number => {
                        let previousValue = previous.startAt
                        let nextValue = next.startAt

                        if (this.preferences.sortField === 'createdAt') {
                            previousValue = previous.createdAt
                            nextValue = next.createdAt
                        } else if (
                            this.preferences.sortField ===
                            'expectedDeliveryDate'
                        ) {
                            previousValue = previous.expectedDeliveryDate
                            nextValue = next.expectedDeliveryDate
                        } else {
                            // dummy
                        }

                        const previousDate =
                            previousValue === undefined
                                ? Number.MAX_SAFE_INTEGER
                                : new Date(previousValue).getTime()
                        const nextDate =
                            nextValue === undefined
                                ? Number.MAX_SAFE_INTEGER
                                : new Date(nextValue).getTime()

                        if (this.preferences.sortDirection === 'ASC') {
                            return previousDate - nextDate
                        }

                        return nextDate - previousDate
                    })
            )
        },
    },

    methods: {
        savePreferences(): void {
            localStorage.setItem(
                'screen.campaigns.preferences',
                JSON.stringify(this.preferences)
            )
        },

        loadPreferences(): void {
            const preferences = localStorage.getItem(
                'screen.campaigns.preferences'
            )

            if (preferences === null) {
                return
            }

            this.preferences = {
                ...defaultPreferences,
                ...JSON.parse(preferences),
            }
            this.preferences.filters = {
                ...defaultPreferences.filters,
                ...JSON.parse(preferences).filters,
            }
        },

        showGallery(
            items: Array<IMediaObjectGalleryItem>,
            index: number
        ): void {
            // @ts-expect-error -- dunno how to fix
            this.$refs.gallery.open(items)
            setTimeout((): void => {
                // @ts-expect-error -- dunno how to fix
                this.$refs.gallery.setCurrent(index, false)
            }, galleryTimeout)
        },

        async createCampaign(data: object): Promise<void> {
            const campaign = (await CampaignPersistence.create(
                data
            )) as ICampaign

            await this.$router.push({
                name: 'CampaignItem',

                params: {
                    id: String(campaign.id),
                },
            })
        },

        async deleteCampaign(campaign: ICampaign): Promise<void> {
            const result = await this.$bvModal.msgBoxConfirm(
                `Weet je zeker dat je "${campaign.name}" wilt verwijderen?`,
                deleteConfirmOptions
            )

            if (result === true) {
                try {
                    await CampaignPersistence.remove(campaign['@id'])
                } catch (error) {
                    if (
                        !axios.isAxiosError(error) ||
                        error.response === undefined
                    ) {
                        throw error
                    }

                    const { response } = error

                    if (response.status === HttpStatusCodes.BadRequest) {
                        await this.$bvModal.msgBoxOk(
                            'Deze campagne kan niet verwijderd worden',
                            persistenceErrorOptions
                        )
                    }
                }
            }
        },
    },
})
