
















































import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import ProductionPackageItem from '@/components/Resource/ProductionPackageItem.vue'
import Heading from '@/components/ui/Heading.vue'
import LoadingCard from '@/components/ui/LoadingCard.vue'
import ProductionProductPersistence from '@/persistence/ProductionProductPersistence'
import { IProductionPackage } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import ProductionPackagePersistence from '@/persistence/ProductionPackagePersistence'
import ProductionPackageItemPersistence from '@/persistence/ProductionPackageItemPersistence'

interface IData {
    loaded: boolean
    name: string | undefined
}

export default TypedVue.extend({
    name: 'Packages',

    components: {
        LoadingCard,
        Heading,
        ProductionPackageItem,
        MaterialBlueprint,
    },

    data(): IData {
        return {
            loaded: false,
            name: undefined,
        }
    },

    async mounted(): Promise<void> {
        this.loaded = false
        await ProductionProductPersistence.loadAll()
        await ProductionPackagePersistence.loadAll()
        await ProductionPackageItemPersistence.loadAll()
        this.loaded = true
    },

    methods: {
        async newPackage(): Promise<void> {
            if (this.name !== undefined && this.name.trim().length === 0) {
                this.$bvToast.toast('Naam mag niet leeg zijn', {
                    title: 'Nieuw Pakket aanmaken',
                    variant: 'danger',
                })

                return
            }

            await this.$api
                .authenticated()
                .post('/api/relation_production_packages', {
                    name: this.name,
                    products: [],
                })
            await ProductionPackagePersistence.loadAll()
        },

        async deletePackage(uri: string): Promise<void> {
            await ProductionPackagePersistence.remove(uri)
        },
    },

    computed: {
        productionPackages(): Array<IProductionPackage> {
            return this.$store.state.productionPackages.items
                .slice()
                .sort(
                    (
                        previous: IProductionPackage,
                        next: IProductionPackage
                    ): number => next.id - previous.id
                )
        },
    },
})
