import api from '@keicreations/sapphire-vue/lib/api-platform'

import { ISegment } from '@/types/api/Interfaces'
import PositionPersistence from '@/persistence/PositionPersistence'
import IApiResource from '@/types/api/IApiResource'
import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class SegmentPersistence extends ApiPersistence {
    public async duplicate(id: number, data: object): Promise<IApiResource> {
        const segment: ISegment = (await this.post(
            `${this.endPoint}/${id}/duplicate`,
            data
        )) as ISegment

        segment.positions?.map(async (item: string): Promise<void> => {
            await PositionPersistence.load(item)
        })

        return <IApiResource>segment
    }
}

export default new SegmentPersistence(
    store.state.storeSegments,
    api,
    '/api/store_segments'
)
