





























































import Vue from 'vue'

import Heading from '@/components/ui/Heading.vue'
import StoreItem from '@/components/Resource/StoreItem.vue'
import StoreTypePersistence from '@/persistence/StoreTypePersistence'
import IResourceCollection from '@/types/IResourceCollection'
import { IStoreType } from '@/types/api/Interfaces'

interface IData {
    selectedName: string
    selectedCity: string
    selectedStoreTypes: Array<IStoreType>
}

export default Vue.extend({
    name: 'Stores',
    components: { StoreItem, Heading },

    data(): IData {
        return {
            selectedName: '',
            selectedCity: '',
            selectedStoreTypes: [],
        }
    },

    computed: {
        params(): object {
            return {
                name: this.selectedName,
                city: this.selectedCity,

                storeType: {
                    ...this.selectedStoreTypes.map(
                        (item: IStoreType): string => item['@id']
                    ),
                },
            }
        },

        storeTypes(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },
    },

    watch: {
        params(): void {
            this.refresh()
        },
    },

    async mounted(): Promise<void> {
        await StoreTypePersistence.loadAll()
    },

    methods: {
        refresh(): void {
            this.$nextTick((): void => {
                this.grid().refresh()
            })
        },

        grid(): IResourceCollection {
            return this.$refs.grid as IResourceCollection
        },
    },
})
