import { Module } from 'vuex'

import IRootState from '@/store/IRootState'
import IState from '@/store/modules/campaigns/IState'
import state from '@/store/modules/campaigns/state'
import getters from '@/store/modules/campaigns/getters'

const main: Module<IState, IRootState> = {
    namespaced: true,
    state,
    getters,
}

export default main
