


















































































































import { PropType } from 'vue'

import TypedVue from '@/config/vue'
import { IMaterial } from '@/types/api/Interfaces'

interface IData {
    selectedWeight?: string | undefined
    selectedColor?: string | undefined
    selectedType?: string | undefined
    selectedMaterial?: IMaterial | undefined
}

export default TypedVue.extend({
    name: 'MaterialItem',

    props: {
        method: {
            type: String,
            required: true,
        },

        value: {
            type: Object,
            required: false,
            default: undefined,
        },

        materials: {
            type: Array as PropType<Array<IMaterial>>,
            required: true,
        },
    },

    data(): IData {
        return {
            selectedWeight: undefined,
            selectedColor: undefined,
            selectedType: undefined,
            selectedMaterial: undefined,
        }
    },

    computed: {
        availableMaterials(): Array<IMaterial> {
            return this.materials.filter((item): boolean => {
                if (
                    this.selectedWeight &&
                    item.weight !== this.selectedWeight
                ) {
                    return false
                }

                if (this.selectedColor && item.color !== this.selectedColor) {
                    return false
                }

                return !(
                    this.selectedType &&
                    `${item.groupName} ${item.extra}` !== this.selectedType
                )
            })
        },

        allTypes(): Array<string> {
            return this.materials
                .map((item): string => `${item.groupName} ${item.extra}`)
                .filter(
                    (value, index, array): boolean =>
                        array.indexOf(value) === index
                )
        },

        availableTypes(): Array<string> {
            return this.materials
                .filter((item): boolean => {
                    if (
                        this.selectedWeight &&
                        item.weight !== this.selectedWeight
                    ) {
                        return false
                    }

                    return !(
                        this.selectedColor !== undefined &&
                        item.color !== this.selectedColor
                    )
                })
                .map((item): string => `${item.groupName} ${item.extra}`)
                .filter(
                    (value, index, array): boolean =>
                        array.indexOf(value) === index
                )
        },

        allColors(): Array<string> {
            return this.materials
                .map((item): string => item.color)
                .filter(
                    (value, index, array): boolean =>
                        array.indexOf(value) === index
                )
        },

        availableColors(): Array<string> {
            return this.materials
                .filter((item): boolean => {
                    if (
                        this.selectedWeight &&
                        item.weight !== this.selectedWeight
                    ) {
                        return false
                    }

                    return !(
                        this.selectedType !== undefined &&
                        `${item.groupName} ${item.extra}` !== this.selectedType
                    )
                })
                .map((item): string => item.color)
                .filter(
                    (value, index, array): boolean =>
                        array.indexOf(value) === index
                )
        },

        allWeights(): Array<string> {
            return this.materials
                .map((item): string => item.weight)
                .filter(
                    (value, index, array): boolean =>
                        array.indexOf(value) === index
                )
                .sort(
                    (previous, next): number =>
                        Number.parseInt(previous, 10) -
                        Number.parseInt(next, 10)
                )
        },

        availableWeights(): Array<string> {
            return this.materials
                .filter((item): boolean =>
                    this.selectedType
                        ? `${item.groupName} ${item.extra}` ===
                          this.selectedType
                        : true
                )
                .map((item): string => item.weight)
                .filter(
                    (value, index, array): boolean =>
                        array.indexOf(value) === index
                )
                .sort(
                    (previous, next): number =>
                        Number.parseInt(previous, 10) -
                        Number.parseInt(next, 10)
                )
        },
    },

    watch: {
        selectedWeight(): void {
            this.$nextTick((): void => {
                this.validateType()
                this.validateColor()
                this.resolveSelectedMaterial()
            })
        },

        selectedColor(): void {
            this.$nextTick((): void => {
                this.validateType()
                this.validateWeight()
                this.resolveSelectedMaterial()
            })
        },

        selectedType(): void {
            this.$nextTick((): void => {
                this.validateColor()
                this.validateWeight()
                this.resolveSelectedMaterial()
            })
        },
    },

    mounted(): void {
        this.selectedMaterial = this.value
    },

    methods: {
        validateType(): void {
            if (
                this.selectedType !== undefined &&
                !this.availableTypes.includes(this.selectedType)
            ) {
                this.selectedType = undefined
            }
        },

        validateWeight(): void {
            if (
                this.selectedWeight !== undefined &&
                !this.availableWeights.includes(this.selectedWeight)
            ) {
                this.selectedWeight = undefined
            }
        },

        validateColor(): void {
            if (
                this.selectedColor !== undefined &&
                !this.availableColors.includes(this.selectedColor)
            ) {
                this.selectedColor = undefined
            }
        },

        resolveSelectedMaterial(): void {
            this.selectedMaterial =
                this.selectedColor &&
                this.selectedWeight &&
                this.selectedType &&
                this.availableMaterials.length === 1
                    ? this.availableMaterials[0]
                    : undefined
        },

        cleanMaterial(item: string | undefined): string {
            if (item === undefined) {
                return ''
            }

            return item.replace('hv ', 'Houtvrij ')
        },
    },
})
