var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar-nav', [_c('b-nav-item', {
    staticClass: "button-open d-lg-none",
    on: {
      "click": _vm.toggle
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', _vm.icon],
      "fixed-width": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }