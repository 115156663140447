




























import { PropType } from 'vue'
import prettyBytes from 'pretty-bytes'

import { IMediaObjectUpload } from '@/store/modules/mediaObjectUploads/IState'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'UploadProgressItem',

    props: {
        mediaObject: {
            type: Object as PropType<IMediaObjectUpload>,
            required: true,
        },
    },

    computed: {
        size(): string {
            return prettyBytes(this.mediaObject.file.size, {
                locale: 'nl-NL',
            })
        },
    },
})
