var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Facturen"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('ResourceList', {
    attrs: {
      "uri": "/api/invoices",
      "display-property": "reference"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }