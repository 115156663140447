import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class CampaignPersistence extends ApiPersistence {}

export default new CampaignPersistence(
    store.state.campaigns,
    api,
    '/api/campaigns'
)
