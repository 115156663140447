


















































import { PropType } from 'vue'
import axios, { AxiosError } from 'axios'

import CampaignPersistence from '@/persistence/CampaignPersistence'
import StorePersistence from '@/persistence/StorePersistence'
import { ITag } from '@/types/api/Interfaces'
import TagPersistence from '@/persistence/TagPersistence'
import TypedVue from '@/config/vue'

interface IData {
    editTitle: boolean
}

export default TypedVue.extend({
    name: 'TagItem',

    data(): IData {
        return {
            editTitle: false,
        }
    },

    props: {
        tag: Object as PropType<ITag>,
    },

    async mounted(): Promise<void> {
        await CampaignPersistence.loadAll()
        await StorePersistence.loadAll()
    },

    methods: {
        showLinkTag(): void {
            this.$emit('linkTag', this.tag)

            this.$bvModal.show(`linkTag${this.tag['@id']}`)
        },

        async saveTag(): Promise<void> {
            if (this.tag.title.trim().length === 0) {
                this.$bvToast.toast('Titel mag niet leeg zijn', {
                    title: 'Tag opslaan',
                    variant: 'danger',
                })

                return
            }

            await TagPersistence.update(this.tag['@id'], {
                title: this.tag?.title,
            })
            this.editTitle = false
        },

        async deleteTag(): Promise<void> {
            try {
                await TagPersistence.remove(this.tag['@id'])
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                const axiosError = error as AxiosError<any>

                if (
                    axiosError.response!.data['hydra:description'].includes(
                        'references'
                    )
                ) {
                    this.$bvToast.toast('Deze tag is nog in gebruik', {
                        title: 'Tag verwijderen',
                        variant: 'danger',
                    })
                } else {
                    this.$bvToast.toast(
                        axiosError.response!.data?.['hydra:description'] ??
                            'Er is iets misgegaan.',
                        {
                            title: 'Fout',
                            variant: 'danger',
                        }
                    )
                }
            }
        },
    },
})
