import { MutationTree } from 'vuex'
import Vue from 'vue'

import IState from '@/store/modules/cart/IState'
import { ICartItem } from '@/types/api/Interfaces'

const mutations: MutationTree<IState> = {
    setId(state: IState, payload: number): void {
        state.id = payload
    },

    increase(state: IState, payload: ICartItem): void {
        let index = state.items.findIndex(
            (item): boolean => item.id === payload.id
        )

        if (index === -1) {
            const length = state.items.push({
                '@id': '1',
                '@context': '/api/CartItem',
                '@type': 'CartItem',
                id: payload.id,
                name: payload.name,
                configuration: payload.configuration,
                quantity: 0,
            })

            index = length - 1
        }

        state.items[index] = {
            '@id': '1',
            '@context': '/api/CartItem',
            '@type': 'CartItem',
            id: payload.id,
            name: payload.name,
            configuration: payload.configuration,
            quantity: state.items[index].quantity + 1,
        }

        Vue.set(state, 'items', Array.from(state.items))
    },

    set(state: IState, payload: ICartItem): void {
        if (
            Number(payload.quantity).toString() !== payload.quantity.toString()
        ) {
            return
        }

        const index = state.items.findIndex(
            (item): boolean => item.id === payload.id
        )

        if (payload.quantity < 1) {
            Vue.delete(state.items, index)
        } else {
            if (index === -1) {
                state.items.push({
                    '@id': '1',
                    '@context': '/api/CartItem',
                    '@type': 'CartItem',
                    id: payload.id,
                    name: payload.name,
                    configuration: payload.configuration,
                    quantity: Number(payload.quantity),
                })
            } else {
                state.items[index] = {
                    '@id': '1',
                    '@context': '/api/CartItem',
                    '@type': 'CartItem',
                    id: payload.id,
                    name: payload.name,
                    configuration: payload.configuration,
                    quantity: Number(payload.quantity),
                }
            }

            Vue.set(state, 'items', Array.from(state.items))
        }
    },

    decrease(state: IState, payload: ICartItem): void {
        const index = state.items.findIndex(
            (item): boolean => item.id === payload.id
        )

        if (index === -1) {
            return
        }

        if (state.items[index].quantity - 1 < 1) {
            Vue.delete(state.items, index)
        } else {
            state.items[index] = {
                '@id': '1',
                '@context': '/api/CartItem',
                '@type': 'CartItem',
                id: payload.id,
                name: payload.name,
                configuration: payload.configuration,
                quantity: state.items[index].quantity - 1,
            }
            Vue.set(state, 'items', Array.from(state.items))
        }
    },

    delete(state: IState, payload: ICartItem): void {
        const index = state.items.findIndex(
            (item): boolean => item.id === payload.id
        )

        if (index === -1) {
            return
        }

        Vue.delete(state.items, index)
    },
}

export default mutations
