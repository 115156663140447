var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', {
    staticClass: "campaign-status-table",
    attrs: {
      "small": "",
      "primary-key": "id",
      "striped": "",
      "items": _vm.tableEntries,
      "sort-desc": true,
      "sort-by": "on",
      "fields": _vm.fields,
      "no-sort-reset": ""
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }