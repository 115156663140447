


































import Vue from 'vue'

interface IData {
    name: string
    width: string
    height: string
}

export default Vue.extend({
    name: 'CreateSizeModal',

    data(): IData {
        return {
            name: '',
            width: '0',
            height: '0',
        }
    },

    methods: {
        create(): void {
            this.$emit('create', {
                name: this.name,
                width: Number.parseInt(this.width, 10),
                height: Number.parseInt(this.height, 10),
            })
            this.name = ''
        },

        submit(): void {
            this.create()
            this.$bvModal.hide('createSize')
        },
    },
})
