

















import NavigationToggle from '@/components/NavigationToggle.vue'
import UserNavigation from '@/components/UserNavigation.vue'
import CartNavigation from '@/components/CartNavigation.vue'
import UploadProgress from '@/components/ui/UploadProgress.vue'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'TopNavigation',

    components: {
        UploadProgress,
        CartNavigation,
        UserNavigation,
        NavigationToggle,
    },

    computed: {
        brand(): string {
            return this.$store.state.app.brand
        },
    },
})
