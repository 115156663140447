import { GetterTree } from 'vuex'

import { ICartItem } from '@/types/api/Interfaces'
import RootState from '@/store/IRootState'
import IState from '@/store/modules/cart/IState'

const getters: GetterTree<IState, RootState> = {
    item:
        (state) =>
        (id: number): ICartItem | undefined => {
            const index = state.items.findIndex(
                (item): boolean => item.id === id
            )

            if (index !== -1) {
                return state.items[index]
            }

            return undefined
        },

    itemQuantity:
        (state, getters) =>
        (id: number): number => {
            const item = getters.item(id)

            if (item) {
                return item.quantity
            }

            return 0
        },

    quantity: (state): number => {
        if (state.items.length === 0) {
            return 0
        }

        return state.items
            .map((item): number => item.quantity)
            .reduce((total, item): number => total + item)
    },
}

export default getters
