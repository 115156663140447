import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class CampaignItemPersistence extends ApiPersistence {}

export default new CampaignItemPersistence(
    store.state.campaignItems,
    api,
    '/api/campaign_items'
)
