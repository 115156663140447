var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sticky-top"
  }, [_c('b-navbar', {
    staticClass: "top-nav",
    attrs: {
      "type": "dark"
    }
  }, [_c('div', {
    staticClass: "d-flex d-lg-none"
  }, [_c('NavigationToggle', {
    staticClass: "text-light mr-3",
    attrs: {
      "nav": "nav.side"
    }
  })], 1), _c('b-navbar-brand', {
    staticClass: "mr-auto",
    attrs: {
      "to": "/dashboard"
    }
  }, [_vm._v(" " + _vm._s(_vm.brand) + " ")]), _c('div', {
    staticClass: "ml-auto d-flex"
  }, [_c('UploadProgress'), _c('UserNavigation')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }