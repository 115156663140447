import { GetterTree } from 'vuex'

import { IRelation } from '@/types/api/Interfaces'
import IState from '@/store/modules/relations/IState'
import IRootState from '@/store/IRootState'

const getters: GetterTree<IState, IRootState> = {
    getByUri:
        (state) =>
        (uri: string): IRelation | undefined =>
            state.items.find((item): boolean => item['@id'] === uri),
}

export default getters
