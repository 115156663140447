


























































































































import FilePreview from '@/components/Resource/FilePreview.vue'
import MediaObjectGallery from '@/components/ui/MediaObjectGallery.vue'
import { IMediaObjectGalleryItem } from '@/types/IMediaObjectGallery'
import { mediaObjectStatus } from '@/enums/MediaObjectStatus'
import TypedVue from '@/config/vue'
import MediaObjectPersistence from '@/persistence/MediaObjectPersistence'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import {
    ICampaign,
    ICampaignItem,
    IMediaObject,
    IProductionProduct,
} from '@/types/api/Interfaces'

interface IData {
    selected: string | undefined
    name: string | undefined
    width: string | undefined
    height: string | undefined
    sortField: string
    sortDirection: string
    campagneFilter: string | undefined
}

export default TypedVue.extend({
    name: 'MediaObjectBrowser',
    components: { FilePreview, MediaObjectGallery },

    async mounted(): Promise<void> {
        await MediaObjectPersistence.loadAll()
        await CampaignPersistence.loadAll()
        this.updateDimensions()
    },

    props: {
        selectedCampaignItemIri: {
            type: String,
        },
    },

    data(): IData {
        return {
            selected: undefined,
            name: undefined,
            width: undefined,
            height: undefined,
            sortField: 'createdAt',
            sortDirection: 'DESC',
            campagneFilter: undefined,
        }
    },

    watch: {
        selectedCampaignItemIri(): void {
            this.updateDimensions()
        },
    },

    methods: {
        selectMediaObject(mediaObject: IMediaObject): void {
            this.selected = mediaObject['@id']
            this.$emit('select', this.selected)
            this.$bvModal.hide('media-object-browser-modal')
        },

        updateDimensions(): void {
            if (this.product === undefined) {
                this.width = undefined
                this.height = undefined
            } else {
                this.width = this.product.width.toString()
                this.height = this.product.height.toString()
            }
        },

        showPreview(item: IMediaObjectGalleryItem): void {
            // @ts-expect-error -- ref
            this.$refs.gallery.open([item])
        },
    },

    computed: {
        sortDirections(): Array<string> {
            return ['ASC', 'DESC']
        },

        campaigns(): Array<ICampaign> {
            return this.$store.state.campaigns.items
                .slice()
                .sort((previous: ICampaign, next: ICampaign): number => {
                    const previousValue = new Date(
                        previous.updatedAt ?? previous.createdAt
                    )
                    const nextValue = new Date(next.updatedAt ?? next.createdAt)

                    return nextValue.getTime() - previousValue.getTime()
                })
        },

        sortFields(): Array<string> {
            return ['createdAt', 'size', 'width', 'height']
        },

        selectedCampaignItem(): ICampaignItem | undefined {
            return this.$store.state.campaignItems.items.find(
                (item: ICampaignItem): boolean =>
                    item['@id'] === this.selectedCampaignItemIri
            )
        },

        product(): IProductionProduct | undefined {
            return this.$store.state.productionProducts.items.find(
                (item: IProductionProduct): boolean =>
                    item['@id'] ===
                    this.selectedCampaignItem?.relationProductionProduct
            )
        },

        mediaObjects(): Array<IMediaObject> {
            return this.$store.state.mediaObjects.items
                .filter((item): boolean => {
                    if (item.status !== mediaObjectStatus.Completed) {
                        return false
                    }

                    if (
                        (this.width !== undefined &&
                            item.widthMm !== this.width) ||
                        (this.height !== undefined &&
                            item.heightMm !== this.height) ||
                        (this.campagneFilter !== undefined &&
                            !item.campaigns.includes(this.campagneFilter))
                    ) {
                        return false
                    }

                    if (this.name === undefined) {
                        return true
                    }

                    return item.name
                        .toLowerCase()
                        .includes(this.name.toLowerCase())
                })
                .slice()
                .sort((previous, next): number => {
                    let previousValue = 0
                    let nextValue = 0

                    if (this.sortField === 'size') {
                        previousValue = Number.parseInt(
                            previous[this.sortField],
                            10
                        )
                        nextValue = Number.parseInt(next[this.sortField], 10)
                    } else if (this.sortField === 'createdAt') {
                        previousValue = new Date(
                            previous[this.sortField]
                        ).getTime()
                        nextValue = new Date(next[this.sortField]).getTime()
                    } else {
                        // Eh ?
                    }

                    if (this.sortDirection === 'ASC') {
                        return previousValue - nextValue
                    }

                    return nextValue - previousValue
                })
        },
    },
})
