












































import TypedVue from '@/config/vue'

interface IData {
    internalValue: number
    step: number
}

export default TypedVue.extend({
    name: 'QuantitySelector',

    props: {
        value: {
            type: Number,
            required: false,
            default: 0,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data(): IData {
        return {
            internalValue: 0,
            step: 1,
        }
    },

    mounted(): void {
        this.internalValue = this.value
    },

    watch: {
        value(): void {
            this.internalValue = this.value
        },
    },

    methods: {
        add(): void {
            this.set(Number(this.internalValue) + this.step)
        },

        checkNull(quantity: number): void {
            if (quantity.toString() === '') {
                this.set(0)
            }
        },

        set(quantity: number): void {
            this.internalValue = quantity

            if (quantity.toString() === '') {
                return
            }

            this.$emit('input', Number(quantity))
        },

        remove(): void {
            this.set(this.internalValue - this.step)
        },
    },
})
