var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "header": "Samenstelling per winkel",
      "no-body": ""
    }
  }, _vm._l(_vm.storeTableItems, function (storeEntry, storeName) {
    return _c('b-card-body', {
      key: storeName,
      staticClass: "border-bottom"
    }, [_c('b-card-title', {
      staticClass: "mb-2"
    }, [_vm._v(" " + _vm._s(storeName) + " ")]), _c('h5', [_vm._v("Overzicht")]), _c('b-table-simple', {
      staticClass: "mb-0",
      attrs: {
        "hover": "",
        "responsive": "",
        "striped": ""
      }
    }, [_c('b-thead', {
      attrs: {
        "head-variant": "dark"
      }
    }, [_c('b-tr', [_c('b-th', {
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v("Vestigingsoverzicht")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', [_vm._v("Aantal pakketten")]), _c('b-td', {
      staticClass: "text-right"
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.getTotalPackagesForStore(storeName)) + " ")])])], 1), _c('b-tr', [_c('b-td', [_vm._v("Aantal individuele items")]), _c('b-td', {
      staticClass: "text-right"
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.getTotalItemsForStore(storeName)) + " ")])])], 1), _c('b-tr', [_c('b-td', [_vm._v("Totaalbedrag (excl. basiskosten)")]), _c('b-td', {
      staticClass: "text-right"
    }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.getTotalPriceForStore(storeName))) + " ")])])], 1)], 1)], 1), _c('h5', [_vm._v("Prijsspecificatie")]), _vm._l(storeEntry, function (packageEntry, packageName) {
      return _c('div', {
        key: storeName + packageName,
        staticClass: "mt-3"
      }, [_c('h6', [_vm._v(_vm._s(packageName))]), _c('b-table-simple', {
        staticClass: "mb-0",
        attrs: {
          "striped": "",
          "hover": "",
          "responsive": ""
        }
      }, [_c('b-thead', {
        attrs: {
          "head-variant": "dark"
        }
      }, [_c('b-tr', [_c('b-th', [_vm._v("Item")]), _c('b-th', {
        staticClass: "text-right"
      }, [_vm._v("Stuksprijs")]), _c('b-th', {
        staticClass: "text-right"
      }, [_vm._v("Aantal")]), _c('b-th', {
        staticClass: "text-right"
      }, [_vm._v("Totaal")])], 1)], 1), _c('b-tbody', [_vm._l(packageEntry.products, function (product, index) {
        return _c('b-tr', {
          key: index
        }, [_c('b-td', [_vm._v(" " + _vm._s(product.name) + " ")]), _c('b-td', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatEur(product.unitPrice, 5)) + " ")]), _c('b-td', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(packageEntry.amount) + " ")]), _c('b-td', {
          staticClass: "text-right"
        }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatEur(Math.round((product.unitPrice + Number.EPSILON) * 100) / 100 * packageEntry.amount)) + " ")])])], 1);
      }), _c('b-tr', [_c('b-td', [_c('strong', [_vm._v("Totaal")])]), _c('b-td'), _c('b-td'), _c('b-td', {
        staticClass: "text-right"
      }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.getTotalPackagePriceForStoreOfType(packageName, _vm.getStoreTypeOfStore(storeName)))) + " ")])])], 1)], 2)], 1)], 1);
    })], 2);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }