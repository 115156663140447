
















































































import SideNavigation from '@keicreations/sapphire-vue/components/ui/SideNavigation.vue'
import jwtDecode from 'jwt-decode'

import router from '@/config/router'
import IJwtTokenPayload from '@/types/IJwtTokenPayload'
import INavItem from '@/types/INavItem'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'SideNavigation',
    extends: SideNavigation,

    computed: {
        roles(): Array<String> {
            if (this.$store.state.user.token) {
                const payload: IJwtTokenPayload = jwtDecode(
                    this.$store.state.user.token
                )

                return payload.roles
            }

            return []
        },

        parentItems(): Array<INavItem> {
            // @ts-expect-error -- Exists in parent
            return this.accessibleItems(this.items)
        },

        parentIconStyle(): string {
            // @ts-expect-error -- Exists in parent
            return this.iconStyle
        },

        parentClosed(): boolean {
            // @ts-expect-error -- Exists in parent
            return this.closed
        },

        parentCollapsed(): boolean {
            // @ts-expect-error -- Exists in parent
            return this.collapsed
        },
    },

    methods: {
        accessibleItems(items: Array<INavItem>): Array<INavItem> {
            return items.filter((item: INavItem): boolean => {
                if (
                    item.children &&
                    this.accessibleItems(item.children).length === 0
                ) {
                    return false
                }

                if (!item.to) {
                    return true
                }

                const matchedRoute = router.match(item.to)

                if (matchedRoute?.meta?.requiredRoles) {
                    return matchedRoute.meta.requiredRoles.some(
                        (requiredRole: string): boolean =>
                            this.roles.includes(requiredRole)
                    )
                }

                return true
            })
        },

        parentCollapse(): void {
            // @ts-expect-error -- Exists in parent
            this.collapse()
        },

        parentClose(): void {
            // @ts-expect-error -- Exists in parent
            this.close()
        },
    },
})
