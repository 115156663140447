


























































import { PropType } from 'vue'

import TypedVue from '@/config/vue'
import { ICampaign, IStore, ITag } from '@/types/api/Interfaces'
import StorePersistence from '@/persistence/StorePersistence'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import { CampaignStatus } from '@/enums/CampaignStatus'

interface IData {
    newCampaigns: Array<ICampaign>
    removeCampaigns: Array<ICampaign>
    newStores: Array<IStore>
    removeStores: Array<IStore>
}

export default TypedVue.extend({
    name: 'LinkTagModal',

    data(): IData {
        return {
            newCampaigns: [],
            removeCampaigns: [],
            newStores: [],
            removeStores: [],
        }
    },

    props: {
        tag: Object as PropType<ITag>,
    },

    computed: {
        stores(): Array<IStore> {
            return this.$store.state.stores.items
        },

        campaigns(): Array<ICampaign> {
            return this.$store.state.campaigns.items.filter(
                (campaign): boolean =>
                    campaign.status === CampaignStatus.Concept
            )
        },

        linkedCampaignsWithPendingChanges(): Array<ICampaign> {
            let linkedCampaigns = Array.from(this.linkedCampaigns)

            linkedCampaigns = linkedCampaigns.filter(
                (campaign): boolean =>
                    !this.removeCampaigns.some(
                        (removeCampaign): boolean =>
                            campaign['@id'] === removeCampaign['@id']
                    ) &&
                    (campaign.status === CampaignStatus.Concept ||
                        campaign.status ===
                            CampaignStatus.ArtworkCorrectionNeeded)
            )
            linkedCampaigns = linkedCampaigns.concat(this.newCampaigns)

            return linkedCampaigns
        },

        unmutableLinkedCampaigns(): Array<ICampaign> {
            return this.linkedCampaigns.filter(
                (campaign): boolean =>
                    campaign.status !== CampaignStatus.Concept
            )
        },

        linkedCampaigns(): Array<ICampaign> {
            if (this.tag === undefined) {
                return []
            }

            return this.$store.state.campaigns.items.filter(
                (campaign): boolean => campaign.tags.includes(this.tag['@id'])
            )
        },

        linkedStoresWithPendingChanges(): Array<IStore> {
            let linkedStores = Array.from(this.linkedStores)

            linkedStores = linkedStores.filter(
                (store): boolean =>
                    !this.removeStores.some(
                        (removeStore): boolean =>
                            store['@id'] === removeStore['@id']
                    )
            )
            linkedStores = linkedStores.concat(this.newStores)

            return linkedStores
        },

        linkedStores(): Array<IStore> {
            if (this.tag === undefined) {
                return []
            }

            return this.$store.state.stores.items.filter((store): boolean =>
                store.tags.includes(this.tag['@id'])
            )
        },
    },

    methods: {
        storeSelect(store: IStore): void {
            if (
                !this.linkedStores.some(
                    (storeItem): boolean => storeItem['@id'] === store['@id']
                )
            ) {
                this.newStores.push(store)
            }

            this.removeStores = this.removeStores.filter(
                (storeItem): boolean => storeItem['@id'] !== store['@id']
            )
        },

        storeRemove(store: IStore): void {
            if (
                this.linkedStores.some(
                    (storeItem): boolean => storeItem['@id'] === store['@id']
                )
            ) {
                this.removeStores.push(store)
            }

            this.newStores = this.newStores.filter(
                (storeItem): boolean => storeItem['@id'] !== store['@id']
            )
        },

        campaignSelect(campaign: ICampaign): void {
            this.newCampaigns.push(campaign)
            this.removeCampaigns = this.removeCampaigns.filter(
                (campaignItem): boolean =>
                    campaignItem['@id'] !== campaign['@id']
            )
        },

        campaignRemove(campaign: ICampaign): void {
            this.removeCampaigns.push(campaign)
            this.newCampaigns = this.newCampaigns.filter(
                (campaignItem): boolean =>
                    campaignItem['@id'] !== campaign['@id']
            )
        },

        async processChanges(): Promise<void> {
            await Promise.all(
                this.newCampaigns.map(async (newCampaign): Promise<void> => {
                    await this.linkCampaign(newCampaign)
                })
            )
            this.newCampaigns = []

            await Promise.all(
                this.removeCampaigns.map(
                    async (removeCampaign): Promise<void> => {
                        await this.unlinkCampaign(removeCampaign)
                    }
                )
            )

            this.removeCampaigns = []
            await Promise.all(
                this.newStores.map(async (newStore): Promise<void> => {
                    await this.linkStore(newStore)
                })
            )
            this.newStores = []
            await Promise.all(
                this.removeStores.map(async (removeStore): Promise<void> => {
                    await this.unlinkStore(removeStore)
                })
            )
            this.removeStores = []
        },

        async linkStore(store: IStore): Promise<void> {
            store.tags.push(this.tag['@id'])
            await StorePersistence.update(store['@id'], {
                tags: store.tags,
            })
        },

        async unlinkStore(store: IStore): Promise<void> {
            const newTags = store.tags.filter(
                (storeTag): boolean => storeTag !== this.tag?.['@id']
            )

            try {
                await StorePersistence.update(store['@id'], {
                    tags: newTags,
                })
            } catch {
                this.$bvToast.toast('Er is iets verkeerd gegaan.', {
                    title: 'Tag los koppelen',
                    variant: 'danger',
                })
            }
        },

        async linkCampaign(campaign: ICampaign): Promise<void> {
            campaign.tags.push(this.tag['@id'])
            await CampaignPersistence.update(campaign['@id'], {
                tags: campaign.tags,
            })
        },

        async unlinkCampaign(campaign: ICampaign): Promise<void> {
            const newTags = campaign.tags.filter(
                (campaignTag): boolean => campaignTag !== this.tag?.['@id']
            )

            try {
                await CampaignPersistence.update(campaign['@id'], {
                    tags: newTags,
                })
            } catch {
                this.$bvToast.toast('Er is iets verkeerd gegaan.', {
                    title: 'Tag los koppelen',
                    variant: 'danger',
                })
            }
        },
    },
})
