

















































import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'Heading',

    props: {
        title: {
            type: String,
            required: false,
        },
    },
})
