



































import CartItem from '@/components/Resource/CartItem.vue'
import Heading from '@/components/ui/Heading.vue'
import IState from '@/store/modules/cart/IState'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'Cart',
    components: { Heading, CartItem },

    computed: {
        cart(): IState {
            return this.$store.state.cart
        },
    },
})
