import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class CampaignPackageStoreTypePersistence extends ApiPersistence {}

export default new CampaignPackageStoreTypePersistence(
    store.state.campaignPackageStoreTypes,
    api,
    '/api/campaign_package_store_types'
)
