var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Products"
    }
  }), _c('b-container', {
    staticClass: "my-5"
  }, [_c('h2', [_vm._v("Vers vlees")]), _c('b-row', {
    staticClass: "mb-3"
  }, _vm._l(12, function (i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('ProductItem', {
      attrs: {
        "id": i,
        "name": 'Product ' + i
      }
    })], 1);
  }), 1), _c('h2', [_vm._v("Ovenvers brood")]), _c('b-row', {
    staticClass: "mb-3"
  }, _vm._l(12, function (i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('ProductItem', {
      attrs: {
        "id": i,
        "name": 'Product ' + i
      }
    })], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }