































































































import Heading from '@/components/ui/Heading.vue'
import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import IHydraCollection from '@/types/api/IHydraCollection'
import MaterialPersistence from '@/persistence/MaterialPersistence'
import TypedVue from '@/config/vue'
import {
    IMaterial,
    IProductionProduct,
    IRelation,
} from '@/types/api/Interfaces'
// import RelationPersistence from '@/persistence/RelationPersistence'

export default TypedVue.extend({
    name: 'ProductionProducts',
    components: { MaterialBlueprint, Heading },

    methods: {
        async loadResources(data: IHydraCollection): Promise<void> {
            // eslint-disable-next-line no-loops/no-loops -- debug
            for (const item of data[
                'hydra:member'
            ] as Array<IProductionProduct>) {
                if (item.material) {
                    // eslint-disable-next-line no-await-in-loop -- debug
                    void (await MaterialPersistence.getByIri(item.material))
                }

                // if (item.relation) {
                //     // eslint-disable-next-line no-await-in-loop -- debug
                //     void (await RelationPersistence.getByIri(item.relation))
                // }
            }
        },

        materialResource(iri: string): IMaterial | undefined {
            return this.$store.state.materials.items.find(
                (item): boolean => item['@id'] === iri
            )
        },

        relationResource(iri: string): IRelation | undefined {
            return this.$store.state.relations.items.find(
                (item): boolean => item['@id'] === iri
            )
        },
    },
})
