















































import Vue from 'vue'

import QuantitySelector from '@/components/ui/QuantitySelector.vue'
import TypedVue from '@/config/vue'

interface IData {
    active: boolean
}

export default TypedVue.extend({
    name: 'ProductItem',
    components: { QuantitySelector },

    props: {
        id: {
            type: Number,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },
    },

    data(): IData {
        return {
            active: false,
        }
    },

    computed: {
        currentQuantity(): number {
            return this.$store.getters['cart/itemQuantity'](this.id)
        },
    },

    methods: {
        remove(): void {
            void this.$store.dispatch('cart/remove', {
                id: this.id,
                name: this.name,
            })
        },

        setActive(event: Event): void {
            this.active = true
            event.preventDefault()
        },

        add(): void {
            void this.$store.dispatch('cart/add', {
                id: this.id,
                name: this.name,
            })
            this.animateAdd()
        },

        getImage(): Element {
            return (this.$refs.image as Vue).$el as Element
        },

        animateAdd(): void {
            const original = this.getImage()
            const copy = original.cloneNode() as Element

            const rect = original.getBoundingClientRect()

            copy.setAttribute(
                'style',
                `width: ${original.clientWidth}px; left: ${rect.left}px; top: ${rect.top}px`
            )
            this.animateCopy(copy)
        },

        animateCopy(copy: Element): void {
            const app = document.querySelector('#app')
            const timeout = 1000

            if (!app) {
                return
            }

            app.append(copy)
            copy.classList.add('addingToCart')

            setTimeout((): void => {
                copy.remove()
            }, timeout)
        },
    },
})
