var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "franchise-item",
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_vm.storeType ? _c('b-badge', {
          attrs: {
            "pill": ""
          }
        }, [_vm._v(_vm._s(_vm.storeType.name))]) : _c('span', {
          staticClass: "small text-muted"
        }, [_vm._v("Type onbekend")]), _c('div', [_c('b-btn', {
          staticClass: "ml-1",
          attrs: {
            "to": {
              name: 'Store',
              params: {
                id: _vm.item.id
              }
            },
            "size": "sm",
            "variant": "info"
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'pencil'],
            "fixed-width": ""
          }
        })], 1), _c('b-btn', {
          staticClass: "ml-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'trash'],
            "fixed-width": ""
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex align-items-begin"
  }, [_c('b-avatar', {
    staticClass: "mr-3",
    attrs: {
      "size": "40px",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.initials()) + " ")]), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c('b-card-sub-title', [_vm._v(_vm._s(_vm.item.city))]), _vm._l(_vm.storeTags, function (storeTag) {
    return _c('b-badge', {
      key: storeTag['@id'],
      staticClass: "mr-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(" " + _vm._s(storeTag.title) + " ")]);
  })], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }