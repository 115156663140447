var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Dashboard"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('b-row', _vm._l(_vm.accessibleItems(_vm.navItems), function (item, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "cols": 12,
        "sm": index === 0 ? 12 : 6,
        "md": index === 0 ? 12 : 4,
        "lg": index === 0 ? 12 : 3
      }
    }, [_c('b-card', {
      staticClass: "h-100",
      staticStyle: {
        "min-height": "240px"
      },
      attrs: {
        "bg-variant": "secondary",
        "text-variant": "light",
        "border-variant": "light",
        "no-body": ""
      }
    }, [_c('b-card-body', {
      staticClass: "pb-0"
    }, [!item.children || item.children.length === 0 ? [_c('b-card-title', {
      staticClass: "mb-3"
    }, [_c('b-link', {
      staticClass: "text-light text-decoration-none stretched-link",
      attrs: {
        "to": item.to
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)] : [_vm._v(" " + _vm._s(item.text) + " ")], !item.children || item.children.length === 0 ? _c('div', {
      staticClass: "text-center my-5"
    }, [_c('font-awesome-icon', {
      attrs: {
        "size": index === 0 ? '8x' : '5x',
        "icon": ['fad', item.icon]
      }
    })], 1) : _vm._e()], 2), item.children && item.children.length > 0 ? _c('b-list-group', {
      staticClass: "border-light border-top",
      attrs: {
        "flush": ""
      }
    }, _vm._l(_vm.accessibleItems(item.children), function (child, childIndex) {
      return _c('b-list-group-item', {
        key: index + '-' + childIndex,
        staticClass: "bg-secondary text-light border-light",
        attrs: {
          "to": child.to
        }
      }, [_c('font-awesome-icon', {
        staticClass: "mr-2",
        attrs: {
          "icon": ['fal', child.icon],
          "size": "lg"
        }
      }), _vm._v(" " + _vm._s(child.text) + " ")], 1);
    }), 1) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }