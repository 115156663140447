






























import axios, { AxiosError } from 'axios'

import TypedVue from '@/config/vue'
import IFormulateSchemaItem from '@/types/IFormulateSchemaItem'
import HttpStatusCode from '@/enums/HttpStatusCodes'
import IHydraViolation from '@/types/api/IHydraViolation'
import IFormulateViolations from '@/types/IFormulateViolations'

interface IForm {
    password?: string
}

interface IData {
    email?: string
    mappedViolations: IFormulateViolations
    unmappedViolations: Array<string>
    formData: IForm
    resetCode?: string
    schema: Array<IFormulateSchemaItem>
}

export default TypedVue.extend({
    name: 'ResetPasswordReset',

    data(): IData {
        return {
            email: undefined,
            mappedViolations: {},
            unmappedViolations: [],
            formData: {},
            resetCode: undefined,

            schema: [
                {
                    type: 'password',
                    name: 'password',
                    label: 'New password',
                    validation: '^required|min:8,length|matches:/[0-9]/',

                    validationMessages: {
                        matches: 'Password must contain at least one number.',
                    },
                },
                {
                    type: 'password',
                    name: 'password_confirm',
                    label: 'Confirm password',
                    validation: 'required|confirm',

                    validationMessages: {
                        confirm: 'Passwords do not match.',
                    },

                    validationName: 'Confirm password',
                },
                {
                    type: 'submit',
                },
            ],
        }
    },

    computed: {
        loggedIn(): boolean {
            return this.$store.state.user.user !== null
        },

        brand(): string {
            return this.$store.state.app.brand
        },
    },

    created(): void {
        const { resetCode } = this.$route.query

        if (typeof resetCode === 'string') {
            this.resetCode = resetCode
        } else {
            void this.$router.replace('/login')
        }
    },

    methods: {
        async onSubmit(): Promise<void> {
            this.unmappedViolations = []
            this.mappedViolations = {}

            try {
                await this.$api.anonymous().post(
                    '/api/password_reset_resets',
                    {
                        password: this.formData.password,
                        resetCode: this.resetCode,
                    },
                    {
                        method: 'POST',
                    }
                )
                this.$root.$bvToast.toast('Your password has been reset.', {
                    title: 'Success',
                    variant: 'success',
                    autoHideDelay: 5000,
                })
                void this.$router.replace('/login')
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    this.handleError(error)
                } else {
                    throw error
                }
            }
        },

        handleViolations(violations: Array<IHydraViolation>): void {
            const mappedViolations: IFormulateViolations = {}

            violations.forEach((item): void => {
                if (item.propertyPath in this.formData) {
                    mappedViolations[item.propertyPath] = item.message
                } else {
                    this.unmappedViolations.push(item.message)
                }
            })
            this.mappedViolations = mappedViolations
        },

        handleError(error: AxiosError<any>): void {
            if (error.response) {
                if (
                    error.response.status === HttpStatusCode.BadRequest &&
                    error.response.data['@type'] === 'ConstraintViolationList'
                ) {
                    this.handleViolations(error.response.data.violations)
                } else {
                    this.unmappedViolations = [
                        error.response.data['hydra:description'],
                    ]
                }
            } else {
                this.$bvToast.toast('Something went wrong', {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                })

                throw error
            }
        },
    },
})
