















































import { BreedingRhombusSpinner } from 'epic-spinners'
import Vue, { PropType } from 'vue'

import SegmentConfigure from '@/components/Resource/SegmentConfigure.vue'
import CreateSizeModal from '@/components/Resource/Create/CreateSizeModal.vue'
import CreatePositionModal from '@/components/Resource/Create/CreatePositionModal.vue'
import CreateSegmentModal from '@/components/Resource/Create/CreateSegmentModal.vue'
import CreateDepartmentModal from '@/components/Resource/Create/CreateDepartmentModal.vue'
import StoreDepartmentConfigure from '@/components/Resource/StoreDepartmentConfigure.vue'
import DuplicateSegmentModal from '@/components/Resource/Create/DuplicateSegmentModal.vue'
import IModalEvent from '@/types/IModalEvent'
import {
    ICreateDepartment,
    IDepartment,
    IPosition,
    ISegment,
    ISegmentContainer,
    ISize,
    IStoreType,
} from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import ApiPersistence from '@/persistence/ApiPersistence'
import StoreTypePersistence from '@/persistence/StoreTypePersistence'
import StoreDepartmentPersistence from '@/persistence/StoreDepartmentPersistence'
import SegmentPersistence from '@/persistence/SegmentPersistence'
import PositionPersistence from '@/persistence/PositionPersistence'
// eslint-disable-next-line import/max-dependencies -- bla
import PositionSizesPersistence from '@/persistence/PositionSizesPersistence'

interface IData {
    loaded: boolean
    progress: number
    completedSteps: number
}

interface ISegmentDuplicate {
    parentSegment: number
    segment: ISegment
}

const persistenceList: Array<ApiPersistence> = [
    StoreTypePersistence,
    StoreDepartmentPersistence,
    SegmentPersistence,
    PositionPersistence,
    PositionSizesPersistence,
]

export default TypedVue.extend({
    name: 'PositionConfigurator',

    components: {
        DuplicateSegmentModal,
        StoreDepartmentConfigure,
        SegmentConfigure,
        CreateSizeModal,
        CreatePositionModal,
        CreateSegmentModal,
        CreateDepartmentModal,
        BreedingRhombusSpinner,
    },

    props: {
        item: {
            type: Object as PropType<ISegmentContainer>,
            required: false,
            default: undefined,
        },

        type: {
            type: String,
            required: true,

            validator(value): boolean {
                return ['Store', 'StoreType'].includes(value)
            },
        },
    },

    data(): IData {
        return {
            loaded: false,
            progress: 0,
            completedSteps: 0,
        }
    },

    async mounted(): Promise<void> {
        const timeout = 50

        const steps = persistenceList.map(async (item): Promise<void> => {
            await item.loadAll()

            this.addProgressStep()
        })

        await Promise.all(steps)

        setTimeout((): void => {
            this.loaded = true
        }, timeout)
    },

    methods: {
        addProgressStep(): void {
            this.completedSteps += 1
            this.setProgressStep(this.completedSteps, persistenceList.length)
        },

        setProgressStep(current: number, steps: number): void {
            const maxWidth = 100

            this.progress = (maxWidth / steps) * current
        },

        showModal(data: IModalEvent): void {
            const modal: Vue = this.$refs[`${data.type}Modal`] as Vue

            Object.entries(data.payload).forEach(([key, value]): void => {
                Vue.set(modal, key, value)
            })
            this.$bvModal.show(data.type)
        },

        async createSize(size: ISize): Promise<void> {
            await PositionSizesPersistence.create(size)
        },

        async createDepartment(payload: ICreateDepartment): Promise<void> {
            await StoreDepartmentPersistence.create(payload)
        },

        async createPosition(position: IPosition): Promise<void> {
            await PositionPersistence.create(position)
            await SegmentPersistence.load(position.segment)
        },

        async createSegment(segment: ISegment): Promise<void> {
            await SegmentPersistence.create(segment)
        },

        async duplicateSegment(payload: ISegmentDuplicate): Promise<void> {
            await SegmentPersistence.duplicate(
                payload.parentSegment,
                payload.segment
            )
        },

        async deleteDepartment(department: IDepartment): Promise<void> {
            await StoreDepartmentPersistence.remove(department['@id'])
        },
    },

    computed: {
        storeTypes(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },

        departments(): Array<IDepartment> {
            return this.$store.state.storeDepartments.items
        },

        segments(): Array<ISegment> {
            return this.$store.state.storeSegments.items
        },

        positions(): Array<IPosition> {
            return this.$store.state.positions.items
        },
    },
})
