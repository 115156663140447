var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "createPosition",
      "title": "Maak nieuwe positie",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": function ok($event) {
        return _vm.create();
      }
    }
  }, [_c('b-form', {
    ref: "positionForm",
    on: {
      "submit": function submit($event) {
        return _vm.submit($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Formaat"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.sizes,
      "value-field": "@id",
      "text-field": "name"
    },
    model: {
      value: _vm.size,
      callback: function callback($$v) {
        _vm.size = $$v;
      },
      expression: "size"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }