import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class StorePersistence extends ApiPersistence {}

// eslint-disable-next-line @typescript-eslint/naming-convention -- constant
const ITEMS_PER_PAGE = 750

export default new StorePersistence(
    store.state.stores,
    api,
    '/api/stores',
    undefined,
    ITEMS_PER_PAGE
)
