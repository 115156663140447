
















import Vue from 'vue'

interface IData {
    name: string
}

export default Vue.extend({
    name: 'CreateDepartmentModal',

    data(): IData {
        return {
            name: '',
        }
    },

    methods: {
        create(): void {
            this.$emit('create', { name: this.name })
            this.name = ''
        },

        submit(): void {
            this.create()
            this.$bvModal.hide('createDepartment')
        },
    },
})
