




























import axios, { AxiosError } from 'axios'

import TypedVue from '@/config/vue'
import HttpStatusCode from '@/enums/HttpStatusCodes'
import IFormulateSchemaItem from '@/types/IFormulateSchemaItem'
import IHydraViolation from '@/types/api/IHydraViolation'
import IFormulateViolations from '@/types/IFormulateViolations'

interface IData {
    email?: string
    mappedViolations: IFormulateViolations
    unmappedViolations: Array<string>
    formData: object
    schema: Array<IFormulateSchemaItem>
}

export default TypedVue.extend({
    name: 'ResetPasswordRequest',

    data(): IData {
        return {
            email: undefined,
            mappedViolations: {},
            unmappedViolations: [],

            formData: {
                application: 'manager',
            },

            schema: [
                {
                    type: 'text',
                    name: 'email',
                    label: 'E-mailadres',
                    placeholder: 'email@example.com',
                    validation: 'required|email',
                },
                {
                    type: 'submit',
                    label: 'Wachtwoord opnieuw instellen',
                },
            ],
        }
    },

    computed: {
        loggedIn(): boolean {
            return this.$store.state.user.user !== null
        },

        brand(): string {
            return this.$store.state.app.brand
        },
    },

    methods: {
        async onSubmit(): Promise<void> {
            this.unmappedViolations = []
            this.mappedViolations = {}

            try {
                await this.$api
                    .anonymous()
                    .post('/api/password_reset_requests', this.formData, {
                        method: 'POST',
                    })
                this.$bvToast.toast(
                    'Check your email for instructions as to how to reset your password.',
                    {
                        title: 'Success',
                        variant: 'success',
                        autoHideDelay: 5000,
                    }
                )
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    this.handleError(error)
                } else {
                    throw error
                }
            }
        },

        handleViolations(violations: Array<IHydraViolation>): void {
            const mappedViolations: IFormulateViolations = {}

            violations.forEach((item): void => {
                if (item.propertyPath in this.formData) {
                    mappedViolations[item.propertyPath] = item.message
                } else {
                    this.unmappedViolations.push(item.message)
                }
            })
            this.mappedViolations = mappedViolations
        },

        handleError(error: AxiosError<any>): void {
            if (error.response) {
                if (
                    error.response.status === HttpStatusCode.BadRequest &&
                    error.response.data['@type'] === 'ConstraintViolationList'
                ) {
                    this.handleViolations(error.response.data.violations)
                } else {
                    this.unmappedViolations = [
                        error.response.data['hydra:description'],
                    ]
                }
            } else {
                this.$bvToast.toast('Something went wrong', {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                })

                throw error
            }
        },
    },
})
