















import Heading from '@/components/ui/Heading.vue'
import CampaignDetailItem from '@/components/Resource/Admin/CampaignDetailItem.vue'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import TypedVue from '@/config/vue'
import { ICampaign } from '@/types/api/Interfaces'

export default TypedVue.extend({
    name: 'Order.vue',
    components: { CampaignDetailItem, Heading },

    async created(): Promise<void> {
        await CampaignPersistence.load(
            `${CampaignPersistence.getEndpoint()}/${this.id}`
        )
    },

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },

    computed: {
        item(): ICampaign | undefined {
            return this.$store.state.campaigns.items.find(
                (item: ICampaign): boolean =>
                    item.id.toString() === this.id.toString()
            )
        },
    },
})
