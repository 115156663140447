












import { BreedingRhombusSpinner } from 'epic-spinners'

import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'LoadingCard',

    components: {
        BreedingRhombusSpinner,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },
    },
})
