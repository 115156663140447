import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class ProductionProductPersistence extends ApiPersistence {}

export default new ProductionProductPersistence(
    store.state.productionProducts,
    api,
    '/api/relation_production_products'
)
