






























import Heading from '@/components/ui/Heading.vue'
import CampaignListItem from '@/components/Resource/Admin/CampaignListItem.vue'
import TypedVue from '@/config/vue'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { ICampaign } from '@/types/api/Interfaces'

interface IData {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- enum
    CampaignStatus: typeof CampaignStatus
}

export default TypedVue.extend({
    name: 'Orders',

    data(): IData {
        return {
            CampaignStatus,
        }
    },

    components: { CampaignListItem, Heading },

    methods: {
        refresh(): void {
            // @ts-expect-error -- dunno how to fix
            return this.$refs.list.refresh()
        },
    },

    computed: {
        campaigns(): Array<ICampaign> {
            return this.$store.state.campaigns.items
        },
    },
})
