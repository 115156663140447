


























import Vue from 'vue'

interface IData {
    name: string
    size: string
    sizes: Array<string>
    segment: string | undefined
}

export default Vue.extend({
    name: 'CreatePositionModal',

    props: {},

    data(): IData {
        return {
            segment: undefined,
            name: '',
            size: '',
            sizes: [],
        }
    },

    async mounted(): Promise<void> {
        const response = await this.$api
            .authenticated()
            .get('api/position_sizes')

        this.sizes = response.data['hydra:member']
    },

    methods: {
        create(): void {
            this.$emit('create', {
                name: this.name,
                segment: this.segment,
                size: this.size,
            })
            this.name = ''
        },

        submit(event: Event): void {
            event.preventDefault()
            this.create()
            this.$bvModal.hide('createPosition')
        },
    },
})
