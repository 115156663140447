import TagPersistence from '@/persistence/TagPersistence'
import CampaignPackagePersistence from '@/persistence/CampaignPackagePersistence'
import ProductionPackagePersistence from '@/persistence/ProductionPackagePersistence'
import ProductionPackageItemPersistence from '@/persistence/ProductionPackageItemPersistence'
import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'
import MediaObjectPersistence from '@/persistence/MediaObjectPersistence'
import CampaignItemPersistence from '@/persistence/CampaignItemPersistence'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import CampaignPackageStoreTypePersistence from '@/persistence/CampaignPackageStoreTypePersistence'
// eslint-disable-next-line import/max-dependencies -- care
import StorePersistence from '@/persistence/StorePersistence'

interface IMercureHandler {
    iri: string
    persistence: ApiPersistence
    shouldReload?: boolean
}

const mappedHandlers: Array<IMercureHandler> = [
    {
        iri: '/api/media_objects/{id}',
        persistence: MediaObjectPersistence,
    },
    {
        iri: '/api/image_objects/{id}',
        persistence: MediaObjectPersistence,
    },
    {
        iri: '/api/document_objects/{id}',
        persistence: MediaObjectPersistence,
    },
    {
        iri: '/api/campaign_items/{id}',
        persistence: CampaignItemPersistence,
    },
    {
        iri: '/api/campaigns/{id}',
        persistence: CampaignPersistence,
        shouldReload: true,
    },
    {
        iri: '/api/tags/{id}',
        persistence: TagPersistence,
    },
    {
        iri: '/api/stores/{id}',
        persistence: StorePersistence,
    },
    {
        iri: '/api/campaign_packages/{id}',
        persistence: CampaignPackagePersistence,
    },
    {
        iri: '/api/campaign_package_store_types/{id}',
        persistence: CampaignPackageStoreTypePersistence,
    },
    {
        iri: '/api/relation_production_packages/{id}',
        persistence: ProductionPackagePersistence,
    },
    {
        iri: '/api/relation_production_package_items/{id}',
        persistence: ProductionPackageItemPersistence,
    },
]

// eslint-disable-next-line func-style,sonarjs/cognitive-complexity -- why not?
const mercureHandler = async (): Promise<void> => {
    await store.dispatch('mercure/useRefreshToken')

    if (store.getters['mercure/currentHub'] !== null) {
        return
    }

    const payload = mappedHandlers.map((item: IMercureHandler): any => ({
        hub: process.env.VUE_APP_MERCURE_URL,
        topic: `${process.env.VUE_APP_API_URL}${item.iri}`,

        handler: async (message: MessageEvent): Promise<void> => {
            const data = JSON.parse(message.data)

            if (data['@id'] === undefined) {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console -- debug
                    console.info('[Mercure Error]: Invalid data', data)
                }

                return
            }

            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console -- debug
                console.info(`[Mercure Message]: ${data['@id']}`, data)
            }

            if (item.shouldReload) {
                await item.persistence.load(data['@id'])
            } else {
                item.persistence.append(data)
            }
        },
    }))

    await store.dispatch('mercure/registerHandlers', payload)
}

export default mercureHandler
