import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'
import IApiResource from '@/types/api/IApiResource'

class CampaignPackagePersistence extends ApiPersistence {
    public async move(uri: string, campaignId: number): Promise<IApiResource> {
        if (!this.checkType(uri)) {
            throw new Error(
                `Invalid uri supplied for endpoint "${this.endPoint}"`
            )
        }

        return <IApiResource>await this.patch(`${uri}/move/${campaignId}`, {})
    }
}

export default new CampaignPackagePersistence(
    store.state.campaignPackages,
    api,
    '/api/campaign_packages'
)
