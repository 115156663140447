
































































































import { PropType } from 'vue'
import prettyBytes from 'pretty-bytes'

import { mediaObjectStatus } from '@/enums/MediaObjectStatus'
import { IMediaObject } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

interface IData {
    mediaObjectStatus: typeof mediaObjectStatus
}

export default TypedVue.extend({
    name: 'MediaObjectProgressItem',

    data(): IData {
        return {
            mediaObjectStatus,
        }
    },

    props: {
        mediaObject: {
            type: Object as PropType<IMediaObject>,
            required: true,
        },
    },

    computed: {
        size(): string {
            return prettyBytes(Number.parseInt(this.mediaObject.size, 10), {
                locale: 'nl-NL',
            })
        },
    },
})
