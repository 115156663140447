import { ActionTree, Module, MutationTree } from 'vuex'

import IRootState from '@/store/IRootState'
import IState from '@/store/modules/mediaObjectUploads/IState'
import state from '@/store/modules/mediaObjectUploads/state'

const actions: ActionTree<IState, IRootState> = {
    cancel({ commit, state }, index): boolean {
        const item = state.items.findIndex(index)

        if (item) {
            commit('remove', index)

            return true
        }

        return false
    },
}

const mutations: MutationTree<IState> = {
    add(state, file): void {
        const currentIndex = state.items[state.items.length - 1]?.index ?? 0

        state.items.push({
            index: currentIndex + 1,
            file,
            progress: 0,
            done: false,
        })
    },

    update(state, payload): void {
        const item = state.items[payload.index]

        if (item !== undefined) {
            item.progress = payload.progress
        }
    },

    remove(state, index): void {
        if (state.items[index] === undefined) {
            return
        }

        state.items[index].done = true
    },
}

const main: Module<IState, IRootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
}

export default main
