var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'material-' + _vm.method,
      expression: "'material-' + method"
    }]
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v("04")]), _c('strong', {
    staticClass: "flex-grow-1 mr-2"
  }, [_vm._v("Materiaal")]), _c('span', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.cleanMaterial(_vm.selectedType)) + " ")])], 1)]), _c('b-collapse', {
    attrs: {
      "id": 'material-' + _vm.method,
      "accordion": '#accordeon-' + _vm.method
    }
  }, [_c('b-card-body', [_c('b-radio-group', {
    staticClass: "mb-2",
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.selectedType,
      callback: function callback($$v) {
        _vm.selectedType = $$v;
      },
      expression: "selectedType"
    }
  }, _vm._l(_vm.allTypes, function (item, index) {
    return _c('b-radio', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_vm.availableTypes.includes(item) ? _c('div', {
      staticClass: "available"
    }, [_vm._v(" " + _vm._s(_vm.cleanMaterial(item)) + " ")]) : _c('div', {
      staticClass: "unavailable"
    }, [_vm._v(" " + _vm._s(_vm.cleanMaterial(item)) + " ")])]);
  }), 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'material-color-' + _vm.method,
      expression: "'material-color-' + method"
    }]
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v("05")]), _c('strong', {
    staticClass: "flex-grow-1 mr-2"
  }, [_vm._v("Kleur")]), _c('span', {
    staticClass: "text-right"
  }, [_vm._v(_vm._s(_vm.selectedColor))])], 1)]), _c('b-collapse', {
    attrs: {
      "id": 'material-color-' + _vm.method,
      "accordion": '#accordeon-' + _vm.method
    }
  }, [_c('b-card-body', [_c('b-radio-group', {
    staticStyle: {
      "columns": "3",
      "column-gap": "1rem"
    },
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.selectedColor,
      callback: function callback($$v) {
        _vm.selectedColor = $$v;
      },
      expression: "selectedColor"
    }
  }, _vm._l(_vm.allColors, function (item, index) {
    return _c('b-radio', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_vm.availableColors.includes(item) ? _c('div', {
      staticClass: "available"
    }, [_vm._v(" " + _vm._s(item) + " ")]) : _c('div', {
      staticClass: "unavailable"
    }, [_vm._v(_vm._s(item))])]);
  }), 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'weight-' + _vm.method,
      expression: "'weight-' + method"
    }]
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v("06")]), _c('strong', {
    staticClass: "flex-grow-1 mr-2"
  }, [_vm._v("Gewicht")]), _c('span', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.selectedWeight ? _vm.selectedWeight + ' g/m²' : '') + " ")])], 1)]), _c('b-collapse', {
    attrs: {
      "id": 'weight-' + _vm.method,
      "accordion": '#accordeon-' + _vm.method
    }
  }, [_c('b-card-body', [_c('b-radio-group', {
    staticStyle: {
      "columns": "3",
      "column-gap": "1rem"
    },
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.selectedWeight,
      callback: function callback($$v) {
        _vm.selectedWeight = $$v;
      },
      expression: "selectedWeight"
    }
  }, _vm._l(_vm.allWeights, function (item, index) {
    return _c('b-radio', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_vm.availableWeights.includes(item) ? _c('div', {
      staticClass: "available"
    }, [_vm._v(" " + _vm._s(item) + " g/m² ")]) : _c('div', {
      staticClass: "unavailable"
    }, [_vm._v(" " + _vm._s(item) + " g/m² ")])]);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }