import { Module } from 'vuex'

import IState from '@/store/modules/auth/IState'
import IRootState from '@/store/IRootState'
import actions from '@/store/modules/auth/actions'

const auth: Module<IState, IRootState> = {
    namespaced: true,
    actions,
}

export default auth
