var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "template-item",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "labels position-absolute top"
  }, [_c('b-badge', [_vm._v("Print")]), _c('b-badge', [_vm._v("A4")])], 1), _c('b-card-img', {
    ref: "image",
    attrs: {
      "src": require("./../../assets/product.jpg")
    }
  }), _c('b-card-footer', [_c('b-link', {
    attrs: {
      "to": "/templates/edit/1"
    }
  }, [_vm._v("Template name")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }