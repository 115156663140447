var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'd-flex  quantity-selector' + (_vm.internalValue > 0 ? ' active' : '')
  }, [_c('div', {
    staticClass: "mr-auto remove"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.disabled,
      "variant": "medium",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.remove();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'minus']
    }
  })], 1)], 1), _c('div', {
    staticClass: "mx-2"
  }, [_c('b-input', {
    staticStyle: {
      "text-align": "center",
      "font-weight": "bold"
    },
    attrs: {
      "disabled": _vm.disabled,
      "value": _vm.internalValue,
      "type": "number",
      "no-wheel": "",
      "number": "",
      "step": _vm.step,
      "min": "0",
      "size": "sm"
    },
    on: {
      "input": function input($event) {
        return _vm.set($event);
      },
      "blur": function blur($event) {
        return _vm.checkNull(_vm.internalValue);
      }
    }
  })], 1), _c('div', {
    staticClass: "ml-auto add"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.disabled,
      "variant": "medium",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.add();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'plus']
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }