import api from '@keicreations/sapphire-vue/lib/api-platform'

import { IMediaObject } from '@/types/api/Interfaces'
import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class MediaObjectPersistence extends ApiPersistence {
    public async upload(
        file: File,
        progressHandler: (percentage: number) => void
    ): Promise<IMediaObject> {
        store.commit('mediaObjectUploads/add', file)

        const index = store.state.mediaObjectUploads.items.length - 1

        const formData = new FormData()

        formData.append('file', file)

        try {
            const mediaObject = <IMediaObject>await this.post(
                this.endPoint,
                formData,
                {
                    headers: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention -- header
                        'Content-Type': 'multipart/form-data',
                    },

                    onUploadProgress: (event: ProgressEvent): void => {
                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 100 is not a magic number is this instance
                        const progress = (event.loaded / event.total) * 100

                        store.commit('mediaObjectUploads/update', {
                            index,
                            progress,
                        })
                        progressHandler(Number(progress.toFixed(1)))
                    },
                }
            )

            store.commit('mediaObjectUploads/remove', index)

            return mediaObject
        } catch (error) {
            store.commit('mediaObjectUploads/remove', index)

            throw error
        }
    }
}

export default new MediaObjectPersistence(
    store.state.mediaObjects,
    api,
    '/api/media_objects',
    ['/api/image_objects', '/api/document_objects']
)
