var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-list-group-item', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'CampaignItem',
        params: {
          id: String(_vm.campaign.id)
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.name) + " ")])], 1)]), _c('div', {
    staticClass: "ml-2"
  }, [_c('b-badge', {
    staticClass: "d-flex w-100 justify-content-stretch align-items-center px-2 py-2 rounded",
    staticStyle: {
      "border-radius": "2px"
    },
    attrs: {
      "variant": _vm.statusObject !== undefined ? _vm.statusObject.type : null
    }
  }, [_vm._v(" " + _vm._s(_vm.statusObject !== undefined ? _vm.statusObject.label : null) + " ")])], 1)]), _vm.campaign.status === _vm.CampaignStatus.ArtworkCorrectionNeeded || _vm.campaign.status === _vm.CampaignStatus.Concept ? _c('ArtworkProgress', {
    staticClass: "flex-grow-1 my-3",
    attrs: {
      "assigned-artwork-count": _vm.campaign.assignedArtworkCount,
      "unassigned-artwork-count": _vm.campaign.unassignedArtworkCount
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('CampaignDates', {
    staticClass: "flex-grow-1",
    attrs: {
      "campaign": _vm.campaign
    }
  }), _c('div', {
    staticClass: "ml-2"
  }, [_c('b-btn', {
    staticClass: "text-dark",
    attrs: {
      "to": {
        name: 'CampaignItem',
        params: {
          id: String(_vm.campaign.id)
        }
      },
      "size": "sm",
      "variant": "link"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'pencil'],
      "fixed-width": ""
    }
  })], 1), _c('b-btn', {
    staticClass: "ml-1 text-dark",
    attrs: {
      "variant": "link",
      "size": "sm",
      "disabled": !_vm.canDelete
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete', _vm.campaign);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'trash'],
      "fixed-width": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }