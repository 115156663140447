




















































































import Heading from '@/components/ui/Heading.vue'
import ProductionItem from '@/components/Resource/ProductionItem.vue'
import TypedVue from '@/config/vue'
import { IWizard } from '@/types/api/Interfaces'

interface IData {
    title: string
    resource?: IWizard
    selectedSize?: string
    selectedMethod?: string
    selectedColor?: string
    selectedMaterial?: string
}

export default TypedVue.extend({
    name: 'Wizard',
    components: { ProductionItem, Heading },

    props: {
        id: {
            required: true,
            type: String,
        },
    },

    data(): IData {
        return {
            title: 'Producten',
            resource: undefined,
            selectedSize: undefined,
            selectedMethod: undefined,
            selectedColor: undefined,
            selectedMaterial: undefined,
        }
    },

    methods: {
        load(item: IWizard): void {
            this.title = item.name
            this.resource = item
        },

        addToCart(): void {
            if (!this.resource) {
                return
            }

            void this.$store.dispatch('cart/add', {
                id: this.resource.id,
                name: this.resource.name,
                quantity: 1,

                configuration: {
                    method: this.selectedMethod,
                    size: this.selectedSize,
                    color: this.selectedColor,
                    material: this.selectedMaterial,
                },
            })
        },
    },
})
