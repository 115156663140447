import { GetterTree } from 'vuex'

import IState from '@/store/modules/campaignPackageStoreTypes/IState'
import { ICampaignPackageStoreType } from '@/types/api/Interfaces'
import IRootState from '@/store/IRootState'

const getters: GetterTree<IState, IRootState> = {
    getByUri:
        (state) =>
        (uri: string): ICampaignPackageStoreType | undefined =>
            state.items.find((item): boolean => item['@id'] === uri),
}

export default getters
