




















































import { PropType } from 'vue'

import QuantitySelector from '@/components/ui/QuantitySelector.vue'
import CampaignPackageStoreTypePersistence from '@/persistence/CampaignPackageStoreTypePersistence'
import { ICampaignPackageStoreType, IStoreType } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'CampaignPackageStoreType',
    components: { QuantitySelector },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        campaignPackageIri: {
            type: String,
            required: true,
        },

        storeType: {
            type: Object as PropType<IStoreType>,
        },

        showBottomBorder: {
            type: Boolean,
        },
    },

    methods: {
        async updateCampaignPackageStoreType(quantity: number): Promise<void> {
            if (quantity < 1 && this.campaignPackageStoreType !== undefined) {
                await CampaignPackageStoreTypePersistence.remove(
                    this.campaignPackageStoreType['@id']
                )
            } else if (this.campaignPackageStoreType === undefined) {
                await CampaignPackageStoreTypePersistence.create({
                    campaignPackage: this.campaignPackageIri,
                    storeType: this.storeType['@id'],
                    quantity,
                })
            } else {
                await CampaignPackageStoreTypePersistence.update(
                    this.campaignPackageStoreType['@id'],
                    { quantity }
                )
            }
        },
    },

    computed: {
        campaignPackageStoreTypeQuantity(): number {
            if (this.campaignPackageStoreType === undefined) {
                return 0
            }

            return this.campaignPackageStoreType.quantity
        },

        campaignPackageStoreType(): ICampaignPackageStoreType | undefined {
            return this.$store.state.campaignPackageStoreTypes.items.find(
                (item: ICampaignPackageStoreType): boolean =>
                    item.storeType === this.storeType?.['@id'] &&
                    item.campaignPackage === this.campaignPackageIri
            )
        },
    },
})
