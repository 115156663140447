import {
    faCalendar,
    faThLarge,
    faList,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faTimes,
    faPencil,
    faTrash,
    faTruck,
    faImages,
    faPlus,
    faImage,
    faCheck,
    faExpand,
    faFileUpload,
    faFolderOpen,
    faLink,
    faComment,
    faCommentEdit,
    faDownload,
    faSpinnerThird,
    faChevronLeft,
    faChevronRight,
    faTimesCircle,
    faShare,
} from '@fortawesome/pro-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
    faCalendar,
    faThLarge,
    faList,
    faLink,
    faSearch,
    faSearchMinus,
    faSearchPlus,
    faTimes,
    faPencil,
    faTrash,
    faTruck,
    faImages,
    faPlus,
    faImage,
    faCheck,
    faExpand,
    faFileUpload,
    faFolderOpen,
    faComment,
    faCommentEdit,
    faDownload,
    faSpinnerThird,
    faChevronLeft,
    faChevronRight,
    faTimesCircle,
    faShare
)
