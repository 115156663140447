








































import dayjs from 'dayjs'
import { PropType } from 'vue'

import { ICampaign } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'CampaignDates',

    methods: {
        dayjs,
    },

    props: {
        campaign: {
            type: Object as PropType<ICampaign>,
            required: true,
        },
    },
})
