var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout-heading"
  }, [_c('div', {
    staticClass: "heading shadow"
  }, [_c('b-container', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm.title !== 'Dashboard' ? _c('b-breadcrumb', {
    staticClass: "p-0 mb-0",
    staticStyle: {
      "background": "none"
    }
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'Dashboard'
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fas', 'home']
    }
  })], 1), _vm._t("breadcrumbs"), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 2) : _vm._e(), _c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.title))])], 1), _c('div', {
    staticClass: "actions"
  }, [_vm._t("actions")], 2)])])], 1), _c('b-container', [!!_vm.$slots.filters ? _c('div', {
    staticClass: "filters rounded shadow bg-light p-2 border w-100 d-flex align-items-start mt-5"
  }, [_vm._t("filters")], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }