
















import Vue from 'vue'

interface IData {
    name: string
}

export default Vue.extend({
    name: 'CreateCampaignModal',

    data(): IData {
        return {
            name: '',
        }
    },

    methods: {
        create(event: Event): void {
            if (this.name.trim().length === 0) {
                event.preventDefault()
                this.$bvToast.toast('Naam mag niet leeg zijn', {
                    title: 'Campagne aanmaken',
                    variant: 'danger',
                })

                return
            }

            this.$bvModal.hide('createCampaign')
            this.$emit('create', { name: this.name })
            this.name = ''
        },
    },
})
