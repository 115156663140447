

























import Vue from 'vue'

import Heading from '@/components/ui/Heading.vue'
import DnaItem from '@/components/Resource/DnaItem.vue'
import IGlobalDna from '@/types/IGlobalDna'

export default Vue.extend({
    name: 'GlobalDna',
    components: { DnaItem, Heading },

    methods: {
        getDna(): IGlobalDna {
            return this.$store.state.storeDna.globalDna
        },
    },
})
