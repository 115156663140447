var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "media-object-browser-modal",
      "title": "Bestand selecteren",
      "ok-title": "Selecteer",
      "cancel-title": "Annuleer",
      "size": "xl",
      "ok-disabled": !_vm.selected,
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light",
      "body-class": "p-0"
    },
    on: {
      "ok": function ok($event) {
        return _vm.$emit('select', _vm.selected);
      },
      "hidden": function hidden($event) {
        _vm.selected = undefined;
      }
    }
  }, [_c('div', {
    staticClass: "files d-flex h-100",
    staticStyle: {
      "position": "relative",
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticClass: "p-3 flex-grow-1",
    staticStyle: {
      "overflow-y": "auto",
      "overflow-x": "hidden"
    }
  }, [_vm.mediaObjects.length === 0 ? _c('b-card', {
    staticClass: "mb-3 text-center"
  }, [_c('font-awesome-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": ['far', 'image'],
      "size": "5x"
    }
  }), _c('div', [_vm._v("Geen bestanden gevonden")])], 1) : _vm._e(), _c('transition-group', {
    staticClass: "row",
    attrs: {
      "tag": "div",
      "name": "slide"
    }
  }, _vm._l(_vm.mediaObjects, function (mediaObject) {
    return _c('b-col', {
      key: mediaObject['@id'],
      attrs: {
        "cols": "6",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('FilePreview', {
      staticClass: "mb-3",
      attrs: {
        "media-object": mediaObject,
        "selected": _vm.selected === mediaObject['@id'],
        "selectable": ""
      },
      on: {
        "preview": function preview($event) {
          return _vm.showPreview({
            title: mediaObject.name,
            imageSrc: mediaObject.previewUrl,
            thumbnailSrc: mediaObject.thumbnailUrl
          });
        },
        "select": function select($event) {
          return _vm.selectMediaObject(mediaObject);
        }
      }
    })], 1);
  }), 1)], 1), _c('div', {
    staticClass: "side p-3 bg-light border-left",
    staticStyle: {
      "min-width": "200px",
      "width": "200px",
      "right": "0",
      "top": "0",
      "bottom": "0"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-input', {
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Campagne"
    }
  }, [_c('b-select', {
    model: {
      value: _vm.campagneFilter,
      callback: function callback($$v) {
        _vm.campagneFilter = $$v;
      },
      expression: "campagneFilter"
    }
  }, [_c('b-select-option', {
    attrs: {
      "value": undefined
    }
  }), _vm._l(_vm.campaigns, function (campaign) {
    return _c('b-select-option', {
      key: campaign['@id'],
      attrs: {
        "value": campaign['@id']
      }
    }, [_vm._v(" " + _vm._s(campaign.name) + " ")]);
  })], 2)], 1), _vm.width ? _c('b-form-group', {
    attrs: {
      "label": "Beedte"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "mm"
    }
  }, [_c('b-input', {
    attrs: {
      "value": _vm.width,
      "disabled": ""
    }
  })], 1)], 1) : _vm._e(), _vm.width ? _c('b-form-group', {
    attrs: {
      "label": "Hoogte"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "mm"
    }
  }, [_c('b-input', {
    attrs: {
      "value": _vm.height,
      "disabled": ""
    }
  })], 1)], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Sorteer"
    }
  }, [_c('b-select', {
    staticClass: "mb-2",
    model: {
      value: _vm.sortField,
      callback: function callback($$v) {
        _vm.sortField = $$v;
      },
      expression: "sortField"
    }
  }, _vm._l(_vm.sortFields, function (field) {
    return _c('b-select-option', {
      key: field,
      attrs: {
        "value": field
      }
    }, [_vm._v(" " + _vm._s(field) + " ")]);
  }), 1), _c('b-select', {
    model: {
      value: _vm.sortDirection,
      callback: function callback($$v) {
        _vm.sortDirection = $$v;
      },
      expression: "sortDirection"
    }
  }, _vm._l(_vm.sortDirections, function (direction) {
    return _c('b-select-option', {
      key: direction,
      attrs: {
        "value": direction
      }
    }, [_vm._v(" " + _vm._s(direction) + " ")]);
  }), 1)], 1)], 1)]), _c('MediaObjectGallery', {
    ref: "gallery"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }