import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'
import '@/config/font-awesome-duotone'
import '@/config/font-awesome-solid'
import '@/config/font-awesome-regular'
import '@/config/font-awesome-light'
import Vue from 'vue'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
