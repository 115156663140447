import { ActionTree } from 'vuex'
import api from '@keicreations/sapphire-vue/lib/api-platform'
import { AxiosResponse } from 'axios'

import IRootState from '@/store/IRootState'
import IState from '@/store/modules/cart/IState'

const actions: ActionTree<IState, IRootState> = {
    clear(context): void {
        context.commit('clear')
    },

    async add(context, payload): Promise<void> {
        if (
            typeof context.state.id === 'undefined' ||
            context.state.id === null
        ) {
            const saleOrderResponse = await context.dispatch('create')

            context.commit('setId', saleOrderResponse.data.id)

            const rulePayload = {
                ...payload,
                saleOrder: saleOrderResponse.data.id,
            }

            await context.dispatch('createRule', rulePayload)
            context.commit('increase', payload)
        } else {
            context.commit('increase', payload)
        }
    },

    remove(context, payload): void {
        context.commit('decrease', payload)
    },

    set(context, payload): void {
        context.commit('set', payload)
    },

    delete(context, payload): void {
        context.commit('delete', payload)
    },

    async create(context, payload): Promise<AxiosResponse> {
        return await Promise.resolve(
            api.authenticated().post('/api/sale_orders', {
                body: payload,
            })
        )
    },

    createRule(context, payload): AxiosResponse {
        return api.authenticated().post('/api/sale_order_rules', payload)
    },

    updateRule(context, payload): AxiosResponse {
        return api
            .authenticated()
            .patch(`/api/sale_order_rules/${payload.id}`, {
                body: payload,
            })
    },
}

export default actions
