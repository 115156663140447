import { GetterTree } from 'vuex'

import { IPosition } from '@/types/api/Interfaces'
import IState from '@/store/modules/positions/IState'
import IRootState from '@/store/IRootState'

const getters: GetterTree<IState, IRootState> = {
    getBySegment:
        (state) =>
        (segment: string): Array<IPosition> =>
            state.items.filter((item): boolean => item.segment === segment),
}

export default getters
