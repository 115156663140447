



















































































import Heading from '@/components/ui/Heading.vue'
import TypedVue from '@/config/vue'

interface IData {
    title: string
}

export default TypedVue.extend({
    name: 'WizardList',
    components: { Heading },

    data(): IData {
        return {
            title: 'Producten',
        }
    },
})
