/* eslint-disable import/max-dependencies -- main file */
import Vue, { VNode } from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/nl'
import VueI18n from 'vue-i18n'
import VueI18nFilter from 'vue-i18n-filter'

import App from '@/App.vue'
import messagesNl from '@/config/locales/nl-NL.json'
import messages from '@/config/locales/en.json'
import mercureHandler from '@/utils/mercureHandler'
import { ISapphireVueApi, SapphireGuard } from '@/config/sapphire'
import store from '@/store/index'
import router from '@/config/router'
import '@/config/font-awesome'
import '@/config/konva'
import '@/config/formulate'
import '@/config/dayjs'

Vue.use(VueI18n)
Vue.use(VueI18nFilter)

async function handleTokens(): Promise<void> {
    await store.dispatch('user/loadRefreshToken')
    await store.dispatch('mercure/loadToken')
}

// register components globally
Vue.component('MultiSelect', Multiselect)
Vue.component('DatePicker', DatePicker)

Vue.config.productionTip = false
Vue.use(BootstrapVue)

declare module 'vue/types/vue' {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- interface has to be called "Vue".
    interface Vue {
        $api: ISapphireVueApi
    }
}

void (async (): Promise<void> => {
    await handleTokens()

    if (store.state.user.refreshToken) {
        try {
            await store.dispatch('user/useRefreshToken')
            await store.dispatch(
                'mercure/setRefreshToken',
                store.state.user.refreshToken
            )
            await mercureHandler()
            // eslint-disable-next-line no-empty -- If you don't catch this the VueApp won't start
        } catch {}
    }

    const i18n = new VueI18n({
        locale: 'nl-NL',
        fallbackLocale: 'en',

        messages: {
            en: messages,
            // eslint-disable-next-line @typescript-eslint/naming-convention -- locale
            'nl-NL': messagesNl,
        },
    })

    new Vue({
        mixins: [SapphireGuard],
        render: (appRender): VNode => appRender(App),
        store,
        router,
        i18n,
    }).$mount('#app')
})()

document.addEventListener('DOMContentLoaded', (): void => {
    let isTicking = false

    const app = document.querySelector('#app')

    if (!app) {
        return
    }

    app.addEventListener(
        'scroll',
        (): void => {
            if (isTicking) {
                return
            }

            const scrollTop = 100

            window.requestAnimationFrame((): void => {
                if (app.scrollTop > scrollTop) {
                    app.classList.add('scrolling')
                } else if (app.scrollTop === 0) {
                    app.classList.remove('scrolling')
                } else {
                    // Do nothing
                }

                isTicking = false
            })

            isTicking = true
        },
        false
    )
})
