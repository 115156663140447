import Vue from 'vue'
import Router, { NavigationGuardNext, Route } from 'vue-router'
import jwtDecode from 'jwt-decode'

import IJwtTokenPayload from '@/types/IJwtTokenPayload'
import store from '@/store/index'
import routes from '@/config/routes'

const router = new Router({
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    mode: 'history',
})

router.beforeEach(
    (to: Route, from: Route, next: NavigationGuardNext<Vue>): void => {
        if (to.meta?.requiredRoles === undefined) {
            next()
        } else if (store.state.user.token) {
            const payload: IJwtTokenPayload = jwtDecode(store.state.user.token)

            if (payload.roles === undefined) {
                next({ name: 'AccessDenied' })
            } else if (
                to.meta.requiredRoles.some(
                    (requiredRole: string): boolean =>
                        !payload.roles.includes(requiredRole)
                )
            ) {
                next({ name: 'AccessDenied' })
            } else {
                next()
            }
        } else {
            next({ name: 'Login' })
        }
    }
)

Vue.use(Router)

export default router
