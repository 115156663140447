





































import Vue, { PropType } from 'vue'

import DnaPositionItem from '@/components/Resource/DnaPositionItem.vue'
import { IDepartment } from '@/types/api/Interfaces'

interface IData {
    positions: Array<string>
}

export default Vue.extend({
    name: 'DnaItem',
    components: { DnaPositionItem },

    props: {
        department: {
            type: Object as PropType<IDepartment>,
            default: undefined,
        },
    },

    data(): IData {
        return {
            positions: ['Poster', 'Label', 'Schapkaart', 'Flag'],
        }
    },
})
