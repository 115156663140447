var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-progress', {
    attrs: {
      "show-value": "",
      "max": _vm.assignedArtworkCount + _vm.unassignedArtworkCount
    }
  }, [_c('b-progress-bar', {
    staticClass: "font-weight-bold",
    attrs: {
      "variant": "secondary",
      "value": _vm.assignedArtworkCount
    }
  }), _c('b-progress-bar', {
    staticClass: "text-muted font-weight-bold",
    attrs: {
      "variant": "medium",
      "value": _vm.unassignedArtworkCount
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }