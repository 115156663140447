














import dayjs from 'dayjs'

import TypedVue from '@/config/vue'
import { ICampaign, ICampaignTransitionLog } from '@/types/api/Interfaces'
import IHydraCollection from '@/types/api/IHydraCollection'

interface ITableEntry {
    id: string
    on: string
    from: string
    to: string
    by: string
    reason?: string
}

interface IData {
    items: Array<ICampaignTransitionLog>
    fields: Array<{
        [key: string]: string | boolean
    }>
    isLoading: boolean
}

export default TypedVue.extend({
    props: {
        campaign: {
            type: Object,
            required: true,
        },
    },

    async created(): Promise<void> {
        const searchParameters = new URLSearchParams({
            campaign: this.campaign['@id'],
            // eslint-disable-next-line @typescript-eslint/naming-convention -- api order
            'order[createdAt]': 'desc',
        })

        await this.loadLogs(
            `/api/campaign_transition_logs?${searchParameters.toString()}`
        )

        this.isLoading = false
    },

    methods: {
        async loadLogs(uri: string): Promise<void> {
            const response = await this.$api.authenticated().get(uri)

            const data = response.data as IHydraCollection
            const items: Array<ICampaignTransitionLog> = data[
                'hydra:member'
            ] as Array<ICampaignTransitionLog>

            this.items = [...this.items, ...items]

            if (data?.['hydra:view']?.['hydra:next']) {
                await this.loadLogs(data['hydra:view']['hydra:next'])
            }
        },
    },

    data(): IData {
        return {
            items: [],

            fields: [
                { key: 'on', sortable: true, label: 'Op' },
                { from: 'Van' },
                { to: 'Naar' },
                { by: 'Door' },
                { reason: 'Reden' },
            ],

            isLoading: true,
        }
    },

    computed: {
        tableEntries(): Array<ITableEntry> {
            return this.items.map((item): ITableEntry => {
                let reason = ''

                if (item.metadata?.reason) {
                    reason =
                        item.metadata.reason === 'Internal system error.'
                            ? // eslint-disable-next-line @typescript-eslint/no-base-to-string -- see: https://github.com/kazupon/vue-i18n/issues/410
                              this.$t(item.metadata.reason).toString()
                            : item.metadata.reason
                }

                return {
                    id: item['@id'],
                    on: dayjs(item.createdAt).format('l LTS'),
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string -- see: https://github.com/kazupon/vue-i18n/issues/410
                    from: this.$t(item.placeFrom).toString(),
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string -- see: https://github.com/kazupon/vue-i18n/issues/410
                    to: this.$t(item.placeTo).toString(),
                    by: item.createdBy ?? 'Systeem',
                    reason,
                }
            })
        },
    },

    watch: {
        async campaign(
            campaign: ICampaign,
            oldCampaign: ICampaign
        ): Promise<void> {
            if (
                JSON.stringify(campaign?.transitionLogs) ===
                JSON.stringify(oldCampaign?.transitionLogs)
            ) {
                return
            }

            const iris = campaign.transitionLogs

            if (this.isLoading) {
                return
            }

            const missingIris = iris.filter(
                (iri): boolean =>
                    !this.items.some((item): boolean => item['@id'] === iri)
            )

            const promises = missingIris.map(async (iri): Promise<void> => {
                const response = await this.$api.authenticated().get(iri)

                this.items.push(response.data)
            })

            await Promise.all(promises)
        },
    },
})
