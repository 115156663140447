
















import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'CartNavigation',

    computed: {
        user(): string {
            return this.$store.state.user.token
        },

        quantity(): number {
            return this.$store.getters['cart/quantity']
        },
    },
})
