





















































import Heading from '@/components/ui/Heading.vue'
import StatusBadge from '@/components/ui/StatusBadge.vue'
import IResourceCollection from '@/types/IResourceCollection'
import TypedVue from '@/config/vue'

interface IData {
    selectedName: string
    selectedGroupName: string
}

export default TypedVue.extend({
    name: 'Materials',
    components: { StatusBadge, Heading },

    data(): IData {
        return {
            selectedName: '',
            selectedGroupName: '',
        }
    },

    computed: {
        params(): object {
            return {
                name: this.selectedName,
                groupName: this.selectedGroupName,
            }
        },
    },

    watch: {
        params(): void {
            this.refresh()
        },
    },

    methods: {
        refresh(): void {
            this.$nextTick((): void => {
                this.grid().refresh()
            })
        },

        grid(): IResourceCollection {
            return this.$refs.grid as IResourceCollection
        },
    },
})
