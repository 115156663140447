var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "modal-lg",
    attrs: {
      "id": "linkTag",
      "title": "Link een Tag",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light",
      "ok-title": "Koppelen",
      "cancel-title": "Annuleren"
    },
    on: {
      "ok": _vm.processChanges
    }
  }, [_c('div', {
    staticStyle: {
      "min-height": "500px"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vestiging"
    }
  }, [_c('MultiSelect', {
    attrs: {
      "value": _vm.linkedStoresWithPendingChanges,
      "placeholder": "Typ om te zoeken",
      "options": _vm.stores,
      "multiple": true,
      "track-by": "@id",
      "label": "name"
    },
    on: {
      "select": _vm.storeSelect,
      "remove": _vm.storeRemove
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Campagnes"
    }
  }, [_c('MultiSelect', {
    attrs: {
      "value": _vm.linkedCampaignsWithPendingChanges,
      "placeholder": "Typ om te zoeken",
      "options": _vm.campaigns,
      "multiple": true,
      "track-by": "@id",
      "label": "name"
    },
    on: {
      "select": _vm.campaignSelect,
      "remove": _vm.campaignRemove
    }
  })], 1), _vm.unmutableLinkedCampaigns.length > 0 ? _c('b-form-group', {
    attrs: {
      "label": "Lopende campagnes"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, _vm._l(_vm.unmutableLinkedCampaigns, function (campaign) {
    return _c('b-badge', {
      key: campaign['@id'],
      staticClass: "mr-1"
    }, [_vm._v(" " + _vm._s(campaign.name) + " ")]);
  }), 1)]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }