var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-grow-1 d-flex"
  }, [_vm.campaign.expectedDeliveryDate ? _c('span', {
    staticClass: "small mr-5"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": ['far', 'truck']
    }
  }), _c('span', [_vm._v("Gewenste leverdatum")]), _c('br'), _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "dark"
    }
  }, [_vm._v(" Week " + _vm._s(_vm.dayjs(_vm.campaign.expectedDeliveryDate).week()) + " ")]), _c('small', [_vm._v(" " + _vm._s(_vm.dayjs(_vm.campaign.expectedDeliveryDate).format('LL')) + " ")])], 1) : _vm._e(), _vm.campaign.artworkLatestDate ? _c('span', {
    staticClass: "small mr-5"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": ['far', 'images']
    }
  }), _c('span', [_vm._v("Artwork aanleveren")]), _c('br'), _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "dark"
    }
  }, [_vm._v(" Week " + _vm._s(_vm.dayjs(_vm.campaign.artworkLatestDate).week()) + " ")]), _c('small', [_vm._v(_vm._s(_vm.dayjs(_vm.campaign.artworkLatestDate).format('LL')))])], 1) : _vm._e(), _vm.campaign.startAt && _vm.campaign.endAt ? _c('span', {
    staticClass: "small mr-5"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": ['far', 'calendar']
    }
  }), _c('span', [_vm._v("Campagne periode")]), _c('br'), _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "dark"
    }
  }, [_vm._v(" Week " + _vm._s(_vm.dayjs(_vm.campaign.startAt).week()) + " ")]), _c('small', [_vm._v(" " + _vm._s(_vm.dayjs(_vm.campaign.startAt).format('LL')) + " - " + _vm._s(_vm.dayjs(_vm.campaign.endAt).format('LL')) + " ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }