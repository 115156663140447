

















import Heading from '@/components/ui/Heading.vue'
import AssetItem from '@/components/Resource/AssetItem.vue'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'Assets',
    components: { AssetItem, Heading },
})
