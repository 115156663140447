var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-list-group-item', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: _vm.dnaPosition.id,
      expression: "dnaPosition.id"
    }],
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_vm._v(_vm._s(_vm.dnaPosition.name))]), _c('font-awesome-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": ['far', 'chevron-down']
    }
  })], 1), _c('b-collapse', {
    staticClass: "mt-2 pt-2 border-top",
    attrs: {
      "id": _vm.dnaPosition.id,
      "accordion": "products"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam",
      "label-size": "sm",
      "label-cols": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "dataName",
      "size": "sm"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Aantal",
      "label-size": "sm",
      "label-cols": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "amount",
      "type": "number",
      "size": "sm"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Omschrijving",
      "label-size": "sm",
      "label-cols": "4"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "size": "sm"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }