var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('side-navigation', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$store.state.user.token,
      expression: "$store.state.user.token"
    }],
    attrs: {
      "nav": "nav.side"
    }
  }), _c('main', [_c('TopNavigation'), _c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }