















import Heading from '@/components/ui/Heading.vue'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'AccessDenied',
    components: { Heading },
})
