var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-header', {
    staticClass: "bg-light border-bottom",
    class: {
      'border-bottom-0': _vm.campaignPackageStoreTypeQuantity > 0,
      'pb-0': _vm.campaignPackageStoreTypeQuantity > 0,
      'text-muted': _vm.campaignPackageStoreTypeQuantity < 1
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.campaignPackageStoreTypeQuantity > 0 ? _c('div', {
    staticClass: "flex-grow-1"
  }, [_vm._v(" " + _vm._s(_vm.storeType.name) + " ")]) : _c('div', {
    staticClass: "flex-grow-1",
    on: {
      "click": function click($event) {
        return _vm.updateCampaignPackageStoreType(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.storeType.name) + " ")]), !_vm.campaignPackageStoreType && !_vm.disabled ? _c('b-link', {
    staticClass: "text-dark mr-2",
    on: {
      "click": function click($event) {
        return _vm.updateCampaignPackageStoreType(1);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'plus']
    }
  })], 1) : _vm._e()], 1)]), _c('b-card-body', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.campaignPackageStoreTypeQuantity > 0,
      expression: "campaignPackageStoreTypeQuantity > 0"
    }],
    staticClass: "p-1 px-3 pt-0 bg-light",
    class: {
      'border-bottom': !_vm.showBottomBorder
    }
  }, [_vm.campaignPackageStoreType ? _c('QuantitySelector', {
    staticClass: "m-1",
    attrs: {
      "disabled": _vm.disabled,
      "value": _vm.campaignPackageStoreTypeQuantity
    },
    on: {
      "input": function input($event) {
        return _vm.updateCampaignPackageStoreType($event);
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }