












































































import { BDropdown } from 'bootstrap-vue'

import UploadProgressItem from '@/components/ui/UploadProgressItem.vue'
import MediaObjectProgressItem from '@/components/ui/MediaObjectProgressItem.vue'
import { IMediaObjectUpload } from '@/store/modules/mediaObjectUploads/IState'
import { IMediaObject } from '@/types/api/Interfaces'
import MediaObjectPersistence from '@/persistence/MediaObjectPersistence'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'UploadProgress',
    components: { MediaObjectProgressItem, UploadProgressItem },

    async mounted(): Promise<void> {
        if (!this.user) {
            return
        }

        await MediaObjectPersistence.loadAll()
    },

    methods: {
        clear(): void {
            this.$store.commit('mediaObjectWatchers/clear')
        },

        clearItem(iri: string): void {
            this.$store.commit('mediaObjectWatchers/remove', iri)
        },
    },

    computed: {
        user(): string {
            return this.$store.state.user.token
        },

        uploadMediaObjects(): Array<IMediaObjectUpload> {
            return this.$store.state.mediaObjectUploads.items.filter(
                (item): boolean => !item.done
            )
        },

        watchedMediaObjects(): Array<IMediaObject> {
            return this.$store.state.mediaObjects.items.filter(
                (item: IMediaObject): boolean =>
                    this.$store.state.mediaObjectWatchers.items.includes(
                        item['@id']
                    )
            )
        },

        count(): number {
            this.$nextTick((): void => {
                // dummy
            })

            return (
                this.uploadMediaObjects.length + this.watchedMediaObjects.length
            )
        },
    },

    watch: {
        count(newValue: number): void {
            const dropdown = this.$refs.dropdown as BDropdown

            if (newValue > 0) {
                dropdown.visible = true
            }
        },
    },
})
