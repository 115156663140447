const deleteConfirmOptions = {
    centered: true,
    okVariant: 'danger',
    title: 'Bevestig verwijderen',
    headerBgVariant: 'primary',
    headerTextVariant: 'light',
    bodyClass: 'text-center py-4',
    okTitle: 'Verwijderen',
    cancelVariant: 'medium',
    cancelTitle: 'Annuleren',
}

const persistenceErrorOptions = {
    centered: true,
    title: 'Er is iets misgegaan',
    headerBgVariant: 'danger',
    headerTextVariant: 'light',
    bodyClass: 'text-center py-4',
    okTitle: 'Ok',
    okVariant: 'medium',
}

export { deleteConfirmOptions, persistenceErrorOptions }
