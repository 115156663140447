








import { PropType } from 'vue'

import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'NavigationToggle',

    props: {
        nav: {
            // add other navs here
            type: String as PropType<'nav.side'>,
            required: true,
        },
    },

    computed: {
        closed: {
            get(): boolean {
                return this.$store.state[this.nav].closed
            },

            set(isClosed: boolean): void {
                this.$store.commit(`${this.nav}/setClosed`, isClosed)
            },
        },

        icon(): string {
            return this.closed ? 'bars' : 'times'
        },
    },

    methods: {
        toggle(): void {
            if (this.closed) {
                this.open()
            } else {
                this.close()
            }
        },

        open(): void {
            this.closed = false
        },

        close(): void {
            this.closed = true
        },
    },
})
