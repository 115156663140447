
















































import axios, { AxiosError } from 'axios'

import Heading from '@/components/ui/Heading.vue'
import TagItem from '@/components/Resource/TagItem.vue'
import LinkTagModal from '@/components/Resource/Update/LinkTagModal.vue'
import StorePersistence from '@/persistence/StorePersistence'
import CampaignPersistence from '@/persistence/CampaignPersistence'
import TypedVue from '@/config/vue'
import TagPersistence from '@/persistence/TagPersistence'
import { ITag } from '@/types/api/Interfaces'

// eslint-disable-next-line @typescript-eslint/naming-convention -- We willen geen camelcase voor global const
const VIOLATION_TAG_NAME_EMPTY = 'ad32d13f-c3d4-423b-909a-857b961eb720'
// eslint-disable-next-line @typescript-eslint/naming-convention -- We willen geen camelcase voor global const
const VIOLATION_TAG_DUPLICATE = '23bd9dbf-6b9b-41cd-a99e-4844bcf3077f'

interface IData {
    newTitle: string
    editTag: undefined | ITag
}

export default TypedVue.extend({
    name: 'ScreenTags',
    components: { LinkTagModal, TagItem, Heading },

    async mounted(): Promise<void> {
        await TagPersistence.loadAll()
        await StorePersistence.loadAll()
        await CampaignPersistence.loadAll()
    },

    computed: {
        tags(): Array<ITag> {
            return this.$store.state.tags.items
        },
    },

    data(): IData {
        return {
            editTag: undefined,
            newTitle: '',
        }
    },

    methods: {
        showLinkTag(tag: ITag): void {
            this.editTag = tag
            this.$bvModal.show('linkTag')
        },

        async create(): Promise<void> {
            this.$bvModal.hide('createTag')

            if (this.newTitle.trim().length === 0) {
                this.$bvToast.toast('Titel mag niet leeg zijn', {
                    title: 'Tag opslaan',
                    variant: 'danger',
                })

                return
            }

            try {
                await TagPersistence.create({ title: this.newTitle })
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                this.createTagErrorHandler(error)
            }

            this.newTitle = ''
        },

        createTagErrorHandler(error: AxiosError<any>): void {
            if (
                error.response!.data.violations.some(
                    (violation: any): boolean =>
                        violation.code === VIOLATION_TAG_NAME_EMPTY
                )
            ) {
                this.$bvToast.toast('Naam mag niet leeg zijn', {
                    title: 'Tag aanmaken',
                    variant: 'danger',
                })
            }

            if (
                error.response!.data.violations.some(
                    (violation: any): boolean =>
                        violation.code === VIOLATION_TAG_DUPLICATE
                )
            ) {
                this.$bvToast.toast('Deze Tag bestaat al', {
                    title: 'Tag aanmaken',
                    variant: 'danger',
                })
            }
        },
    },
})
