



















































































































































































import Heading from '@/components/ui/Heading.vue'
import MaterialPersistence from '@/persistence/MaterialPersistence'
import ProductionProductPersistence from '@/persistence/ProductionProductPersistence'
import { IMaterial, IProductionProduct } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import formatEur from '@/utils/euroFormatter'

interface IData {
    materials: Array<IMaterial>
    itemMaterial: IMaterial | undefined
    item: IProductionProduct | undefined
}

// type NullNumber = 'width' | 'height'

export default TypedVue.extend({
    name: 'ProductionProductItem',
    components: { Heading },

    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },

    async mounted(): Promise<void> {
        await ProductionProductPersistence.load(
            `${ProductionProductPersistence.getEndpoint()}/${this.id}`
        )

        this.item = this.$store.state.productionProducts.items.find(
            (product): boolean => product.id.toString() === this.id.toString()
        )

        if (this.item !== undefined) {
            await MaterialPersistence.load(this.item.material)

            this.itemMaterial = this.$store.state.materials.items.find(
                (material): boolean => material['@id'] === this.item?.material
            )
        }
    },

    data(): IData {
        return {
            materials: [],
            item: undefined,
            itemMaterial: undefined,
        }
    },

    watch: {
        itemMaterial(): void {
            if (this.itemMaterial !== undefined && this.item !== undefined) {
                this.item.material = this.itemMaterial['@id']
            }
        },
    },

    methods: {
        formatEur,

        async search(query: string): Promise<void> {
            try {
                const response = await this.$api
                    .authenticated()
                    .get('/api/materials', {
                        params: {
                            name: query,
                        },
                    })

                this.materials = response.data['hydra:member']
            } catch {
                this.materials = []
            }
        },

        async updateProduct(): Promise<void> {
            if (this.item === undefined) {
                return
            }

            try {
                this.item.width = this.item.width.toString()
                this.item.height = this.item.height.toString()

                await ProductionProductPersistence.update(
                    this.item['@id'],
                    this.item
                )
            } catch {
                await this.$bvModal.msgBoxOk('Opslaan mislukt')
            } finally {
                this.$bvToast.toast('Opslaan gelukt', {
                    title: 'Product opslaan',
                    variant: 'success',
                })
            }
        },

        async save(): Promise<void> {
            if (this.item === undefined) {
                return
            }

            if (this.item.name.length === 0) {
                this.$bvToast.toast('Naam mag niet leeg zijn', {
                    title: 'Product opslaan',
                    variant: 'danger',
                })

                return
            }

            await this.updateProduct()
            // try {
            //        const response = await this.$api
            //            .authenticated()
            //            .patch(`/api/relation_production_products/${this.id}`, {
            //                ...this.item,
            //
            //                width: this.item.width
            //                    ? this.item.width.toString()
            //                    : null,
            //
            //                height: this.item.height
            //                    ? this.item.height.toString()
            //                    : null,
            //
            //                material: this.item.materialResource
            //                    ? this.item.materialResource['@id']
            //                    : undefined,
            //            })
            //
            //        this.item = await this.parseItem(response.data)
            //    }
        },
    },
})
