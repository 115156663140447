




















import Heading from '@/components/ui/Heading.vue'
import ProductItem from '@/components/Resource/ProductItem.vue'
import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'Products',
    components: { ProductItem, Heading },
})
