


















































































































































































































































































































/* eslint-disable max-lines -- will probably forget to correct this */

import { PropType } from 'vue'
import { groupBy } from 'lodash'
// eslint-disable-next-line import/no-unresolved -- DE BUG
import { Dictionary } from 'vue-router/types/router'

import CampaignReportStoreDetails from '@/components/CampaignReportStoreDetails.vue'
import StorePersistence from '@/persistence/StorePersistence'
import {
    ICampaign,
    ICampaignItem,
    ICampaignPackage,
    ICampaignPackageStoreType,
    IProductionProduct,
    IStore,
    IStoreType,
    ITag,
} from '@/types/api/Interfaces'
import formatEur from '@/utils/euroFormatter'
import TypedVue from '@/config/vue'

interface ICampaignPackageCollection {
    campaignPackageIri: string
    campaignPackageStoreTypes: Array<ICampaignPackageStoreType>
    campaignItems: Array<ICampaignItem>
}

interface IMatchingTag extends ITag {
    matching?: boolean
}

interface IData {
    isStoresVisible: boolean
}

export default TypedVue.extend({
    components: { CampaignReportStoreDetails },

    props: {
        campaign: {
            type: Object as PropType<ICampaign>,
            required: true,
        },
    },

    async mounted(): Promise<void> {
        await StorePersistence.loadAll()
    },

    data(): IData {
        return {
            isStoresVisible: false,
        }
    },

    computed: {
        selectedStores(): Array<IStore> {
            if (this.campaign.tags.length === 0) {
                return this.$store.state.stores.items
            }

            return this.$store.state.stores.items.filter((storeItem): boolean =>
                storeItem.tags.some((storeTag): boolean =>
                    this.campaign.tags.includes(storeTag)
                )
            )
        },

        storeTypes(): Array<IStoreType> {
            return this.$store.state.storeTypes.items
        },

        totalUnitPriceAmount(): number {
            return this.campaignItems
                .map((item): number => this.getTotalUnitPriceForItem(item))
                .reduce((carrier, current): number => carrier + current, 0)
        },

        totalBasePriceAmount(): number {
            return this.campaignItems
                .map((campaignItem): number => {
                    if (this.isFirstProductOccurrenceInCampaign(campaignItem)) {
                        const product = this.getRelationProductionProduct(
                            campaignItem.relationProductionProduct
                        )

                        if (product?.basePrice !== undefined) {
                            return Number(product.basePrice)
                        }
                    }

                    return 0
                })

                .reduce((carrier, current): number => carrier + current, 0)
        },

        totalPackages(): number {
            let amount = 0

            this.campaignPackages.forEach((campaignPackage): void => {
                this.getCampaignPackageStoreTypes(
                    campaignPackage['@id']
                ).forEach((campaignPackageStoreType): void => {
                    amount += campaignPackageStoreType.quantity
                })
            })

            return amount
        },

        totalItems(): number {
            let amount = 0

            this.campaignItems.forEach((campaignItem): void => {
                amount += this.campaignItemAmount(campaignItem)
            })

            return amount
        },

        campaignPackages(): Array<ICampaignPackage> {
            return this.$store.state.campaignPackages.items.filter(
                (item: ICampaignPackage): boolean =>
                    item.campaign === this.campaign['@id']
            )
        },

        campaignItems(): Array<ICampaignItem> {
            if (this.campaign === undefined) {
                return []
            }

            return this.$store.state.campaignItems.items.filter(
                (item): boolean => item.campaign === this.campaign?.['@id']
            )
        },

        campaignItemsByCampaignPackage(): Array<ICampaignPackageCollection> {
            if (this.campaign === undefined) {
                return []
            }

            const result = groupBy(
                this.campaignItems
                    .filter(
                        (campaignItem: ICampaignItem): boolean =>
                            campaignItem.campaignPackage !== undefined
                    )
                    .slice()
                    .sort(
                        (
                            previous: ICampaignItem,
                            next: ICampaignItem
                        ): number => previous.id - next.id
                    ),
                'campaignPackage'
            ) as Dictionary<any>

            return Object.keys(result).map(
                (key: string): ICampaignPackageCollection => ({
                    campaignPackageIri: key,

                    campaignPackageStoreTypes:
                        this.getCampaignPackageStoreTypes(key),

                    campaignItems: result[key],
                })
            )
        },

        campaignItemsByNonEmptyCampaignPackage(): Array<ICampaignPackageCollection> {
            return this.campaignItemsByCampaignPackage.filter(
                (campaignPackageCollection): boolean =>
                    campaignPackageCollection.campaignPackageStoreTypes.some(
                        (campaignPackageStoreType): boolean =>
                            campaignPackageStoreType.quantity > 0
                    )
            )
        },
    },

    methods: {
        amountPerStore(
            storeTypeIri: string,
            campaignPackageIri: string
        ): number {
            const campaignPackageStoretypeQuantity =
                this.$store.state.campaignPackageStoreTypes.items.find(
                    (campaignPackageStoreType): boolean =>
                        campaignPackageStoreType.storeType === storeTypeIri &&
                        campaignPackageStoreType.campaignPackage ===
                            campaignPackageIri
                )?.quantity

            if (campaignPackageStoretypeQuantity === undefined) {
                return 0
            }

            const campaignItemAmount =
                this.$store.state.campaignItems.items.filter(
                    (campaignItem): boolean =>
                        campaignItem.campaignPackage === campaignPackageIri
                ).length

            return campaignPackageStoretypeQuantity * campaignItemAmount
        },

        totalAmountOfProducts(
            storeTypeIri: string,
            campaignPackageIri: string
        ): number {
            return (
                this.amountPerStore(storeTypeIri, campaignPackageIri) *
                this.selectedStores.filter(
                    (store): boolean => store.storeType === storeTypeIri
                ).length
            )
        },

        getCampaignPackageStoreTypes(
            campaignPackageIri: string
        ): Array<ICampaignPackageStoreType> {
            if (
                this.$store.state.campaignPackageStoreTypes.items === undefined
            ) {
                return []
            }

            return this.$store.state.campaignPackageStoreTypes.items.filter(
                (item): boolean => item.campaignPackage === campaignPackageIri
            )
        },

        getCampaignPackage(packageIri: string): ICampaignPackage | undefined {
            return this.$store.state.campaignPackages.items.find(
                (campaignPackage: ICampaignPackage): boolean =>
                    campaignPackage['@id'] === packageIri
            )
        },

        getRelationProductionProduct(
            productIri: string
        ): IProductionProduct | undefined {
            return this.$store.state.productionProducts.items.find(
                (product: IProductionProduct): boolean =>
                    product['@id'] === productIri
            )
        },

        campaignItemAmount(campaignItem: ICampaignItem): number {
            let amount = 0

            if (campaignItem.campaignPackage !== undefined) {
                this.getCampaignPackageStoreTypes(
                    campaignItem.campaignPackage
                ).forEach((campaignPackageStoreType): void => {
                    amount += campaignPackageStoreType.quantity
                })
            }

            return amount
        },

        // getTotalPackagesByPackage(packageObject: IPackage): number {
        //     let amount = 0
        //
        //     packageObject.orderEntries.forEach((orderEntry): void => {
        //         amount += this.getTotalPackagesByOrderEntry(orderEntry)
        //     })
        //
        //     return amount
        // },
        //
        // getTotalPackagesByOrderEntry(orderEntry: IOrderEntry): number {
        //     return (
        //         this.getAmountOfStoresByType(orderEntry.storeType) *
        //         orderEntry.amount
        //     )
        // },
        //
        // getTotalPackagesForSingleStore(packageObject: IPackage): number {
        //     return packageObject.orderEntries
        //         .map((orderEntry): number => orderEntry.amount)
        //         .reduce((carrier, current): number => carrier + current, 0)
        // },
        //
        // getAmountOfStoresByType(type: string): number {
        //     return this.stores.filter((store): boolean => store.type === type)
        //         .length
        // },
        //
        // getTotalPackagePriceForStoreOfType(
        //     packageName: string,
        //     storeType: string
        // ): number {
        //     const packageObject = this.data.packages.find(
        //         (item): boolean => item.name === packageName
        //     )
        //
        //     if (!packageObject) {
        //         throw new Error('Unknown package')
        //     }
        //
        //     const orderEntry = packageObject.orderEntries.find(
        //         (item): boolean => item.storeType === storeType
        //     )
        //
        //     if (!orderEntry) {
        //         throw new Error('Order entry not found')
        //     }
        //
        //     return packageObject.items
        //         .map((item): IProduct => products[item])
        //         .map((product): number => product.unitPrice * orderEntry.amount)
        //         .reduce((carrier, current): number => carrier + current, 0)
        // },
        //
        // getTotalPriceByPackageForAllStoreTypes(pack: IPackage): number {
        //     return this.storeTypes
        //         .map((item): number =>
        //             this.getTotalPackagePriceForStoreType(pack.name, item)
        //         )
        //         .reduce((carrier, current): number => carrier + current, 0)
        // },
        //
        // getTotalPackagePriceForStoreType(
        //     packageName: string,
        //     storeType: string
        // ): number {
        //     return (
        //         this.getTotalPackagePriceForStoreOfType(
        //             packageName,
        //             storeType
        //         ) * this.getAmountOfStoresByType(storeType)
        //     )
        // },

        matchingTags(store: IStore): Array<IMatchingTag | undefined> {
            return store.tags.map((storeTag): IMatchingTag | undefined => {
                const tag: IMatchingTag | undefined =
                    this.$store.state.tags.items.find(
                        (tagItem): boolean => tagItem['@id'] === storeTag
                    )

                if (tag !== undefined) {
                    tag.matching = Boolean(
                        this.campaign.tags.includes(storeTag)
                    )
                }

                return tag
            })
        },

        getTotalUnitPriceForPackage(
            collection: ICampaignPackageCollection
        ): number {
            return collection.campaignItems
                .map((item): number => this.getTotalUnitPriceForItem(item))
                .reduce((carrier, current): number => carrier + current, 0)
        },

        getTotalUnitPriceForItem(campaignItem: ICampaignItem): number {
            const unitPrice = Number(
                this.getRelationProductionProduct(
                    campaignItem.relationProductionProduct
                )?.unitPrice
            )

            const campaignPackageStoreType =
                this.$store.state.campaignPackageStoreTypes.items.find(
                    (campaignPackageStoreTypeItem): boolean => {
                        const campaignPackage =
                            this.$store.state.campaignPackages.items.find(
                                (
                                    campaignPackageItem: ICampaignPackage
                                ): boolean =>
                                    campaignPackageItem['@id'] ===
                                    campaignItem.campaignPackage
                            )

                        if (campaignPackage !== undefined) {
                            return (
                                campaignPackageStoreTypeItem.campaignPackage ===
                                campaignPackage['@id']
                            )
                        }

                        return false
                    }
                )

            const storeTypeAmount = this.selectedStores.filter(
                (store): boolean =>
                    store.storeType === campaignPackageStoreType?.storeType
            ).length

            if (
                campaignPackageStoreType !== undefined &&
                !Number.isNaN(unitPrice)
            ) {
                return (
                    unitPrice *
                    campaignPackageStoreType.quantity *
                    storeTypeAmount
                )
            }

            return 0
        },

        getTotalBasePriceForPackage(
            collection: ICampaignPackageCollection
        ): number {
            return collection.campaignItems
                .filter((item): boolean =>
                    this.isFirstProductOccurrenceInCampaign(item)
                )
                .map((item): number => {
                    const basePrice = this.getRelationProductionProduct(
                        item.relationProductionProduct
                    )?.basePrice

                    if (basePrice !== undefined) {
                        return Number(basePrice)
                    }

                    return 0
                })
                .reduce((carrier, current): number => carrier + current, 0)
        },

        isFirstProductOccurrenceInCampaign(findItem: ICampaignItem): boolean {
            const campaignItemsWithProduct =
                this.campaignItemsByCampaignPackage.map(
                    (
                        campaignPackageCollection: ICampaignPackageCollection
                    ): ICampaignItem | undefined => {
                        if (
                            campaignPackageCollection.campaignPackageStoreTypes
                                .length === 0
                        ) {
                            return undefined
                        }

                        return campaignPackageCollection.campaignItems.find(
                            (campaignItem: ICampaignItem): boolean => {
                                if (
                                    campaignItem.campaignPackage === undefined
                                ) {
                                    return false
                                }

                                return (
                                    campaignItem.relationProductionProduct ===
                                    findItem.relationProductionProduct
                                )
                            }
                        )
                    }
                )

            const firstOccurence = campaignItemsWithProduct.find(
                (campaignItem): boolean =>
                    campaignItem?.relationProductionProduct ===
                    findItem.relationProductionProduct
            )

            return firstOccurence === findItem
        },

        formatEur,
    },
})
