




















































































































































import { PropType } from 'vue'

import IColors from '@/types/IColors'
import TypedVue from '@/config/vue'

const maxPixels: number = 150

export default TypedVue.extend({
    name: 'MaterialBlueprint',

    props: {
        title: {
            type: String,
            required: false,
        },

        remarks: {
            type: String,
            required: false,
        },

        name: {
            type: String,
            required: false,
        },

        material: {
            type: String,
            required: false,
        },

        width: {
            type: [Number, String],
            required: false,
        },

        height: {
            type: [Number, String],
            required: false,
        },

        frontImg: {
            type: String,
            required: false,
        },

        frontImageScale: {
            type: Number,
            default: 1,
        },

        frontImageOffsetX: {
            type: Number,
            default: 0,
        },

        frontImageOffsetY: {
            type: Number,
            default: 0,
        },

        backImg: {
            type: String,
            required: false,
        },

        showDimensions: {
            type: Boolean,
            default: false,
        },

        dimensionUnit: {
            type: String,
            default: 'mm',
        },

        doubleSided: {
            type: Boolean,
            required: false,
            default: false,
        },

        wide: {
            type: Boolean,
            default: false,
        },

        frontColors: {
            type: Array as PropType<Array<string>>,
            required: false,
        },

        backColors: {
            type: Array as PropType<Array<string>>,
            required: false,
        },

        colors: {
            type: Object as () => IColors,
            required: false,
        },

        clickable: {
            type: Boolean,
            default: false,
        },

        selectable: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList(): object {
            return this.$attrs.class as unknown as object
        },

        calculatedRatio(): number {
            if (this.parseNumber(this.width) > this.parseNumber(this.height)) {
                return maxPixels / this.parseNumber(this.width)
            }

            return maxPixels / this.parseNumber(this.height)
        },

        calculatedWidth(): number {
            return this.parseNumber(this.width) * this.calculatedRatio
        },

        calculatedHeight(): number {
            return this.parseNumber(this.height) * this.calculatedRatio
        },
    },

    methods: {
        parseNumber(number: string | number): number {
            if (typeof number === 'string') {
                return Number.parseFloat(number)
            }

            return number
        },

        checkClick(): void {
            if (!this.clickable) {
                return
            }

            this.$emit('click')
        },

        formatNumber(number: number | string): string {
            return new Intl.NumberFormat('nl-NL', {
                maximumFractionDigits: 0,
            }).format(number as number)
        },

        mapColor(color: string): string {
            switch (color) {
                case 'PMS 1':
                    return 'pms1'
                case 'PMS 2':
                    return 'pms2'
                case 'PMS 3':
                    return 'pms3'
                case 'Cyaan':
                    return 'cyan'
                case 'Geel':
                    return 'yellow'
                case 'Magenta':
                    return 'magenta'
                case 'Zwart':
                    return 'black'
                case 'CMYK':
                    return 'cmyk'

                default:
                    return 'danger'
            }
        },
    },
})
