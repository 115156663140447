































































































































































































import { PropType } from 'vue'

import MaterialItem from '@/components/Resource/MaterialItem.vue'
import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import TypedVue from '@/config/vue'

interface IColorDetails {
    front: Array<string>
    back: Array<string>
}

interface IProductionColor {
    standard: boolean
    detail: string
    description: string
}

interface IProductionMethod {
    colors: Array<IProductionColor>
    materials: Array<string>
    description: string
}

interface IProductionFinish {
    description: string
}

interface IProductionSize {
    name: string
    width: number
    height: number
}

enum ProductionMethod {
    Offset = 'offset',
    Digital = 'digital',
    Largeformat = 'largeformat',
}

interface IProduction {
    printingsheet: string
    printingmatter: string
    pages: number
    offset?: IProductionMethod
    digital?: IProductionMethod
    largeformat?: IProductionMethod
    finishing?: Array<IProductionFinish>
    standard: {
        description: string
        method: ProductionMethod
    }
}

interface IData {
    selectedMethod?: ProductionMethod
    selectedColor?: IProductionColor
    selectedMaterial?: string
    selectedSize?: {
        name: string
    }
    methods: Array<ProductionMethod>
    availableSizes: Array<IProductionSize>
}

export default TypedVue.extend({
    name: 'ProductionItem',
    components: { MaterialBlueprint, MaterialItem },

    props: {
        showLabel: {
            type: Boolean,
            default: true,
        },

        production: {
            type: Object as PropType<IProduction>,
            required: true,
        },
    },

    data(): IData {
        return {
            selectedMethod: undefined,
            selectedColor: undefined,
            selectedMaterial: undefined,
            selectedSize: undefined,
            methods: Object.values(ProductionMethod),

            availableSizes: [
                {
                    name: 'A4',
                    width: 210,
                    height: 297,
                },
                {
                    name: 'A5',
                    width: 148,
                    height: 210,
                },
            ],
        }
    },

    computed: {
        availableMethods(): Array<ProductionMethod> {
            return this.methods.filter((item): boolean =>
                Object.keys(this.production).includes(item)
            )
        },

        selectedProductionMethodColors(): Array<IProductionColor> {
            return this.selectedProductionMethod?.colors ?? []
        },

        selectedProductionMethodMaterials(): Array<string> {
            return this.selectedProductionMethod?.materials ?? []
        },

        selectedProductionMethod(): IProductionMethod | undefined {
            if (!this.selectedMethod) {
                return undefined
            }

            return this.production[this.selectedMethod]
        },
    },

    mounted(): void {
        this.selectedMethod = this.production.standard.method

        if (
            this.selectedMethod !== undefined &&
            this.production?.[this.selectedMethod] !== undefined
        ) {
            this.selectedColor = this.production[
                this.selectedMethod
            ]!.colors.find((item: IProductionColor): boolean => item.standard)
            this.selectedMaterial =
                this.production[this.selectedMethod]!.materials[0]
        }

        this.selectedSize = undefined
    },

    methods: {
        getProductionMethodDescription(
            method?: ProductionMethod
        ): string | undefined {
            if (!method) {
                return undefined
            }

            return this.production[method]?.description
        },

        parseColorDetails(details: string): IColorDetails {
            let frontColors: Array<string> = []
            let backColors: Array<string> = []

            let sides: Array<string> = []

            if (details) {
                sides = details.split('\r')
            }

            if (sides[0]) {
                frontColors = sides[0].replace('Schoonzijde: ', '').split('; ')
            }

            if (sides[1]) {
                backColors = sides[1].replace('Weerzijde: ', '').split('; ')
            }

            return {
                front: frontColors,
                back: backColors,
            }
        },

        mapColor(color: string): string {
            switch (color) {
                case 'PMS 1':
                    return 'pms1'
                case 'PMS 2':
                    return 'pms2'
                case 'Cyaan':
                    return 'cyan'
                case 'Geel':
                    return 'yellow'
                case 'Magenta':
                    return 'magenta'
                case 'Zwart':
                    return 'black'

                default:
                    return 'danger'
            }
        },
    },
})
