import { Module } from 'vuex'

import IState from '@/store/modules/productionProducts/IState'
import state from '@/store/modules/productionProducts/state'
import IRootState from '@/store/IRootState'

const main: Module<IState, IRootState> = {
    namespaced: true,
    state,
}

export default main
