




















































































import jwtDecode from 'jwt-decode'

import Heading from '@/components/ui/Heading.vue'
import MaterialBlueprint from '@/components/ui/MaterialBlueprint.vue'
import router from '@/config/router'
import IJwtTokenPayload from '@/types/IJwtTokenPayload'
import INavItem from '@/types/INavItem'
import TypedVue from '@/config/vue'

interface IData {
    bgVariants: Array<string>
    textVariants: Array<string>
}

export default TypedVue.extend({
    name: 'Dashboard',
    components: { MaterialBlueprint, Heading },

    props: {
        msg: {
            type: String,
            default: undefined,
        },
    },

    computed: {
        navItems(): Array<INavItem> {
            return this.$store.state['nav.side'].items.filter(
                (item): boolean => item.text !== 'Dashboard'
            )
        },

        roles(): Array<String> {
            if (this.$store.state.user.token) {
                const payload: IJwtTokenPayload = jwtDecode(
                    this.$store.state.user.token
                )

                return payload.roles
            }

            return []
        },
    },

    methods: {
        accessibleItems(items: Array<INavItem>): Array<INavItem> {
            return items.filter((item: INavItem): boolean => {
                if (
                    item.children &&
                    this.accessibleItems(item.children).length === 0
                ) {
                    return false
                }

                if (!item.to) {
                    return true
                }

                const matchedRoute = router.match(item.to)

                if (matchedRoute?.meta?.requiredRoles) {
                    return matchedRoute.meta.requiredRoles.some(
                        (requiredRole: string): boolean =>
                            this.roles.includes(requiredRole)
                    )
                }

                return true
            })
        },
    },

    data(): IData {
        return {
            bgVariants: [
                'info',
                'danger',
                'success',
                'secondary',
                'primary',
                'warning',
                'light',
                'dark',
                'info',
                'danger',
                'success',
                'secondary',
                'primary',
                'warning',
                'light',
                'dark',
            ],

            textVariants: [
                'light',
                'light',
                'light',
                'light',
                'light',
                'light',
                'dark',
                'light',
                'light',
                'light',
                'light',
                'light',
                'light',
                'light',
                'dark',
                'light',
            ],
        }
    },
})
