
























































































































import { BreedingRhombusSpinner } from 'epic-spinners'

import {
    IMediaObjectGallery,
    IMediaObjectGalleryItem,
} from '@/types/IMediaObjectGallery'
import TypedVue from '@/config/vue'

const zoomStep = 10
const defaultZoom = 100

export default TypedVue.extend({
    name: 'MediaObjectGallery',

    components: {
        BreedingRhombusSpinner,
    },

    data(): IMediaObjectGallery {
        return {
            hidden: true,
            items: [],
            loaded: [],
            current: 0,
            zoom: defaultZoom,
            defaultZoom,
        }
    },

    methods: {
        open(items: Array<IMediaObjectGalleryItem>): void {
            const body = document.querySelector('body')

            if (body) {
                body.style.overflow = 'hidden !important'
            }

            this.items = items
            this.items.forEach((): void => {
                this.loaded.push(false)
            })
            this.loadIndex(0)
            this.hidden = false
        },

        close(): void {
            const body = document.querySelector('body')

            if (body) {
                body.style.overflow = 'auto'
            }

            const container = this.$refs.images as HTMLElement

            container.scroll({
                left: 0,
            })

            this.items = []
            this.loaded = []
            this.current = 0
            this.hidden = true
            this.zoom = defaultZoom
        },

        zoomIn(): void {
            this.zoom += zoomStep
        },

        next(): void {
            if (this.hasNext) {
                this.setCurrent(this.current + 1)
            }
        },

        previous(): void {
            if (this.hasPrevious) {
                this.setCurrent(this.current - 1)
            }
        },

        setCurrent(index: number, isSmooth: boolean = true): void {
            if (index > this.items.length - 1) {
                return
            }

            this.loadIndex(index)

            this.zoomReset()
            this.current = index

            const container = this.$refs.images as HTMLElement

            container.scroll({
                left: Number(container.offsetWidth * index),
                behavior: isSmooth ? 'smooth' : 'auto',
            })
        },

        loadIndex(index: number): void {
            if (this.loaded[index] === undefined || this.loaded[index]) {
                return
            }

            const image = new Image()

            image.addEventListener('load', (): void => {
                this.loaded.splice(index, 1, true)
            })
            image.src = this.items[index].imageSrc
        },

        zoomOut(): void {
            if (this.zoom - zoomStep > 0) {
                this.zoom -= zoomStep
            }
        },

        zoomReset(): void {
            this.zoom = defaultZoom
        },
    },

    computed: {
        hasNext(): boolean {
            return this.current < this.items.length - 1
        },

        hasPrevious(): boolean {
            return this.current > 0
        },
    },
})
