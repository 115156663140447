


































import axios from 'axios'

import FilePreview from '@/components/Resource/FilePreview.vue'
import Heading from '@/components/ui/Heading.vue'
import UpdateFileModal from '@/components/Resource/Update/UpdateFileModal.vue'
import MediaObjectGallery from '@/components/ui/MediaObjectGallery.vue'
import HttpStatusCodes from '@/enums/HttpStatusCodes'
import { persistenceErrorOptions } from '@/constants/messages'
import { IMediaObject } from '@/types/api/Interfaces'
import MediaObjectPersistence from '@/persistence/MediaObjectPersistence'
import TypedVue from '@/config/vue'

interface IData {
    editObject?: IMediaObject
}

export default TypedVue.extend({
    name: 'Files',
    components: { MediaObjectGallery, UpdateFileModal, Heading, FilePreview },

    data(): IData {
        return {
            editObject: undefined,
        }
    },

    computed: {
        mediaObjects(): Array<IMediaObject> {
            return this.$store.state.mediaObjects.items
                .filter((item): boolean => item.status === 'completed')
                .slice()
                .sort((previous: IMediaObject, next: IMediaObject): number => {
                    const previousValue = new Date(previous.createdAt).getTime()
                    const nextValue = new Date(next.createdAt).getTime()

                    return nextValue - previousValue
                })
        },
    },

    async mounted(): Promise<void> {
        await MediaObjectPersistence.loadAll()
    },

    methods: {
        showImage(mediaObject: IMediaObject): void {
            // @ts-expect-error -- care
            this.$refs.gallery.open([
                {
                    title: mediaObject?.name,
                    subTitle: undefined,
                    imageSrc: mediaObject?.previewUrl,
                },
            ])
        },

        edit(mediaObject: IMediaObject): void {
            this.editObject = mediaObject
            this.$bvModal.show('updateFile')
        },

        async remove(mediaObject: IMediaObject): Promise<void> {
            try {
                await MediaObjectPersistence.remove(mediaObject['@id'])
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                const { response } = error

                if (
                    response.status === HttpStatusCodes.BadRequest ||
                    response.status === HttpStatusCodes.UnprocessableEntity
                ) {
                    await this.$bvModal.msgBoxOk(
                        'Dit bestand kan niet verwijderd worden. Waarschijnlijk is dit bestand onderdeel van een campagne.',
                        persistenceErrorOptions
                    )
                }
            }
        },

        async update(data: object): Promise<void> {
            await MediaObjectPersistence.update(this.editObject!['@id'], data)
        },
    },
})
