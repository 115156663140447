var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-list-group-item', {
    staticClass: "cart-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-img', {
    staticClass: "mr-3",
    attrs: {
      "rounded": "",
      "src": require("@/assets/product.jpg"),
      "width": "80"
    }
  }), _c('h5', {
    staticClass: "flex-grow-1 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")]), _c('QuantitySelector', {
    attrs: {
      "id": _vm.item.id,
      "name": _vm.item.name,
      "current-quantity": 0
    },
    on: {
      "add": function add($event) {
        return _vm.$emit('addItem', _vm.item);
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }