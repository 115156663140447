










































import Vue, { PropType } from 'vue'

import {
    IPosition,
    IStoreType,
    IStoreTypePosition,
} from '@/types/api/Interfaces'

interface IData {
    amount: number
    item: IStoreTypePosition | undefined
}

export default Vue.extend({
    name: 'StoreTypePosition',
    components: {},

    props: {
        position: {
            type: Object as PropType<IPosition>,
            required: true,
        },

        storeType: {
            type: Object as PropType<IStoreType>,
            required: true,
        },
    },

    data(): IData {
        return {
            item: undefined,
            amount: 0,
        }
    },

    computed: {
        sizeName(): number {
            const size = this.$store.getters['positionSizes/getSizeByUri'](
                this.position.size
            )

            if (typeof size !== 'undefined') {
                return size.name
            }

            return 0
        },

        sizeWidth(): number {
            const size = this.$store.getters['positionSizes/getSizeByUri'](
                this.position.size
            )

            if (typeof size !== 'undefined') {
                return size.width
            }

            return 0
        },

        sizeHeight(): number {
            const size = this.$store.getters['positionSizes/getSizeByUri'](
                this.position.size
            )

            if (typeof size !== 'undefined') {
                return size.height
            }

            return 0
        },
    },

    watch: {
        storeTypePosition(value): void {
            if (typeof value !== 'undefined') {
                this.setItem(value)
            }
        },
    },

    methods: {
        setItem(item: IStoreTypePosition | undefined): void {
            if (typeof item === 'undefined') {
                this.item = undefined
            } else {
                this.item = item
                this.amount = item.amount
            }
        },

        editPosition(): void {
            this.$bvModal.show('editPosition')
        },

        async deletePosition(): Promise<void> {
            await this.$api.authenticated().delete(this.position['@id'])
        },

        async patchPosition(position: IPosition): Promise<void> {
            await this.$api.authenticated().patch(position['@id'], position)
        },

        async setAmount(): Promise<void> {
            if (typeof this.item === 'undefined') {
                if (this.amount > 0) {
                    const response = await this.$api
                        .authenticated()
                        .post('/api/store_type_positions', {
                            position: this.position['@id'],
                            storeType: this.storeType['@id'],
                            amount: Number.parseInt(this.amount.toString(), 10),
                        })

                    this.setItem(response.data)
                }
            } else if (this.amount > 0) {
                await this.$api.authenticated().patch(this.item['@id'], {
                    amount: Number.parseInt(this.amount.toString(), 10),
                })
            } else {
                await this.$api.authenticated().delete(this.item['@id'])
                this.setItem(undefined)
            }
        },
    },
})
