import { Module } from 'vuex'

import state from '@/store/modules/nav/state'
import mutations from '@/store/modules/nav/mutations'
import IState from '@/store/modules/nav/IState'
import RootState from '@/store/IRootState'

const main: Module<IState, RootState> = {
    namespaced: true,
    state,
    mutations,
}

export default main
