import { Module, MutationTree } from 'vuex'

import IRootState from '@/store/IRootState'
import IState from '@/store/modules/mediaObjectWatchers/IState'
import state from '@/store/modules/mediaObjectWatchers/state'

const mutations: MutationTree<IState> = {
    add(state, iri): void {
        const index = state.items.indexOf(iri)

        if (index === -1) {
            state.items.push(iri)
        }
    },

    remove(state, iri): void {
        const index = state.items.indexOf(iri)

        if (index !== -1) {
            state.items.splice(index, 1)
        }
    },

    clear(state): void {
        state.items = []
    },
}

const main: Module<IState, IRootState> = {
    namespaced: true,
    state,
    mutations,
}

export default main
