













































import orderStatus, { IOrderStatus } from '@/constants/order-status'
import TypedVue from '@/config/vue'

interface IData {
    statusList: Array<IOrderStatus>
}

export default TypedVue.extend({
    name: 'OrderProgress',

    props: {
        currentStatus: {
            type: String,
        },
    },

    computed: {
        statusIndex(): number {
            return orderStatus.findIndex(
                (item: IOrderStatus): boolean =>
                    item.status === this.currentStatus
            )
        },

        statusObject(): IOrderStatus | undefined {
            return orderStatus.find(
                (item): boolean => item.status === this.currentStatus
            )
        },
    },

    data(): IData {
        return {
            statusList: orderStatus,
        }
    },
})
