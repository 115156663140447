enum MediaObjectStatus {
    Uploaded = 'uploaded',
    Stored = 'stored',
    Cached = 'cached',
    Parsed = 'parsed',
    Rasterized = 'rasterized',
    Processed = 'processed',
    Completed = 'completed',
    Failed = 'failed',
    Invalid = 'invalid',
}

const mediaObjectStatus: typeof MediaObjectStatus = {
    Uploaded: MediaObjectStatus.Uploaded,
    Stored: MediaObjectStatus.Stored,
    Cached: MediaObjectStatus.Cached,
    Parsed: MediaObjectStatus.Parsed,
    Rasterized: MediaObjectStatus.Rasterized,
    Processed: MediaObjectStatus.Processed,
    Completed: MediaObjectStatus.Completed,
    Failed: MediaObjectStatus.Failed,
    Invalid: MediaObjectStatus.Invalid,
}

export { MediaObjectStatus, mediaObjectStatus }
