var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Productieproducten"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('ResourceGrid', {
    attrs: {
      "cols": "4",
      "uri": "/api/relation_production_products",
      "display-property": "reference"
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.loadResources($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var _vm$materialResource, _vm4, _vm$materialResource$;

        var item = _ref.item;
        return [_c('b-card', {
          staticClass: "mb-3",
          attrs: {
            "body-class": "p-0"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              var _vm$relationResource, _vm2, _vm$relationResource2, _vm3, _vm$relationResource3;

              return [_c('div', [_c('font-awesome-icon', {
                staticClass: "mr-2",
                class: {
                  'text-success': item.valid,
                  'text-danger': !item.valid
                },
                attrs: {
                  "icon": ['far', item.valid ? 'check' : 'times']
                }
              }), _vm._v(" " + _vm._s(item.name) + " ")], 1), (_vm$relationResource = (_vm2 = _vm).relationResource) !== null && _vm$relationResource !== void 0 && _vm$relationResource.call(_vm2, item.relation) ? _c('div', {
                staticClass: "text-muted small"
              }, [_vm._v(" " + _vm._s((_vm$relationResource2 = (_vm3 = _vm).relationResource) === null || _vm$relationResource2 === void 0 ? void 0 : (_vm$relationResource3 = _vm$relationResource2.call(_vm3, item === null || item === void 0 ? void 0 : item.relation)) === null || _vm$relationResource3 === void 0 ? void 0 : _vm$relationResource3.name) + " ")]) : _vm._e(), item.deliveryDays !== 1 ? _c('div', {
                staticClass: "text-muted small"
              }, [_vm._v(" Levertijd: " + _vm._s(item.deliveryDays) + " Dagen ")]) : _c('div', {
                staticClass: "text-muted small"
              }, [_vm._v(" Levertijd: 1 Dag ")])];
            },
            proxy: true
          }, {
            key: "footer",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex justify-content-between align-items-center"
              }, [_c('div', {
                staticClass: "flex-grow-1"
              }), _c('div', [_c('b-btn', {
                staticClass: "ml-1",
                attrs: {
                  "to": {
                    name: 'ProductionProductItem',
                    params: {
                      id: item.id
                    }
                  },
                  "size": "sm",
                  "variant": "light"
                }
              }, [_c('font-awesome-icon', {
                attrs: {
                  "icon": ['far', 'pencil'],
                  "fixed-width": ""
                }
              })], 1)], 1)])];
            },
            proxy: true
          }], null, true)
        }, [item.width && item.height && item.colors.front.length > 0 ? _c('MaterialBlueprint', {
          staticClass: "m-0 border-0",
          attrs: {
            "double-sided": item.doubleSided,
            "width": item.width,
            "height": item.height,
            "show-dimensions": "",
            "wide": "",
            "material": (_vm$materialResource = (_vm4 = _vm).materialResource) === null || _vm$materialResource === void 0 ? void 0 : (_vm$materialResource$ = _vm$materialResource.call(_vm4, item === null || item === void 0 ? void 0 : item.material)) === null || _vm$materialResource$ === void 0 ? void 0 : _vm$materialResource$.name,
            "colors": item.colors
          }
        }) : _c('div', {
          staticClass: "p-4 text-muted small text-center"
        }, [_vm._v(" Onvoldoende product-informatie voor een voorbeeld ")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }