











import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'Swatch',

    props: {
        name: {
            type: String,
            required: true,
        },

        space: {
            type: String,
            required: false,
            default: 'rgb',
        },

        value: {
            type: Object,
            required: true,
        },

        selected: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    methods: {
        asString(): string {
            if (this.space === 'rgb') {
                return `rgb(${this.value.r},${this.value.g},${this.value.b})`
            }

            return 'black'
        },
    },
})
