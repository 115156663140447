var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3"
  }, [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "body-class": "p-2",
      "text-variant": "light",
      "bg-variant": "secondary"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [!_vm.editing ? _c('h5', {
    staticClass: "mb-0",
    on: {
      "click": function click($event) {
        return _vm.edit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.department.name) + " ")]) : _vm._e(), _c('b-input-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editing,
      expression: "editing"
    }]
  }, [_c('b-input', {
    ref: "departmentName",
    attrs: {
      "value": _vm.department.name,
      "size": "sm"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.saveItem();
      }
    }
  }), _c('b-input-group-append', [_c('b-btn', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'check']
    }
  })], 1)], 1)], 1)], 1), !_vm.editing ? _c('b-btn', {
    staticClass: "ml-1",
    attrs: {
      "size": "sm"
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'pencil']
    }
  })], 1) : _vm._e(), _c('b-btn', {
    staticClass: "ml-1",
    attrs: {
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.removeItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'trash'],
      "fixed-width": ""
    }
  })], 1)], 1)]), _vm.segments ? _c('b-row', [_vm._l(_vm.segments, function (segment) {
    return _c('b-col', {
      key: segment.id,
      staticClass: "mb-3",
      attrs: {
        "md": "4"
      }
    }, [_c('SegmentConfigure', {
      attrs: {
        "segment": segment,
        "type": _vm.type,
        "item": _vm.item
      },
      on: {
        "showModal": function showModal($event) {
          return _vm.$emit('showModal', $event);
        }
      }
    })], 1);
  }), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "mb-0"
        }, [_vm._v("Nieuw segment aanmaken")])];
      },
      proxy: true
    }], null, false, 967574942)
  }, [_c('b-btn', {
    staticClass: "p-5",
    attrs: {
      "variant": "white",
      "block": "",
      "size": "lg"
    },
    on: {
      "click": function click($event) {
        return _vm.showCreateSegmentModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "fixed-width": "",
      "size": "2x",
      "icon": ['fal', 'plus']
    }
  })], 1)], 1)], 1)], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }