import { Module } from 'vuex'

import getters from '@/store/modules/positions/getters'
import IRootState from '@/store/IRootState'
import IState from '@/store/modules/positions/IState'
import state from '@/store/modules/positions/state'

const main: Module<IState, IRootState> = {
    namespaced: true,
    state,
    getters,
}

export default main
