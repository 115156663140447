import api from '@keicreations/sapphire-vue/lib/api-platform'

import ApiPersistence from '@/persistence/ApiPersistence'
import store from '@/store/index'

class ProductionPackagePersistence extends ApiPersistence {}

export default new ProductionPackagePersistence(
    store.state.productionPackages,
    api,
    '/api/relation_production_packages'
)
