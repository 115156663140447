var render = function () {
  var _vm$status, _vm$status2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.campaign ? _c('b-dropdown', {
    attrs: {
      "size": _vm.size,
      "variant": (_vm$status = _vm.status(_vm.campaign.status)) === null || _vm$status === void 0 ? void 0 : _vm$status.type,
      "right": "",
      "text": (_vm$status2 = _vm.status(_vm.campaign.status)) === null || _vm$status2 === void 0 ? void 0 : _vm$status2.label,
      "no-caret": _vm.availableFlows.length === 0,
      "menu-class": _vm.availableFlows.length === 0 ? 'd-none' : ''
    }
  }, _vm._l(_vm.availableFlows, function (workFlow) {
    return _c('b-dropdown-item', _vm._g({
      key: workFlow.transition,
      attrs: {
        "id": 'order_status_' + workFlow.transition,
        "link-class": 'd-flex align-items-center' + (!workFlow.enabled ? ' text-muted' : '')
      }
    }, workFlow.enabled ? {
      click: function click() {
        return _vm.clickWorkFlow(workFlow.transition);
      }
    } : {}), [_c('div', {
      staticClass: "flex-grow-1"
    }, [_vm._v(_vm._s(_vm.$t(workFlow.transition)))]), workFlow.blockers.length > 0 ? _c('b-tooltip', {
      staticClass: "text-left",
      attrs: {
        "variant": "danger",
        "placement": "left",
        "target": 'order_status_' + workFlow.transition
      }
    }, _vm._l(workFlow.blockers, function (blocker, index) {
      return _c('div', {
        key: workFlow.transition + '_' + index,
        staticClass: "text-left"
      }, [index > 0 ? _c('hr', {
        staticClass: "m-0 my-1 bg-light",
        staticStyle: {
          "opacity": "0.3"
        }
      }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t(blocker)) + " ")]);
    }), 0) : _vm._e()], 1);
  }), 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": 'reason-modal-' + _vm.campaign.id,
      "title": "Wat is de reden van afwijzing?",
      "ok-disabled": _vm.reason === undefined,
      "cancel-title": "Annuleren"
    },
    on: {
      "ok": _vm.onReasonModalOk,
      "show": _vm.onReasonModalShow
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reden"
    }
  }, [_c('b-form-input', {
    attrs: {
      "required": ""
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }