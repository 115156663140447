

























































































import TypedVue from '@/config/vue'

export default TypedVue.extend({
    name: 'TextOptions',

    props: {
        config: {
            type: Object,
            required: true,
        },
    },
})
