

















































































































































import Konva from 'konva'

import Swatches from '@/components/editor/Swatches.vue'
import TextOptions from '@/components/editor/TextOptions.vue'
import RectOptions from '@/components/editor/RectOptions.vue'
import TypedVue from '@/config/vue'

import LayerConfig = Konva.LayerConfig
import KonvaEventObject = Konva.KonvaEventObject
import NodeConfig = Konva.NodeConfig

const floatProperties = ['x', 'y', 'scaleX', 'scaleY', 'rotation', 'fontSize']
const a4Width = 210
const a4Height = 297

interface IDocumentSize {
    value: {
        width: number
        height: number
    }
    text: string
}

interface INodeConfig {
    layerIndex: number
    nodeIndex: number
    property: string
    value: any
}

interface IData {
    selectedShapeName?: string
    selectedNode?: [number, number]

    documents: {}

    sizes: Array<IDocumentSize>
    zoomFactor: number

    configKonva: {
        width: number
        height: number
    }

    layers: Array<LayerConfig>
}

export default TypedVue.extend({
    name: 'TemplateEdit',
    components: { RectOptions, TextOptions, Swatches },

    data(): IData {
        return {
            selectedShapeName: undefined,
            selectedNode: undefined,

            documents: {},

            sizes: [
                {
                    value: {
                        width: a4Width,
                        height: a4Height,
                    },

                    text: 'A4',
                },
            ],

            zoomFactor: 1,

            configKonva: {
                width: 594,
                height: 840,
            },

            layers: [
                {
                    children: [
                        {
                            nodeType: 'v-rect',

                            config: {
                                x: 0,
                                y: 0,
                                width: 100,
                                height: 100,
                                fill: 'red',
                                draggable: true,
                                rotation: 0,
                            },
                        },
                        {
                            nodeType: 'v-text',

                            config: {
                                x: 100,
                                y: 100,
                                text: 'Woord 2',
                                draggable: true,
                            },
                        },
                    ],
                },
            ],
        }
    },

    methods: {
        selectNode(layerIndex: number, nodeIndex: number): void {
            this.selectedNode = [layerIndex, nodeIndex]
            this.selectedShapeName =
                this.layers[layerIndex].children[nodeIndex].config.name
            this.updateTransformer()
        },

        // eslint-disable-next-line no-unused-vars -- no idea what this was for -- martijn???
        setSize({ documentSize }: { documentSize: IDocumentSize }): void {
            // documentSize.value.width
            // documentSize.value.height
            this.configKonva.width = 400
            this.configKonva.height = 300
        },

        setNodeConfig({
            property,
            value,
            layerIndex,
            nodeIndex,
        }: INodeConfig): void {
            let parsedValue = value

            if (floatProperties.includes(property)) {
                parsedValue = Number.parseFloat(value)
            }

            this.layers[layerIndex].children[nodeIndex].config[property] =
                parsedValue
        },

        handleTransformEnd(event: KonvaEventObject<Event>): void {
            const rect = this.layers[0].children.find(
                (layer: LayerConfig): boolean =>
                    layer.config.name === this.selectedShapeName
            )

            if (!event.target) {
                return
            }

            // update the state
            rect.config.x = event.target.x()
            rect.config.y = event.target.y()
            rect.config.rotation = event.target.rotation()
            rect.config.scaleX = event.target.scaleX()
            rect.config.scaleY = event.target.scaleY()
        },

        clickedOnStage(event: KonvaEventObject<Event>): boolean {
            if (event.target === event.target.getStage()) {
                this.selectedShapeName = undefined
                this.selectedNode = undefined
                this.updateTransformer()

                return true
            }

            return false
        },

        clickedOnTransformer(event: KonvaEventObject<Event>): boolean {
            return event.target.getParent().className === 'Transformer'
        },

        handleStageMouseDown(event: KonvaEventObject<Event>): void {
            if (
                this.clickedOnStage(event) ||
                this.clickedOnTransformer(event)
            ) {
                return
            }

            if (typeof event.target.attrs.name === 'undefined') {
                this.selectedShapeName = undefined
                this.selectedNode = undefined
            } else {
                this.selectedShapeName = event.target.attrs.name

                this.layers.forEach(
                    (layer: LayerConfig, layerIndex: number): void => {
                        layer.children.forEach(
                            (node: NodeConfig, nodeIndex: number): void => {
                                if (
                                    node.config.name !== this.selectedShapeName
                                ) {
                                    return
                                }

                                this.selectedNode = [layerIndex, nodeIndex]
                            }
                        )
                    }
                )
            }

            this.updateTransformer()
        },

        updateTransformer(): void {
            if (!this.selectedShapeName) {
                return
            }

            const transformerNode: NodeConfig = (
                this.$refs.transformer as Array<NodeConfig>
            )[0].getNode()
            const stage = transformerNode.getStage()

            const selectedNode = stage.findOne(`.${this.selectedShapeName}`)

            this.drawTransformer(selectedNode, transformerNode)
        },

        drawTransformer(selectedNode: Node, transformerNode: NodeConfig): void {
            if (selectedNode === transformerNode.node()) {
                return
            }

            if (selectedNode) {
                transformerNode.nodes([selectedNode])
            } else {
                transformerNode.nodes([])
            }

            transformerNode.getLayer().batchDraw()
        },
    },
})
