




















































































































import { PropType } from 'vue'

import products from '@/constants/products'
import TypedVue from '@/config/vue'
import ICampaignOrder from '@/types/ICampaignOrder'
import formatEur from '@/utils/euroFormatter'
import { IProductionProduct } from '@/types/api/Interfaces'

interface IStoreStub {
    name: string
    type: string
}

interface IStoreTableItems {
    // store name
    [key: string]: {
        // package name
        [key: string]: {
            amount: number
            products: Array<IProductionProduct>
        }
    }
}

export default TypedVue.extend({
    name: 'CampaignReportStoreDetails',

    props: {
        stores: {
            type: Array as PropType<Array<IStoreStub>>,
            required: true,
        },

        data: {
            type: Object as PropType<ICampaignOrder>,
            required: true,
        },
    },

    computed: {
        storeTableItems(): IStoreTableItems {
            const entries: IStoreTableItems = {}

            this.stores.forEach((store): void => {
                this.data.packages.forEach((pack): void => {
                    const orderEntry = pack.orderEntries.find(
                        (entry): boolean => entry.storeType === store.type
                    )

                    if (!orderEntry || orderEntry.amount === 0) {
                        return
                    }

                    if (!entries[store.name]) {
                        entries[store.name] = {}
                    }

                    entries[store.name][pack.name ?? '<naamloos>'] = {
                        amount: orderEntry.amount,

                        products: pack.items.map(
                            (item): IProductionProduct => products[item]
                        ),
                    }
                })
            })

            return entries
        },
    },

    methods: {
        getTotalPackagesForStore(storeName: string): number {
            const storeType = this.stores.find(
                (store): boolean => store.name === storeName
            )?.type

            if (!storeType) {
                throw new Error('Unknown store type')
            }

            let amount = 0

            this.data.packages.forEach((pack): void => {
                const entry = pack.orderEntries.find(
                    (orderEntry): boolean => orderEntry.storeType === storeType
                )

                amount += entry?.amount ?? 0
            })

            return amount
        },

        getTotalItemsForStore(storeName: string): number {
            const storeType = this.stores.find(
                (store): boolean => store.name === storeName
            )?.type

            if (!storeType) {
                throw new Error('Unknown store type')
            }

            let amount = 0

            this.data.packages.forEach((pack): void => {
                const entry = pack.orderEntries.find(
                    (orderEntry): boolean => orderEntry.storeType === storeType
                )

                if (!entry) {
                    return
                }

                amount += entry.amount * pack.items.length
            })

            return amount
        },

        getTotalPriceForStore(storeName: string): number {
            const storeType = this.stores.find(
                (store): boolean => store.name === storeName
            )?.type

            if (!storeType) {
                throw new Error('Unknown store type')
            }

            let total = 0

            this.data.packages.forEach((pack): void => {
                const entry = pack.orderEntries.find(
                    (orderEntry): boolean => orderEntry.storeType === storeType
                )

                if (!entry) {
                    return
                }

                pack.items
                    .map((item): IProductionProduct => products[item])
                    .forEach((item): void => {
                        total +=
                            entry.amount * Number.parseFloat(item.unitPrice)
                    })
            })

            return total
        },

        formatEur,

        getStoreTypeOfStore(storeName: string): string {
            const storeType = this.stores.find(
                (store): boolean => store.name === storeName
            )?.type

            if (!storeType) {
                throw new Error('Store does not exist')
            }

            return storeType
        },

        // @todo: remove duplicate function (ik stop er mee; het is 1:15)
        getTotalPackagePriceForStoreOfType(
            packageName: string,
            storeType: string
        ): number {
            const packageObject = this.data.packages.find(
                (item): boolean => item.name === packageName
            )

            if (!packageObject) {
                throw new Error('Unknown package')
            }

            const orderEntry = packageObject.orderEntries.find(
                (item): boolean => item.storeType === storeType
            )

            if (!orderEntry) {
                throw new Error('Order entry not found')
            }

            return packageObject.items
                .map((item): IProductionProduct => products[item])
                .map(
                    (product): number =>
                        Number.parseFloat(product.unitPrice) * orderEntry.amount
                )
                .reduce((carrier, current): number => carrier + current, 0)
        },
    },
})
