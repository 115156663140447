





























import Vue from 'vue'

import { IDepartment } from '@/types/api/Interfaces'

interface IData {
    name: string
    department: string | undefined
    parentSegment: string | undefined
}

export default Vue.extend({
    name: 'DuplicateSegmentModal',

    data(): IData {
        return {
            name: '',
            department: undefined,
            parentSegment: undefined,
        }
    },

    computed: {
        departments(): Array<IDepartment> {
            return this.$store.state.storeDepartments.items
        },
    },

    methods: {
        create(): void {
            this.$emit('duplicate', {
                parentSegment: this.parentSegment,

                segment: {
                    name: this.name,
                    department: this.department,
                },
            })
            this.name = ''
            this.department = undefined
            this.parentSegment = undefined
        },
    },
})
