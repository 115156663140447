var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "duplicateSegment",
      "title": "Dupliceer segment",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": _vm.create
    }
  }, [_c('b-form', {
    ref: "segmentForm"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Afdeling"
    }
  }, [_c('b-form-select', {
    model: {
      value: _vm.department,
      callback: function callback($$v) {
        _vm.department = $$v;
      },
      expression: "department"
    }
  }, _vm._l(_vm.departments, function (item) {
    return _c('b-form-select-option', {
      key: item['@id'],
      attrs: {
        "value": item['@id']
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }