import { CampaignStatus } from '@/enums/CampaignStatus'

const orderStatus = [
    { status: CampaignStatus.Concept, label: 'Concept', type: 'concept' },
    {
        status: CampaignStatus.PriceInReview,
        label: 'Accorderen prijs',
        type: 'status-price-in-review',
    },
    {
        status: CampaignStatus.ArtworkAttaching,
        label: 'Drukwerk aanleveren',
        type: 'dark',
    },
    {
        status: CampaignStatus.ArtworkCorrectionNeeded,
        label: 'Drukwerkcorrectie benodigd',
        type: 'warning',
    },
    {
        status: CampaignStatus.ArtworkInReview,
        label: 'Drukwerk in review',
        type: 'status-review',
    },
    {
        status: CampaignStatus.Producing,
        label: 'In productie',
        type: 'status-producing',
    },
    {
        status: CampaignStatus.Shipped,
        label: 'Verzonden',
        type: 'status-shipped',
    },
    { status: CampaignStatus.Finalized, label: 'Afgerond', type: 'success' },
]

interface IOrderStatus {
    status: string
    label: string
    type: string
}

export { orderStatus as default, IOrderStatus }
