var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swatch",
    class: {
      selected: _vm.selected
    },
    style: 'background-color: ' + _vm.asString(),
    on: {
      "click": function click($event) {
        return _vm.$emit('select');
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }