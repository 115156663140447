














import Swatch from '@/components/editor/Swatch.vue'
import TypedVue from '@/config/vue'

interface ISwatch {
    name: string
    space: 'rgb' | 'cmyk'
    value: {
        r: number
        g: number
        b: number
    }
}

interface IData {
    selected?: number
    swatches: Array<ISwatch>
}

export default TypedVue.extend({
    name: 'Swatches',
    components: { Swatch },

    data(): IData {
        return {
            selected: undefined,

            swatches: [
                {
                    name: 'R:255 G:0 B:0',
                    space: 'rgb',

                    value: {
                        r: 255,
                        g: 0,
                        b: 0,
                    },
                },
                {
                    name: 'R:0 G:0 B:255',
                    space: 'rgb',

                    value: {
                        r: 0,
                        g: 0,
                        b: 255,
                    },
                },
                {
                    name: 'R:0 G:255 B:0',
                    space: 'rgb',

                    value: {
                        r: 0,
                        g: 255,
                        b: 0,
                    },
                },
            ],
        }
    },
})
