





































































import { PropType } from 'vue'
import axios, { AxiosError } from 'axios'

import CampaignPersistence from '@/persistence/CampaignPersistence'
import { ICampaign, IStore, ITag } from '@/types/api/Interfaces'
import TypedVue from '@/config/vue'
import TagPersistence from '@/persistence/TagPersistence'

interface IData {
    newTag: string
    selectedTag: string
}

export default TypedVue.extend({
    name: 'CampaignTags',

    props: {
        campaign: Object as PropType<ICampaign>,
        editable: Boolean,
    },

    data(): IData {
        return {
            newTag: '',
            selectedTag: '',
        }
    },

    methods: {
        storesWithTag(tag: ITag): Array<IStore> {
            return this.$store.state.stores.items.filter((store): boolean =>
                store.tags.includes(tag['@id'])
            )
        },

        async addTag(): Promise<void> {
            if (this.campaign === undefined) {
                return
            }

            if (this.selectedTag === '') {
                await this.createTag()
            } else {
                this.campaign.tags.push(this.selectedTag)
                this.selectedTag = ''
            }

            await CampaignPersistence.update(this.campaign['@id'], {
                tags: this.campaign.tags,
            })
        },

        // eslint-disable-next-line max-statements -- shush
        async createTag(): Promise<void> {
            if (this.campaign === undefined) {
                return
            }

            if (this.newTag === '') {
                this.$bvToast.toast('Tag naam kan niet leeg zijn', {
                    title: 'Lege Tag',
                    variant: 'danger',
                })

                return
            }

            try {
                const response = await TagPersistence.create({
                    title: this.newTag.trim(),
                })

                this.newTag = ''

                this.campaign.tags.push(response['@id'])
            } catch (error) {
                if (
                    !axios.isAxiosError(error) ||
                    error.response === undefined
                ) {
                    throw error
                }

                const axiosError = error as AxiosError<any>

                if (
                    axiosError.response!.data['hydra:description'].includes(
                        'This value is already used'
                    )
                ) {
                    this.$bvToast.toast('Deze naam bestaat al', {
                        title: 'Duplicate Tag',
                        variant: 'danger',
                    })
                } else {
                    throw error
                }
            }
        },

        async removeTag(tag: ITag): Promise<void> {
            if (this.campaign === undefined || !this.editable) {
                return
            }

            await CampaignPersistence.update(this.campaign['@id'], {
                tags: this.campaign.tags.filter(
                    (filterTag): boolean => filterTag !== tag['@id']
                ),
            })
        },
    },

    computed: {
        tags(): Array<ITag> {
            return this.$store.state.tags.items.filter(
                (tag): boolean =>
                    this.campaign?.tags.find(
                        (tagIri): boolean => tagIri === tag['@id']
                    ) !== undefined
            )
        },
    },
})
